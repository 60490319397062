import React from 'react';
import XLSX from 'xlsx';
import excelLogo from '../../assets_aplan/images/xls.svg';
import * as Actions from '../../store/actions/general';
import * as Types from '../../store/types';
import ClassroomImportForm from './classroom-import';
import CourseImportForm from './course-import';
import StudentImportForm from './student-import';
import BuildingImportForm from './building-import';
import ProgramImportForm from './program-import';
import FacultyImportForm from './faculty-import';
import InstructorImportForm from './instructor-import';
import UserImportForm from './user-import';
import CampusImportForm from './campus-import';
import PlanningClassroomHoursImportForm from './planning-classroom-hours-import';
import PlanningInstructorHoursImportForm from './planning-instructor-hours-import';
import PlanningCourseHoursImportForm from './planning-course-hours-import';
import { Log } from 'ng2-logger';
import Translator from '../../services/translate-factory';
const T = Translator.create();
const L = Log.create('ImportModal');

export enum ExcelImportKeys {
  Classrooms = 'ClassroomImportModal',
  Students = 'StudentImportModal',
  Courses = 'CourseImportModal',
  Buildings = 'BuildingImportModal',
  Programs = 'ProgramImportModal',
  Faculties = 'FacultyImportModal',
  Instructors = 'InstructorImportModal',
  Campuses = 'CampusImportModal',
  Users = 'UserImportModal',
  PlanningClassroomHours = 'PlanningClassroomHourImportModal',
  PlanningInstructorHours = 'PlanningInstructorHourImportModal',
  PlanningCourseHours = 'PlanningCourseHourImportModal',
}

class ImportModal extends React.Component<Types.IImportModalProps, Types.IImportModalState> {
  state: Types.IImportModalState = {};

  onSelectFile = (e: any) => {
    let element = document.getElementById('excel_file_input');
    if (element) {
      element.click();
    }
  };

  apiResultTableToExcel = () => {
    let tableElement = document.getElementById('api-result-table') as HTMLTableElement;
    let table = tableElement && (tableElement.cloneNode(true) as HTMLTableElement);

    if (table) {
      for (var i = 1; i < table.rows.length; i++) {
        table.rows[i].hidden = false;
      }
      var wb = XLSX.utils.table_to_book(table);
      XLSX.writeFile(wb, 'report.xlsx');
    }
  };

  getComponent() {
    let component_props: Types.IImportFormProps = {
      dispatch: this.props.dispatch,
      apiResultTableToExcel: this.apiResultTableToExcel,
      onImport: this.props.onImport
    };

    switch (this.props.componentKey) {
      case ExcelImportKeys.Classrooms:
        return <ClassroomImportForm {...component_props} />; //* new format, localized
      case ExcelImportKeys.Courses:
        return <CourseImportForm {...component_props} />; //? new format, unlocalized
      case ExcelImportKeys.Students:
        return <StudentImportForm {...component_props} />; //? new format, unlocalized
      case ExcelImportKeys.Buildings:
        return <BuildingImportForm {...component_props} />; //* new format, localized
      case ExcelImportKeys.Programs:
        return <ProgramImportForm {...component_props} />; //* new format, localized
      case ExcelImportKeys.Faculties:
        return <FacultyImportForm {...component_props} />; //* new format, localized
      case ExcelImportKeys.Campuses:
        return <CampusImportForm {...component_props} />; //* new format, localized
      case ExcelImportKeys.Instructors:
        return <InstructorImportForm {...component_props} />; //? new format, unlocalized
      case ExcelImportKeys.Users:
        return <UserImportForm {...component_props} />; //* new format, unlocalized
      case ExcelImportKeys.PlanningClassroomHours:
        return <PlanningClassroomHoursImportForm {...component_props} />; //? new format, unlocalized
      case ExcelImportKeys.PlanningInstructorHours:
        return <PlanningInstructorHoursImportForm {...component_props} />; //? new format, unlocalized
      case ExcelImportKeys.PlanningCourseHours:
        return <PlanningCourseHoursImportForm {...component_props} />; //? new format, unlocalized
      default:
        return null;
    }
  }

  getModalTitle(): string {
    switch (this.props.componentKey) {
      case ExcelImportKeys.Classrooms:
        return "excel_importing_classrooms_from_excel";
      case ExcelImportKeys.Courses:
        return "excel_importing_courses_from_excel";
      case ExcelImportKeys.Students:
        return "excel_importing_students_from_excel";
      case ExcelImportKeys.Buildings:
        return "excel_importing_buildings_from_excel";
      case ExcelImportKeys.Programs:
        return "excel_importing_programs_from_excel";
      case ExcelImportKeys.Faculties:
        return "excel_importing_faculties_from_excel";
      case ExcelImportKeys.Instructors:
        return "excel_importing_instructors_from_excel";
      case ExcelImportKeys.Campuses:
        return "excel_importing_campuses_from_excel";
      case ExcelImportKeys.Users:
        return "gen_assigning_head_program_role_with_excel";
      case ExcelImportKeys.PlanningClassroomHours:
        return "excel_importing_planning_classroom_hours_from_excel";
      case ExcelImportKeys.PlanningInstructorHours:
        return "excel_importing_planning_instructor_hours_from_excel";
      case ExcelImportKeys.PlanningCourseHours:
        return "excel_importing_planning_course_hours_from_excel";
      default:
        return "excel_importing_record_from_excel";
    }
  }

  showImportModal = () => {
    this.props.dispatch(
      Actions.ShowModal({
        name: this.props.componentKey,
        className: 'import-modal',
        centered: false,
        bodyCss: 'border-top mt3',
        title: T.t(this.getModalTitle()),
        body: this.getComponent(),
        confirm: T.t('gen_upload_file'),
        cancel: T.t('gen_close'),
        onConfirm: this.onSelectFile,
        closeOnConfirm: false
      })
    );
  };

  render() {
    return (
      <button type="button" className="ml-1 category-tag-square tag-green" onClick={this.showImportModal}>
        <img src={excelLogo} height={24} width={24} className="mr-0 mr-md-2" />
        <span>{this.props.title}</span>
      </button>
    );
  }
}

export default ImportModal;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Log } from 'ng2-logger';
import { Modal, UncontrolledTooltip } from 'reactstrap';
import Paginate from '../../components/table/paginate';
import Spinner from '../../components/templates/spinner';
import Announce from '../../components/templates/announce';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  CourseHoursSearchInitialValues,
  ClassroomCombineTypes,
  ExamTypes,
  ExamEnvironments,
  ExamOverlapTypes,
} from '../../store/constants/course-const';
import CombineExamsModal from './combined-exams-modal';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import { allRestirectedRoles, isUserEquals } from '../../util/authorize';
const T = Translator.create();

const L = Log.create('BulkCombineExamsModal');

function getInitialState(): any {
  const initialValues: any = {
    filters: Object.assign({}, CourseHoursSearchInitialValues),
    filterIsOpen: false,
    selectedCourseOptions: [],
    all_ids: [],
    selected_ids: [],
    selected_courses: [],
    courseFormIsOpen: false,
    courseId: undefined,
    combineExamsModalIsOpen: false,
    selectedGroupId: -1,
    selectedGroup: undefined
  };
  initialValues.filters.onlySelectWithoutCourseHours = false;
  return Object.assign({}, initialValues);
}

class BulkCombineExamsModal extends Component<any, any> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getExamGroups();
  }
  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentDidUpdate() {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    if (id && !this.state.filters.term_id) {
      this.state.filters.term_id = id;
      this.setState(this.state);
    }
  }

  getExamGroups() {
    const resultCallback = (result: any, status: number) => {
      if (status === 200) {
        this.state.filters = result.filter;
        this.setState(this.state);
      }
    };
    const model = {
      selected_option_ids: this.state.selectedCourseOptions,
      term_id: this.props.term_id,
      filter: this.state.filters
    };
    this.props.dispatch(
      Actions.ApiRequest(
        Constants.exam_period.EXAM_PERIOD_BULK_COMBINE_EXAMS_SEARCH,
        model,
        'bulk-combine-exams-spin',
        resultCallback
      ));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onSelectCourse = (e: React.ChangeEvent<HTMLInputElement>, course: any) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let checkedCourses = Object.assign([], this.state.selected_courses);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
        checkedCourses.push(course);
      } else {
        let index1 = checkedList.indexOf(id);
        if (index1 !== -1) {
          checkedList.splice(index1, 1);
        }
        let index2 = this.state.selected_courses.indexOf(course);
        if (index2 !== -1) {
          checkedCourses.splice(index2, 1);
        }
        if (checkedList.length < 2) {
          checkedList = [];
          checkedCourses = [];
          this.state.selectedGroupId = -1;
          this.state.selectedGroup = undefined;
        }
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList,
        selected_courses: checkedCourses
      });
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>, courses: any) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.selected_ids = courses.map(function (v: any) { return v.course_id; });
        this.state.selected_courses = courses;
      } else {
        this.state.selected_ids = [];
        this.state.selected_courses = [];
        this.state.selectedGroupId = -1;
        this.state.selectedGroup = undefined;
      }
      this.setState(this.state);
    }
  };

  onPageChange = (page: number, size?: number) => {
    this.setState((prev: any) => ({
      ...prev,
      filters: {
        ...prev.filters,
        page: page,
        size: size ? size : 10
      }
    }), this.getExamGroups)
  };

  onSizeChange = (size: number) => {
    this.setState((prev: any) => ({
      ...prev,
      filters: {
        ...prev.filters,
        size: size
      }
    }), this.getExamGroups)
  }

  onSearchByOptions(e: React.MouseEvent<HTMLSpanElement>) {
    if (e && e.currentTarget) {
      if (this.state.selectedCourseOptions.length > 0) {
        this.getExamGroups();
      } else {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_bulk_combine_exams_no_options_selected_title'),
            body: T.t('gen_bulk_combine_exams_no_options_selected_body'),
            name: 'bulk',
            icon: 'info_outline',
            iconColor: 'red',
            confirm: T.t('gen_ok'),
          })
        );
      }
    }
  }

  unCheckAllSelecteds = () => {
    this.setState({
      ...this.state,
      selected_ids: [],
      selected_courses: [],
      selectedGroupId: -1,
      selectedGroup: undefined
    });
  };

  switchCombiningModal = () => {
    this.setState({
      ...this.state,
      hasCombinedCourses: false,
      combineExamsModalIsOpen: !this.state.combineExamsModalIsOpen
    });

  };

  switchCombineExamsModalStatus = () => {
    if (this.state.combineExamsModalIsOpen == true) {
      this.unCheckAllSelecteds();
    }
    this.setState({
      ...this.state,
      combineExamsModalIsOpen: !this.state.combineExamsModalIsOpen
    });
  };

  static getDerivedStateFromProps(props: Types.ICoursePageProps, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;
    if (state.filters.select_all) {
      hasNewState = true;
      state.all_ids = props.all_ids;
      state.selected_ids = props.all_ids;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  deriveCourseList(): any {
    let courseList: Types.IExamPeriod_CourseItem[] = [];

    for (let index = 0; index < this.state.selected_courses.length; index++) {
      let course: any = this.state.selected_courses[index];

      let item: Types.IExamPeriod_CourseItem = {
        course_id: course.course_id,
        courseInfo: course,
        status: course.status,
        status_classroom_assignment: 0,
        attached_classrooms: [],
        time_slots: []
      };

      let derived: any = {
        id: -1,
        item: item
      };

      courseList.push(derived);
    }

    return courseList;
  }

  render() {
    return (
      <Modal className="pt-0" style={{ maxWidth: '100%', padding: '0 15px' }} isOpen={this.props.modalIsOpen} toggle={this.setCloseModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_bulk_combine_exams')}
            </h6>
            <button id='button_close' type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.setCloseModal}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <Spinner name="bulk-combine-exams-spin" />
          <div className="modal-body">
            <div className="container-fluid p-0">
              {/* OPTIONS STARTS HERE*/}
              <div className="row">
                <div className="col-12">
                  <><h6 id="course_combination_options" className="col-3 mb-3 ml-3">{T.t('gen_course_combination_options')} <i className="material-icons mb-1">info_outlined</i></h6>
                    <UncontrolledTooltip placement="top" target="course_combination_options">
                      {T.t('gen_course_combination_options_info')}
                    </UncontrolledTooltip></>
                </div>
              </div>
              <div className="row ml-3">
                <div className="col-md-3">
                  <div className="tick-radio d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        id='option_course_code'
                        type="checkbox"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 0) == 0 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(0);
                            } else {
                              let index = selectedList.indexOf(0);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_course_code')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        id='option_faculty'
                        type="checkbox"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 1) == 1 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(1);
                            } else {
                              let index = selectedList.indexOf(1);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_faculty')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        id="option_session_count"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 2) == 2 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(2);
                            } else {
                              let index = selectedList.indexOf(2);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_session_count')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        id="option_classroom_combine_status"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 3) == 3 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(3);
                            } else {
                              let index = selectedList.indexOf(3);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_classroom_combine_status')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        id="option_campus"
                        type="checkbox"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 4) == 4 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(4);
                            } else {
                              let index = selectedList.indexOf(4);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_campus')}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  {/* <hr /> */}
                  <div className="tick-radio d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        id='option_course_name'
                        type="checkbox"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 5) == 5 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(5);
                            } else {
                              let index = selectedList.indexOf(5);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_course_name')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        id='option_program'
                        type="checkbox"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 6) == 6 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(6);
                            } else {
                              let index = selectedList.indexOf(6);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_program')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        id="option_course_language"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 7) == 7 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(7);
                            } else {
                              let index = selectedList.indexOf(7);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_course_language')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        id="option_assistants"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 8) == 8 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(8);
                            } else {
                              let index = selectedList.indexOf(8);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_assistants')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        id="option_exam_duration"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 9) == 9 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(9);
                            } else {
                              let index = selectedList.indexOf(9);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_exam_duration')}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="tick-radio d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        id='option_instructor'
                        type="checkbox"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 10) == 10 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(10);
                            } else {
                              let index = selectedList.indexOf(10);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_instructor')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        id='option_grade'
                        type="checkbox"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 11) == 11 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(11);
                            } else {
                              let index = selectedList.indexOf(11);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_grade')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        id="option_classroom_type"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 12) == 12 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(12);
                            } else {
                              let index = selectedList.indexOf(12);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_classroom_type')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        id="option_invigilator_status"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 13) == 13 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(13);
                            } else {
                              let index = selectedList.indexOf(13);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_invigilator_status')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        id="option_exam_type"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 14) == 14 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(14);
                            } else {
                              let index = selectedList.indexOf(14);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_exam_type')}
                    </label>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="tick-radio d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        id='option_education_type'
                        type="checkbox"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 15) == 15 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(15);
                            } else {
                              let index = selectedList.indexOf(15);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_education_type')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        id='option_section'
                        type="checkbox"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 16) == 16 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(16);
                            } else {
                              let index = selectedList.indexOf(16);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_section')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        id="option_exam_environment"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 17) == 17 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(17);
                            } else {
                              let index = selectedList.indexOf(17);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_exam_environment')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        id="option_exam_overlap_type"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 18) == 18 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(18);
                            } else {
                              let index = selectedList.indexOf(18);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_exam_overlap_type')}
                    </label>
                  </div>
                  <div className="tick-radio position-relative d-block">
                    <label style={{ cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        id="option_conjugate_course_code"
                        className="form-radio"
                        style={{ backgroundColor: 'blue' }}
                        checked={this.state.selectedCourseOptions && this.state.selectedCourseOptions.find((item: number) => item == 19) == 19 ? true : false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e && e.currentTarget) {
                            let selectedList = this.state.selectedCourseOptions;
                            if (e.currentTarget.checked) {
                              selectedList.push(19);
                            } else {
                              let index = selectedList.indexOf(19);
                              selectedList.splice(index, 1);
                            }
                            this.state.selectedCourseOptions = selectedList;
                            this.setState(this.state);
                          }
                        }}
                      />
                      &nbsp;{T.t('gen_conjugate_course_code')}
                    </label>
                  </div>
                </div>
                <hr />
                <div className="col-12">
                  <button
                    id='button_search'
                    type="button"
                    className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2 float-right ml-3 mr-3"
                    style={{ margin: '5px' }}
                    onClick={(e) => this.onSearchByOptions(e)}
                  >
                    <i className="material-icons mr-2">search</i>
                    <span>{T.t('gen_search')}</span>
                  </button>
                </div>
              </div>
              <hr />
              <div className="row"></div>
              <hr />
              {/* GROUPS STARTS HERE*/}
              <div className="row ml-3">
                {this.props.exam_groups && this.props.exam_groups.length > 0 ? (
                  <React.Fragment>
                    <div className="col-12">
                      <div className="quick-actions">
                        {this.state.selected_ids && this.state.selected_ids.length ? (
                          <button
                            id='button_call_merge'
                            className="btn float-left pr-2 pl-2"
                            style={{ color: '#fff', background: 'indigo' }}
                            onClick={this.switchCombiningModal}
                            disabled={isUserEquals(this.props.user, allRestirectedRoles)}
                          >
                            <i className="material-icons mr-2" style={{ color: 'white' }}>
                              call_merge
                            </i>
                            <span>{T.t('gen_combine_exams')}</span>
                          </button>
                        ) : null}
                      </div>
                    </div>
                    <div className="generic-wrapper">
                      <div className="large-wrapper">
                        <div className="shortened-list">
                          {
                            this.props.exam_groups && this.props.exam_groups.map((item: any, index: any) => (
                              <div key={index} className="d-inline-flex w-100 mb-2"
                                style={{
                                  backgroundColor:
                                    this.state.selectedGroupId == item.group_id
                                      ? '#ffb4b47a'
                                      : '#d3d3d380'
                                }}
                              >
                                <div
                                  className="sl-item align-items-center align-self-center w-100 pt-2 pb-2 pl-2 pr-2"
                                  data-id={item.activityRelationId}
                                  onClick={() => {
                                    const id =
                                      this.state.selectedGroupId == item.group_id
                                        ? -1
                                        : item.group_id;
                                    this.setState({
                                      ...this.state,
                                      selectedGroupId: id,
                                      selectedGroup: id > -1 ? item : undefined,
                                      selected_ids: id > -1 ? item.courses.map(function (v: any) { return v.course_id; }) : [],
                                      selected_courses: id > -1 ? item.courses : []
                                    });
                                  }}
                                  key={item.group_id}
                                >
                                  <React.Fragment>
                                    <div className="row">
                                      <div className="col-12">
                                        <table className="aplan-table aplan-table-responsive table table-borderless table-striped filter-table">
                                          <thead>
                                            <tr>
                                              <td scope="row" className="activityrel-td col-1">
                                                {T.t('gen_group')}:
                                                <button className="multiple-info-tag small-tag group-ellipsis">
                                                  {item.group_id ? item.group_id : "-"}
                                                </button>
                                              </td>
                                              <td
                                                className="col-11"
                                                data-cell="descriptions"
                                                id={'description-popover'}
                                              >
                                                {T.t('gen_description') + ": " + (item && item.description ? item.description : '-')}
                                              </td>
                                            </tr>
                                          </thead>
                                        </table>
                                        <table className="aplan-table aplan-table-responsive table table-borderless table-striped filter-table">
                                          <thead>
                                            <tr>
                                              <th data-cell="select">
                                                <div className="tick-radio position-relative">
                                                  <input
                                                    id='selected_ids'
                                                    type="checkbox"
                                                    className="form-radio"
                                                    disabled={this.state.selectedGroupId == -1 || !(item && item.group_id == this.state.selectedGroupId)}
                                                    checked={
                                                      this.state.selected_ids && item && item.group_id == this.state.selectedGroupId && this.state.selected_ids.length == item.courses.length
                                                    }
                                                    onChange={(e) => this.onSelectAll(e, item.courses)}
                                                  />
                                                </div>
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_course_no')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_course_code')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_conjugate_course_code')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_name')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_total_student_count')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_grade')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_section')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_faculty')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_campus')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_exam_duration')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_session_count')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_classroom_combine_status')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_exam_type')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_exam_environment')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_exam_overlap_type')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_connected_programs')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_lecture_staff')}
                                              </th>
                                              <th scope="col" className="examgroup-th text-center">
                                                {T.t('gen_assistants')}
                                              </th>
                                            </tr>
                                          </thead>
                                          {item.courses && item.courses.map((course: any, index: any) => {

                                            let ClassroomCombineStatusObject = ClassroomCombineTypes(T).find(
                                              object => object.value == course.classroom_combine_status
                                            );

                                            let ExamTypesObject = ExamTypes(T).find(
                                              (object) => object.value == course.exam_type
                                            );

                                            let ExamEnvironmentsObject = ExamEnvironments(T).find(
                                              (object) => object.value == course.exam_environment
                                            );

                                            let ExamOverlapTypesObject = ExamOverlapTypes(T).find(
                                              (object) => object.value == course.exam_overlap_type
                                            );

                                            return (
                                              <tbody key={index}>
                                                <tr>
                                                  <td data-cell="select">
                                                    <div className="tick-radio position-relative">
                                                      <input
                                                        id='select_course'
                                                        type="checkbox"
                                                        className="form-radio"
                                                        disabled={this.state.selectedGroupId == -1 || !(item && item.group_id == this.state.selectedGroupId)}
                                                        checked={
                                                          this.state.selected_ids && this.state.selected_ids.indexOf(course.course_id ? course.course_id : -1) > -1
                                                        }
                                                        data-id={course.course_id}
                                                        onChange={(e) => this.onSelectCourse(e, course)}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td scope="row" data-label={T.t('gen_course_no')} className="examgroup-td text-center">
                                                    {course && course.course_id}
                                                  </td>
                                                  <td scope="row" data-label={T.t('gen_course_code')} className="examgroup-td text-center">
                                                    <div className="table-scrollable-td">{course && course.course_code ? course.course_code.split(';').join(', ') : '-'}</div>
                                                  </td>
                                                  <td scope="row" data-label={T.t('gen_conjugate_course_code')} className="examgroup-td text-center">
                                                    <div className="table-scrollable-td">{course && course.conjugate_course_code ? course.conjugate_course_code.split(';').join(', ') : '-'}</div>
                                                  </td>
                                                  <td scope="row" data-label={T.t('gen_name')} className="examgroup-td text-center">
                                                    <div className="table-scrollable-td">{course && course.name ? course.name.split(';').join(', ') : '-'}</div>
                                                  </td>
                                                  <td scope="row" className="examgroup-td text-center">
                                                    {
                                                      course && course.student_counts && course.student_counts.length > 0 ?
                                                        <div className="table-scrollable-td">{course.student_count_total}</div> : '-'
                                                    }
                                                  </td>
                                                  <td className="examgroup-td text-center">
                                                    {
                                                      course && course.class_ids && course.class_ids.length > 0 ?
                                                        <div className="table-scrollable-td">{course.class_ids && course.class_ids.map((i: any, index: any) => (index == course!.class_ids!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                                    }
                                                  </td>
                                                  <td className="examgroup-td text-center">
                                                    {
                                                      course && course.sections && course.sections.length > 0 ?
                                                        <div className="table-scrollable-td">{course.sections && course.sections.map((i: any, index: any) => (index == course!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                                    }
                                                  </td>
                                                  <td className="examgroup-td text-center">
                                                    {
                                                      course && course.faculties && course.faculties.length > 0 ?
                                                        <div className="table-scrollable-td">{course.faculties && course.faculties.map((i: any, index: any) => (index == course!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                                    }
                                                  </td>
                                                  <td className="examgroup-td text-center">
                                                    {
                                                      course && course.campuses && course.campuses.length > 0 ?
                                                        <div className="table-scrollable-td">{course.campuses && course.campuses.map((i: any, index: any) => (index == course!.campuses!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                                    }
                                                  </td>
                                                  <td scope="row" data-label={T.t('gen_exam_duration')} className="examgroup-td text-center">
                                                    {course && course.exam_duration}
                                                  </td>
                                                  <td data-label={T.t('gen_session_count')} className="examgroup-td text-center">
                                                    {course && course.exam_session}
                                                  </td>
                                                  <td data-label={T.t('gen_classroom_combine_status')} className="examgroup-td text-center">
                                                    {ClassroomCombineStatusObject && ClassroomCombineStatusObject.label ? ClassroomCombineStatusObject && ClassroomCombineStatusObject.label : '-'}
                                                  </td>
                                                  <td data-label={T.t('gen_exam_type')} className="examgroup-td text-center">
                                                    {ExamTypesObject && ExamTypesObject.label ? ExamTypesObject && ExamTypesObject.label : '-'}
                                                  </td>
                                                  <td data-label={T.t('gen_exam_environment')} className="examgroup-td text-center">
                                                    {ExamEnvironmentsObject && ExamEnvironmentsObject.label ? ExamEnvironmentsObject && ExamEnvironmentsObject.label : '-'}
                                                  </td>
                                                  <td data-label={T.t('gen_exam_overlap_type')} className="examgroup-td text-center">
                                                    {ExamOverlapTypesObject && ExamOverlapTypesObject.label ? ExamOverlapTypesObject && ExamOverlapTypesObject.label : '-'}
                                                  </td>
                                                  <td className="examgroup-td text-center">
                                                    {
                                                      course && course.programs && course.programs.length > 0 ?
                                                        <div className="table-scrollable-td">{course.programs && course.programs.map((i: any, index: any) => (index == course!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                                    }
                                                  </td>
                                                  <td className="examgroup-td text-center">
                                                    {
                                                      course && course.instructors && course.instructors.length > 0 ?
                                                        <div className="table-scrollable-td">{course.instructors && course.instructors.map((i: any, index: any) => (index == course!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                                    }
                                                  </td>
                                                  <td className="examgroup-td text-center">
                                                    {
                                                      course && course.assistant_staff && course.assistant_staff.length > 0 ?
                                                        <div className="table-scrollable-td">{course.assistant_staff && course.assistant_staff.map((i: any, index: any) => (index == course!.assistant_staff!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                                    }
                                                  </td>
                                                </tr>
                                              </tbody>
                                            )
                                          })}
                                        </table>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                  <div className="align-items-center align-self-center ml-auto sidebar-controls"></div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div className="row-options justify-content-end">
                        <div
                          className="page-sorting d-flex align-items-center justify-content-center"
                          style={{ marginTop: '5px' }}
                        >
                          {
                            this.props.exam_groups && this.props.exam_groups.length > 0 && (
                              <Paginate filters={this.state.filters} onPageChange={this.onPageChange} onSizeChange={this.onSizeChange} />
                            )}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-12 col-xs-12 text-center">
                        <Announce title={T.t('gen_notification_course_combination_options_not_selected')} />
                      </div>
                    </div>
                    <hr />
                    <div className="row"></div>
                    <hr />
                  </div>)
                }
              </div>
            </div>
          </div>
          {
            this.state.combineExamsModalIsOpen && (
              <CombineExamsModal
                courseList={this.deriveCourseList()}
                modalIsOpen={this.state.combineExamsModalIsOpen}
                onClose={this.switchCombineExamsModalStatus}
                showCoursesOnly={false}
                onUpdateList={() => this.getExamGroups()}
                coursesAreCombined={false}
              />
            )
          }
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICoursePageProps): Types.ICoursePageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICoursePageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    results: store.state.course_page && store.state.course_page.results,
    filters: store.state.course_page && store.state.course_page.filters,
    all_ids: store.state.course_page && store.state.course_page.all_ids,
    selectOptions: store.state.select_options && store.state.select_options.coursePage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    exam_groups: store.state.bulk_combine_exams_page && store.state.bulk_combine_exams_page.exam_groups,
    filtersPagination: store.state.bulk_combine_exams_page && store.state.bulk_combine_exams_page.filters,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  // if (next.state.course_page) {
  //   return (
  //     !!equal(prev.state.course_page && prev.state.course_page.results, next.state.course_page && next.state.course_page.results) &&
  //     !!equal(prev.state.course_page && prev.state.course_page.all_ids, next.state.course_page && next.state.course_page.all_ids) &&
  //     !!equal(
  //       prev.state.select_options && prev.state.select_options.coursePage,
  //       next.state.select_options && next.state.select_options.coursePage
  //     )
  //   );
  // } else {
  //   return true;
  // }
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(BulkCombineExamsModal);

export default container;

import moment from 'moment';
import 'moment/locale/tr';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Announcement from '../../assets_aplan/images/announcement.svg';
import Paginate from '../../components/table/paginate';
import { Formik, FormikActions, FormikProps } from 'formik';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  CalendarModalSearchInitialValues,
  ClassroomHoursDataModelValues,
  ClassroomHoursInitialValues
} from '../../store/constants/exam-period-const';
import * as Types from '../../store/types';
import ClassroomAddModal from './classroom-hours-add-modal';
import ClassroomListPage from './classroom-hours-listpage';
import HoursExportModal from '../planning/hours-export-modal';
import ImportModal, { ExcelImportKeys } from '../../components/excel-imports/import-modal';
import SortedColumn from '../../components/table/sorted-column';
import Announce from '../../components/templates/announce';
import { ClassroomFeatureOptions } from '../../store/constants/classroom-const';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { SectionTypes } from '../../store/constants/enums';
import Translator from '../../services/translate-factory';
import { filterClassroomTypesForEventModuleStatus } from '../../util/sort';

const T = Translator.create();
const Logger = Log.create('ExamDates');

class ClassroomHours extends Component<Types.IExamPeriod_ClassroomProps, Types.IExamPeriod_Classroom_TabState> {
  state: Types.IExamPeriod_Classroom_TabState = {
    model: Object.assign({}, ClassroomHoursInitialValues),
    dataModel: Object.assign({}, ClassroomHoursDataModelValues),
    filters: Object.assign({}, CalendarModalSearchInitialValues),
    filterIsOpen: false,
    selected_ids: [],
    all_ids: [],
    selected_names: [],
    selected_classrooms: [],
    downloadHoursModalIsOpen: false
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.model.term_id = id;
    this.state.filters.term_id = id;
    this.setState(this.state);
    this.getClassroomSelectOptions();
    this.getBuildingsByCampusesAtClassrooms([]);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentDidUpdate() {
    if (
      !this.state.model.is_calendar_created &&
      this.state.model.calendar.days.length &&
      this.state.model.calendar.times.length &&
      Object.keys(this.state.model.calendar.schedule).length
    ) {
      this.createModifiedSchedule();
    }
  }

  getClassroomSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_SELECT_OPTIONS, 'classroom-list-spin'));
  }

  getBuildingsByCampusesAtClassrooms = (campusIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_BUILDINGS_BY_CAMPUSES, campusIds, 'classroom-list-spin'));
  }

  getClassroomHours() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS, this.state.filters, 'ep-classroom-list')
    );
  }

  onFormReset = () => {
    this.state.filters = Object.assign({}, CalendarModalSearchInitialValues);
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.setState(this.state);
    this.getClassroomHours();
  };

  onFilterClassroom(model: Types.IFilterClassroom, FormActions: FormikActions<Types.IFilterClassroom>) {
    this.setState(prev => ({
      ...prev,
      selected_ids: [],
      filters: {
        ...model,
        page: 1,
      },
      filterIsOpen: true,
    }));
    this.getClassroomHours();
    FormActions.setSubmitting(false);
  }

  switchAddModalStatus = () => {
    this.setState({
      ...this.state,
      model: {
        ...this.state.model,
        addModalIsOpen: !this.state.model.addModalIsOpen
      }
    });

    this.getClassroomHours();
  };

  switchDisplay = () => {
    if (this.state.model.displayListPage == false) {
      this.state.model.selected_Classroom = {
        term_id: undefined,
        classroom_id: 0,
        status: 0,
        time_slots: {}
      };
    }
    this.setState({
      ...this.state,
      model: {
        ...this.state.model,
        displayListPage: !this.state.model.displayListPage
      }
    });
  };

  createModifiedSchedule() {
    let scheduleClone: any = Object.assign({}, this.state.model.calendar.schedule);
    let daysClone: any = Object.assign([], this.state.model.calendar.days);
    let test_schedule: any = {};

    daysClone.map((day: any) => {
      let newArray: any = [];
      scheduleClone[day].map((item: any) => {
        let obj = {
          hour: item.hour,
          status: item.isActive == true ? 'empty' : 'disabled'
        };
        newArray.push(obj);
      });
      test_schedule[day] = newArray;
    });
    this.setState({
      ...this.state,
      model: {
        ...this.state.model,
        calendar: {
          ...this.state.model.calendar,
          schedule: test_schedule
        },
        initialSchedule: test_schedule,
        is_calendar_created: true
      }
    });
  }

  convertDataToSchedule() {
    let data_time_slots: any =
      this.state.model.selected_Classroom && this.state.model.selected_Classroom.time_slots
        ? this.state.model.selected_Classroom.time_slots
        : {};

    let timeSlotsDays = Object.keys(data_time_slots);
    let dateObject_timeSlots: any = {};
    let result_timeSlots: any = {};
    let scheduleClone: any = this.state.model.calendar.schedule;
    if (timeSlotsDays && !timeSlotsDays.length) {
      this.setState({
        ...this.state,
        model: {
          ...this.state.model,
          calendar: {
            ...this.state.model.calendar,
            schedule: this.state.model.initialSchedule ? this.state.model.initialSchedule : {}
          }
        }
      });
    }

    if (timeSlotsDays && timeSlotsDays.length) {
      timeSlotsDays.map((day: string) => {
        dateObject_timeSlots = scheduleClone[moment(day).format('YYYY-MM-DD')];
        data_time_slots[day].map((dataHour: string) => {
          dateObject_timeSlots = dateObject_timeSlots.map((item: { hour: string; status: any }) =>
            item.hour == dataHour ? { hour: item.hour, status: 'classroom' } : item
          );
        });
        result_timeSlots = Object.assign(scheduleClone, result_timeSlots, {
          [day]: dateObject_timeSlots
        });
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          model: {
            ...this.state.model,
            calendar: {
              ...this.state.model.calendar,
              schedule: result_timeSlots
            }
          }
        });
      }, 0);
    }
  }

  onSelectClassroom = (
    e?: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>,
    passedID?: number
  ) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      let numID = parseInt(id, 10);
      // ID bize ClassroomListPage'deki tablodan gelebilir ya da programın olduğu tabloda soldaki listeden gelebilir.
      let theID = passedID && passedID !== -1 ? passedID : numID;
      const selectedClassroom = this.state.model.classroom_list.find((item) => item.classroom_id === theID);
      if (this.state.model.selected_Classroom && this.state.model.selected_Classroom.classroom_id != theID) {
        this.state.model.selected_Classroom = selectedClassroom;
        this.state.model.calendar.schedule = this.state.model.initialSchedule
          ? JSON.parse(JSON.stringify(this.state.model.initialSchedule))
          : {};
      }
      this.convertDataToSchedule();
    }
  };

  saveClassroomHours(addType: string) {
    let dataModel: Types.IExamPeriod_ClassroomDataModel = Object.assign({}, ClassroomHoursDataModelValues);
    let scheduleClone: any = this.state.model.calendar.schedule;
    let timeSlotsArray: any = [];
    let timesHelperObject: any = {};
    let result_timeSlots: any = {};

    this.state &&
      this.state.model.calendar.days.map((day: string) => {
        scheduleClone[day].map((item: { hour: string; status: string }) => {
          if (item.status == 'classroom') {
            timeSlotsArray.push(item.hour);
          }
        });
        result_timeSlots = Object.assign(timesHelperObject, timeSlotsArray.length ? { [day]: timeSlotsArray } : {});
        timeSlotsArray = [];
      });

    if (addType == 'single') {
      dataModel = {
        term_id: this.state.model.term_id,
        classroom_id: this.state.model.selected_Classroom ? this.state.model.selected_Classroom.classroom_id : -1,
        time_slots: result_timeSlots
      };
    } else if (addType == 'multiple') {
      dataModel = {
        term_id: this.state.model.term_id,
        classroom_ids: this.state.selected_ids ? this.state.selected_ids : [],
        time_slots: result_timeSlots
      };
    }

    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_save_hours_title'),
        body: T.t('gen_save_hours_question'),
        name: 'classroomHours_save',
        icon: 'warning',
        iconColor: 'red',
        confirm: T.t('gen_save'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (status == 200) {
              this.setState(
                {
                  ...this.state,
                  all_ids: [],
                  selected_ids: []
                },
                () => this.getClassroomHours()
              );
            }
          };

          if (addType == 'single') {
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_UPDATE_CLASSROOM_HOURS,
                dataModel,
                'exam-period-modal-tab-spin',
                resultCallback
              )
            );
          } else if (addType == 'multiple') {
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_UPDATE_MULTIPLE_CLASSROOM_HOURS,
                dataModel,
                'exam-period-modal-tab-spin',
                resultCallback
              )
            );
          }
        }
      })
    );
  }

  chooseHour = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      const hour: string = e.currentTarget.dataset.hour || '';
      const day: string = e.currentTarget.dataset.day || '';
      let newSchedule = scheduleClone[day].map((item: any) =>
        item.hour == hour && item.status != 'disabled'
          ? {
            ...item,
            status: item.status == this.state.model.addType ? 'empty' : this.state.model.addType
          }
          : item
      );
      let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, {
        [day]: newSchedule
      });
      this.state.model.calendar.schedule = updatedSchedule;
      this.setState(this.state);
    }
  };

  chooseAllHoursOfDay = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      const day: string = e.currentTarget.dataset.day || '';

      var checkArray = scheduleClone[day].map((item: any) => item.status);

      let allEmptySlotsAreSelected: boolean = checkArray.includes('classroom') && checkArray.indexOf('empty') == -1;

      let newSchedule = scheduleClone[day].map((item: any) => {
        if (item.status != 'disabled') {
          if (allEmptySlotsAreSelected) {
            return { ...item, status: 'empty' };
          } else {
            return { ...item, status: 'classroom' };
          }
        } else return item;
      });
      let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, {
        [day]: newSchedule
      });
      this.state.model.calendar.schedule = updatedSchedule;
      this.setState(this.state);
    }
  };

  deleteClassrooms = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_classrooms_remove_planning'),
          body: T.t('gen_remove_classrooms_from_exam_schedule_question'),
          name: 'examPeriod_classrooms_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status === 200) {
                this.setState(
                  {
                    ...this.state,
                    all_ids: [],
                    selected_ids: [],
                    filters: {
                      ...this.state.filters,
                      delete_list: []
                    }
                  },
                  () => this.getClassroomHours()
                );
              }
            };
            this.state.filters.delete_list = this.state.selected_ids ? this.state.selected_ids : [];

            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_DELETE_CLASSROOMS_FROM_PERIOD,
                this.state.filters,
                'ep-classroom-list',
                resultCallback
              )
            );
          }
        })
      );
    }
  };

  onPageChange = (page: number, size?: number) => {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        page: page,
        size: size ? size : 10
      }
    }), this.getClassroomHours)
  };

  onSizeChange = (size: number) => {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        size: size
      }
    }), this.getClassroomHours)
  }

  chooseAllDaysofHour = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget) {
      let newSchedule = {};
      let scheduleClone: any = this.state.model.calendar.schedule;
      let daysClone: any = this.state.model.calendar.days;
      const time: string = e.currentTarget.dataset.time || '';

      let checkArray: any = [];
      daysClone.map((day: string) =>
        scheduleClone[day].map((item: any) => (item.hour == time ? checkArray.push(item.status) : null))
      );

      let allEmptySlotsAreSelected: boolean = checkArray.includes('classroom') && checkArray.indexOf('empty') == -1;

      daysClone.map((day: string) => {
        let dayObject = scheduleClone[day].map((item: any) => {
          if (item.hour == time) {
            if (allEmptySlotsAreSelected) {
              return item.status != 'disabled' ? { ...item, status: 'empty' } : item;
            } else {
              return item.status != 'disabled' ? { ...item, status: 'classroom' } : item;
            }
          } else return item;
        });
        newSchedule = Object.assign(newSchedule, { [day]: dayObject });
      });
      this.state.model.calendar.schedule = JSON.parse(JSON.stringify(newSchedule));
      this.setState(this.state);
    }
  };

  clearAllHours = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_tablo_clear_info'),
        body: T.t('gen_clear_hours_warning'),
        name: 'examPeriod_delete',
        icon: 'error_outline',
        iconColor: 'red',
        confirm: T.t('gen_clear'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          if (e && e.currentTarget) {
            let newSchedule = {};
            let scheduleClone: any = this.state.model.calendar.schedule;
            let daysClone: any = this.state.model.calendar.days;
            daysClone.map((day: string) => {
              let dayObject = scheduleClone[day].map((item: any) => Object.assign({}, item, { isActive: false }));
              newSchedule = Object.assign(newSchedule, { [day]: dayObject });
            });
            let schedule = newSchedule;
            let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, schedule);
            this.state.model.calendar.schedule = updatedSchedule;
            this.setState(this.state);
          }
        }
      })
    );
  };

  checkScheduleAndSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    let addType: string = e.currentTarget.dataset.addtype || '';
    let dayHasAnyTrueValues: any = [];
    if (e && e.currentTarget) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      let daysClone: any = this.state.model.calendar.days;
      dayHasAnyTrueValues = daysClone.map((day: string) => {
        return scheduleClone[day].map((item: any) => (item.status == 'classroom' ? 1 : 0)).includes(1);
      });
      if (!dayHasAnyTrueValues.includes(true)) {
        this.props.dispatch(
          Actions.ShowModal({
            title: 'Saat seçmediniz!',
            body: T.t('gen_select_hours_before_saving'),
            name: 'examPeriod_delete',
            icon: 'error_outline',
            iconColor: 'red',
            cancel: T.t('gen_close')
          })
        );
      } else if (dayHasAnyTrueValues.includes(true)) {
        this.saveClassroomHours(addType);
      }
    }
  };

  onCheckClassroom = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList: any = this.state.selected_ids ? this.state.selected_ids : [];
      let newList = Object.assign([], checkedList);
      const stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);
      if (e.currentTarget.checked) {
        newList.push(id);
      } else if (!e.currentTarget.checked) {
        newList = checkedList.filter((item: number) => item != id);
      }
      this.setState({
        ...this.state,
        selected_ids: newList
      });
    }
  };

  clearCheckedItems = () => {
    this.state.model.checked_list = [];
    this.setState(this.state);
  };

  onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList: any = this.state.model.checked_list;
      let classroomList: any = this.state.model.classroom_list.map((i) => i.classroom_id);
      let newList: any = [];
      if (e.currentTarget.checked) {
        newList = checkedList.concat(classroomList.filter((item: number) => checkedList.indexOf(item) < 0));
      } else if (e.currentTarget.checked && !checkedList.length) {
        newList = classroomList;
      } else if (!e.currentTarget.checked) {
        newList = [];
      }
      this.setState({
        ...this.state,
        model: {
          ...this.state.model,
          checked_list: newList
        }
      });
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onCheckAll) {
      this.props.onCheckAll(e);
    }
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS,
            this.state.filters,
            'ep-classroom-list',
            (response: any) =>
              this.setState({
                ...this.state,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  fetchAllIds = () => {
    this.setState(
      {
        ...this.state,
        filters: {
          ...this.state.filters,
          select_all: true
        }
      },
      () =>
        this.props.dispatch(
          Actions.ApiRequest(Constants.classroom.CLASSROOM_LIST_SEARCH, this.state.filters, 'ep-classroom-list')
        )
    );
  };

  sort = (sortkey: string, order_by: string) => {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getClassroomHours();
  };

  checker = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onHoursImported = () => {
    this.getClassroomHours();
  };

  onDownloadHours = () => {
    this.setState({
      ...this.state,
      downloadHoursModalIsOpen: !this.state.downloadHoursModalIsOpen
    });
  }

  switchDownloadHoursModalStatus = () => {
    this.setState({
      ...this.state,
      downloadHoursModalIsOpen: !this.state.downloadHoursModalIsOpen
    });
  };

  static getDerivedStateFromProps(
    props: Types.IExamPeriod_ClassroomProps,
    state: Types.IExamPeriod_Classroom_TabState
  ) {
    let hasNewState: boolean = false;
    if (props.classroomList) {
      hasNewState = true;
      state.model.classroom_list = props.classroomList;
      if (
        props.storedCalendar &&
        props.storedCalendar.times &&
        props.storedCalendar.days &&
        Object.keys(props.storedCalendar.schedule).length &&
        !state.model.is_calendar_created
      ) {
        state.model.calendar = props.storedCalendar;
      }
    }
    if (props.all_ids && props.all_ids.length) {
      hasNewState = true;
      state.all_ids = props.all_ids;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    const appLang = localStorage.getItem('langid');
    const lang = appLang ? appLang.split('-')[0] : 'tr';
    moment.locale(lang);

    let classroomFeatureOptions = ClassroomFeatureOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_features && this.props.selectOptions.additional_classroom_features.length > 0) {
      classroomFeatureOptions = [...ClassroomFeatureOptions(T), ...this.props.selectOptions.additional_classroom_features].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    let tableHeaders = null;

    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    const all_ids = this.state.all_ids ? this.state.all_ids : [];

    let { classroom_list, checked_list, selected_Classroom } = this.state.model;

    let { times, days } = this.state.model.calendar;

    let classroom_list_ids = classroom_list.map((person) => person.classroom_id);
    let checkAllChecked: boolean = classroom_list_ids.every((id: number) => checked_list.includes(id));
    let tableColumnHeads = days.map((day) => (
      <th key={day}>
        <div data-day={day} onClick={this.chooseAllHoursOfDay}>
          {moment(day).format('D MMM ddd')}
        </div>
      </th>
    ));

    let timeTableRows = null;

    timeTableRows = times.map((time: string) => {
      let boxes = Array(days.length)
        .fill(undefined)
        .map((val, dayindex) => {
          let day: string = days[dayindex];
          let scheduleClone: any = this.state && this.state.model.calendar && this.state.model.calendar.schedule;
          return (
            <td key={dayindex}>
              <div
                data-day={day}
                data-hour={time}
                onClick={this.chooseHour}
                className={
                  scheduleClone[day].find((item: any) => item.hour == time).status == 'empty'
                    ? ''
                    : scheduleClone[day].find((item: any) => item.hour == time).status == 'classroom'
                      ? 'on'
                      : 'unavailable'
                }
              ></div>
            </td>
          );
        });

      return (
        <tr>
          <td className="aplan-tm-sticky-col">
            <div data-time={time} onClick={this.chooseAllDaysofHour}>
              {time}
            </div>
          </td>
          {boxes}
        </tr>
      );
    });

    tableHeaders = (
      <tr>
        <th data-cell="select">
          <div className="tick-radio position-relative">
            <input id='select_all' type="checkbox" className="form-radio" checked={this.checker()} onChange={this.onSelectAll} />
          </div>
        </th>
        <th></th>
        <SortedColumn
          datacell="name"
          className="d-none d-lg-table-cell d-xl-table-cell text-center"
          title={T.t('gen_name_and_code')}
          sortkey="name"
          sortedcolumn={this.state.filters.order_by}
          sort={this.sort}
        />
        <SortedColumn
          id="clickList"
          datacell="building_name"
          className="d-none d-lg-table-cell d-xl-table-cell text-center"
          title={T.t('gen_campus_and_building')}
          sortkey="building_name"
          sortedcolumn={this.state.filters.order_by}
          sort={this.sort}
        />
        <SortedColumn
          datacell="classroom_type_exam_capacity"
          className="d-none d-lg-table-cell d-xl-table-cell text-center"
          title={T.t('gen_classroom_type') + ' / ' + T.t('gen_exam_capacity')}
          sortkey="classroom_type_exam_capacity"
          sortedcolumn={this.state.filters.order_by}
          sort={this.sort}
        />
        <th scope="col" className="text-center">
          {T.t('gen_description')}
        </th>
      </tr>
    );

    let table = (
      <div className="row">
        {this.state.selected_ids && this.state.selected_ids.length ? (
          <React.Fragment>
            <button
              id='button_cancel_selection'
              className="category-tag-square tag-gray float-left pr-2 pl-2 ml-3"
              style={{ margin: '5px' }}
              onClick={() => {
                this.setState({
                  ...this.state,
                  selected_ids: [],
                  filters: {
                    ...this.state.filters,
                    select_all: false
                  }
                });
              }}
            >
              <i className="material-icons mr-2">close</i>
              <span>
                {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
              </span>
            </button>
            {this.props.user && (this.props.user.role === 's') ? (
              <button
                id='delete_selected'
                className="category-tag-square tag-red float-left pr-2 pl-2"
                style={{ margin: '5px' }}
                onClick={this.deleteClassrooms}
              >
                <i className="material-icons mr-2" style={{ color: 'white' }}>
                  delete_outline
                </i>
                <span> {T.t('gen_delete_selected')}</span>
              </button>
            ) : null}
          </React.Fragment>
        ) : this.state.model.showSearchBar ? (
          <div className="align-items-center d-inline-flex w-100 mb-4">
            <div className="searchWrapper float-right">
              <div id="filterWrap2" className="search in width collapse show mt-2" style={{ top: '0px' }}>
                <div className="input-group autocomplete">
                  <input
                    type="text"
                    name="filter2"
                    className="form-control"
                    value={this.state.filters.query}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.setState({
                        ...this.state,
                        filters: {
                          ...this.state.filters,
                          query: event.target.value
                        }
                      })
                    }
                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                      if (event.keyCode == 13) {
                        this.getClassroomHours();
                      }
                    }}
                    id="filter2"
                    placeholder={T.t('gen_search')}
                    data-class="fup"
                  />
                  <div id="reset-filter" className="input-group-prepend collapsed ml-1" aria-expanded="false">
                    <span
                      id='cancel_list'
                      className="category-tag-square tag-glass pl-0 pr-2"
                      onClick={() => {
                        this.setState(
                          {
                            ...this.state,
                            model: {
                              ...this.state.model,
                              showSearchBar: !this.state.model.showSearchBar
                            },
                            filters: {
                              ...this.state.filters,
                              query: ''
                            }
                          },
                          () => {
                            this.getClassroomHours();
                          }
                        );
                      }}
                    >
                      <i data-toggle="tooltip" className="material-icons" data-original-title="Listeden Çıkar">
                        close
                      </i>
                      {T.t('gen_cancel')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12">
            <div className="quick-actions">
              <div className="align-items-center d-inline-flex w-100 mb-2">
                <button
                  id='button_arrow_back'
                  className="category-tag-square tag-gray float-left pr-2 pl-2"
                  style={{ margin: '5px' }}
                  onClick={this.switchDisplay}
                >
                  <i className="material-icons mr-2">arrow_back</i>
                  <span>{T.t('gen_view_list')}</span>
                </button>
                {this.props.user && (this.props.user.role === 's') ? (
                  <button
                    id='button_playlist_add'
                    className="category-tag-square tag-green float-left pr-2 pl-2"
                    style={{ margin: '5px' }}
                    onClick={this.switchAddModalStatus}
                  >
                    <i className="material-icons mr-2">playlist_add</i>
                    <span>{T.t('gen_add')}</span>
                  </button>
                ) : null}
                {this.props.user && (this.props.user.role === 's') ? (
                  <ImportModal
                    title={T.t('gen_add_with_excel')}
                    componentKey={ExcelImportKeys.PlanningClassroomHours}
                    dispatch={this.props.dispatch}
                    onImport={this.onHoursImported}
                  />
                ) : null}
                {this.props.user && (this.props.user.role === 's') ? (
                  <button className="category-tag-square tag-blue ml-1 pl-2" onClick={this.onDownloadHours}>
                    <i className="material-icons mr-2" style={{ color: 'white' }}>cloud_download</i>
                    <span>{T.t('gen_download')}</span>
                  </button>
                ) : null}
                <div
                  className="d-inline-flex align-self-center align-items-center sidebar-controls"
                  style={{ right: '5px' }}
                >
                  <span
                    id='search'
                    className="category-tag-square tag-glass mr-4"
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        model: {
                          ...this.state.model,
                          showSearchBar: !this.state.model.showSearchBar
                        }
                      });
                    }}
                  >
                    <i data-toggle="tooltip" className="material-icons" data-original-title="Listeden Çıkar">
                      search
                    </i>
                    {T.t('gen_search')}
                  </span>
                  <button
                    id='button_filter'
                    className="category-tag-square tag-glass float-right ml-3 mr-3"
                    style={{ margin: '5px' }}
                    onClick={() => {
                      this.state.filterIsOpen = !this.state.filterIsOpen;
                      this.setState(this.state);
                    }}
                  >
                    <i className="material-icons mr-2">filter_list</i>
                    <span>{T.t('gen_filter')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)} id="advance-search">
          <div className="advance-search d-block mt-3">
            <Formik
              initialValues={CalendarModalSearchInitialValues}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                values.term_id = this.state.filters.term_id;
                this.onFilterClassroom(values, actions);
              }}
              onReset={this.onFormReset}
            >
              {(props: FormikProps<Types.IFilterClassroom>) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <h6>{T.t('gen_filter_parameters')}</h6>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="classroom_code"
                            name="classroom_code"
                            value={props.values.classroom_code}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="classroom_code">{T.t('gen_classroom_code')}</label>
                          <i className="material-icons">filter_1</i>
                        </div>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="name"
                            name="name"
                            value={props.values.name}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="name">{T.t('gen_classroom_name')}</label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_campus')}</label>
                            <Select
                              id='select_campus'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.campuses
                                  ? this.props.selectOptions.campuses
                                  : []
                              }
                              placeholder={T.t('gen_select_campus')}
                              value={props.values.campuses ? props.values.campuses : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('campuses', list);
                                props.setFieldValue('campus_ids', list.map((item) => item.value));
                                this.getBuildingsByCampusesAtClassrooms(list.map((item) => item.value));
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_building')}</label>
                            <Select
                              id='select_building'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={this.props.buildings_related_campuses ? this.props.buildings_related_campuses : []}
                              placeholder={T.t('gen_select_building')}
                              value={props.values.buildings ? props.values.buildings : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('buildings', list);
                                props.setFieldValue('building_ids', list.map((item) => item.value));
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_building')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_classroom_features')}</label>
                            <Select
                              id='select_feature'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={classroomFeatureOptions}
                              placeholder={T.t('gen_select_feature')}
                              value={props.values.classroom_features}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('classroom_features', list);
                                props.setFieldValue(
                                  'classroom_feature_codes',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_feature')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_classroom_type')}</label>
                            <Select
                              id='select_type'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.classroom_types
                                  ? this.props.selectOptions.classroom_types.filter(filterClassroomTypesForEventModuleStatus(this.props.general_settings))
                                  : []
                              }
                              placeholder={T.t('gen_select_type')}
                              value={props.values.classroom_types ? props.values.classroom_types : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('classroom_types', list);
                                props.setFieldValue(
                                  'classroom_type_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_type')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="building_floor"
                            name="building_floor"
                            value={props.values && props.values.building_floor}
                            onChange={props.handleChange}
                            type="number"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="building_floor">{T.t('gen_floor')}</label>
                        </div>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="door_order"
                            name="door_order"
                            value={props.values.door_order}
                            onChange={props.handleChange}
                            type="number"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="door_order">{T.t('gen_door_number')}</label>
                        </div>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="exam_capacity"
                            name="exam_capacity"
                            value={props.values.exam_capacity}
                            onChange={props.handleChange}
                            type="number"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="exam_capacity">{T.t('gen_exam_capacity')}</label>
                        </div>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="lecture_capacity"
                            name="lecture_capacity"
                            value={props.values.lecture_capacity}
                            onChange={props.handleChange}
                            type="number"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="lecture_capacity">{T.t('gen_lecture_capacity')}</label>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row mt-3">
                      <div className="col-6">
                        <button
                          id='button_arrow_upward'
                          type="button"
                          onClick={() => {
                            this.state.filterIsOpen = false;
                            this.setState(this.state);
                          }}
                          className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                        >
                          <i className="material-icons">arrow_upward</i>
                        </button>
                        <button
                          id='button_delete_sweep'
                          type="reset"
                          onClick={props.handleReset}
                          className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                        >
                          <i className="material-icons">delete_sweep</i>
                        </button>
                      </div>
                      <div className="col-6 text-right">
                        <button
                          id='button_search'
                          type="button"
                          className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                          onClick={() => props.handleSubmit()}
                          disabled={props.isSubmitting}
                        >
                          <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="col-12 mt-2">
          <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
            <thead>{tableHeaders}</thead>
            <tbody>
              {classroom_list && classroom_list.length ? (
                classroom_list.map((item: Types.IExamPeriod_ClassroomItem) => {
                  let tableContent = null;
                  const classroomCode =
                    item.classroomInfo && item.classroomInfo.classroom_code
                      ? `(${item.classroomInfo.classroom_code})`
                      : '';

                  tableContent = (
                    <tr
                      key={'classroom-' + item.classroom_id}
                      data-title={item.classroomInfo && item.classroomInfo.name}
                      data-id={item.classroom_id}
                      onClick={this.onSelectClassroom}
                      style={
                        this.state.model.selected_Classroom &&
                          this.state.model.selected_Classroom.classroom_id == item.classroom_id
                          ? {
                            backgroundColor: 'rgba(218, 225, 232, 0.96)',
                            border: '1px solid darkgrey',
                            cursor: 'pointer'
                          }
                          : {
                            cursor: 'pointer'
                          }
                      }
                    >
                      <td data-cell="select">
                        <div className="tick-radio position-relative">
                          <input
                            id='check_classroom'
                            type="checkbox"
                            className="form-radio"
                            checked={this.state.selected_ids && this.state.selected_ids.indexOf(item.classroom_id) > -1}
                            data-id={item.classroom_id}
                            data-name={
                              item.classroomInfo && item.classroomInfo.classroom_code + ' - ' + item.classroomInfo.name
                            }
                            data-item={JSON.stringify(item)}
                            onChange={this.onCheckClassroom}
                          />
                        </div>
                      </td>
                      <td>
                        {item.status ? (
                          <i className="material-icons" style={{ color: '#33c900c4' }}>
                            check_circle
                          </i>
                        ) : (
                          <i className="material-icons" style={{ color: '#ff0000c4' }}>
                            cancel
                          </i>
                        )}
                      </td>
                      <td data-label={T.t('gen_name_and_code')} className="text-center" style={{ width: '40%' }}>
                        {item.classroomInfo && item.classroomInfo.name + ' ' + classroomCode}
                      </td>
                      <td data-label={T.t('gen_campus_and_building')} className="text-center" style={{ width: '30%' }}>
                        {item.classroomInfo && item.classroomInfo.campus_name && item.classroomInfo.building_name}
                      </td>
                      <td data-label={T.t('gen_classroom_type') + '/' + T.t('gen_exam_capacity')} className="text-center" style={{ width: '30%' }}>
                        {item.classroomInfo && item.classroomInfo.classroom_type} /{' '}
                        {item.classroomInfo && item.classroomInfo.exam_capacity}
                      </td>
                      <td data-label={T.t('gen_description')} className="text-center">
                        {item.classroomInfo && item.classroomInfo.description && item.classroomInfo.description.length ?
                          <div className="table-scrollable-td">{item.classroomInfo.description}</div> : '-'}
                      </td>
                    </tr>
                  );
                  return tableContent;
                })
              ) : (
                <tr>
                  <td colSpan={24}>
                    <Announce title={T.t('gen_no_records_found')} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row-options justify-content-end">
            <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
              {this.state.model.classroom_list && this.state.model.classroom_list.length > 0 ? (
                <Paginate filters={this.props.filters} onPageChange={this.onPageChange} onSizeChange={this.onSizeChange} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );

    let BottomButtons =
      this.state.selected_ids && this.state.selected_ids.length > 1 ? (
        this.props.user && (this.props.user.role === 's') ? (
          <button
            id='button_save_all'
            type="button"
            className="btn btn-blue mt-2 mb-2 float-right"
            data-addtype="multiple"
            onClick={this.checkScheduleAndSave}
          >
            <i className="material-icons mr-2 text-left">done_all</i>
            <span>{T.t('gen_save_multi')}</span>
          </button>
        ) : null
      ) : (
        this.props.user && (this.props.user.role === 's') ? (
          <button
            id='button_save'
            type="button"
            className="btn btn-green mt-2 mb-2 mr-2 float-right"
            data-addtype="single"
            onClick={this.checkScheduleAndSave}
          >
            <i className="material-icons mr-2 text-left">done</i>
            <span>{T.t('gen_save')}</span>
          </button>
        ) : null
      );

    let ClassroomPage = this.state.model.displayListPage ? (
      <div className="white-container mt-4">
        <div className="row">
          <div className="col-xl-12 col-lg-8 col-md-12 classrooms">
            <ClassroomListPage
              calledFromCourseTab={false}
              onShowModal={this.switchAddModalStatus}
              onSwitch={this.switchDisplay}
              checkedClassroomIds={this.state.model.checked_list}
            />
          </div>
          <ClassroomAddModal
            calledFromCourseTab={false}
            modalIsOpen={this.state.model.addModalIsOpen}
            onClose={this.switchAddModalStatus}
            term_id={this.state.filters.term_id}
            onUpdateList={() => this.getClassroomHours()}
          />
        </div>
      </div>
    ) : (
      <React.Fragment>
        <div className="row">
          <div className="col-xl-5 col-lg-5 col-md-5">
            <div className="generic-wrapper">
              <div className="container-fluid p-0">{table}</div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-7" id="RightPanel">
            <div className="generic-wrapper">
              {Object.keys(this.state.model.calendar.schedule).length !== 0 &&
                this.state.model.selected_Classroom &&
                this.state.model.selected_Classroom.classroom_id ? (
                <React.Fragment>
                  <div className="tab-content" id="nav-tabContent2">
                    <div className="tab-pane fade active show" id="hours" role="tabpanel" aria-labelledby="hours">
                      <div className="row">
                        <div className="col-12">
                          <div className="aplan-table-matrix-wrapper">
                            <div
                              className="aplan-table-matrix-scroller"
                              style={this.state.model.isExpanded ? { height: '100%' } : { height: '434px' }}
                            >
                              <table className="table table-borderless table-striped table-hover aplan-table-matrix mb-0">
                                <thead>
                                  <tr>
                                    <th className="aplan-tm-sticky-col">
                                      <div>#</div>
                                    </th>
                                    {tableColumnHeads}
                                  </tr>
                                </thead>
                                <tbody>{timeTableRows}</tbody>
                              </table>
                            </div>
                          </div>
                          {BottomButtons}
                          <button
                            id='button_expand'
                            type="button"
                            className="btn btn-gray mt-2 mb-2 float-none"
                            onClick={() => {
                              this.state.model.isExpanded = !this.state.model.isExpanded;
                              this.setState(this.state);
                            }}
                          >
                            <i className="material-icons mr-2 text-left">
                              {this.state.model.isExpanded ? 'remove' : 'add'}
                            </i>
                            <span>{this.state.model.isExpanded ? T.t('gen_collapse') : T.t('gen_expand')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div>
                  <img
                    id='classroom_hours_area_info'
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: '40%'
                    }}
                    src={Announcement}
                  />
                  <br />
                  <p style={{ textAlign: 'center' }}>
                    {T.t('gen_classroom_hours_area_info_text')} <br /> {T.t('gen_remember_to_save_hours')}
                  </p>
                </div>
              )}
            </div>
          </div>
          <ClassroomAddModal modalIsOpen={this.state.model.addModalIsOpen} onClose={this.switchAddModalStatus} />
          {
            this.state.downloadHoursModalIsOpen ? (
              <HoursExportModal
                onClose={this.switchDownloadHoursModalStatus}
                modalIsOpen={this.state.downloadHoursModalIsOpen}
                termID={this.state.model.term_id}
                sectionType={SectionTypes.Planning_Classrooms}
              />
            ) : null
          }
        </div>
      </React.Fragment>
    );

    return <React.Fragment>{ClassroomPage}</React.Fragment>;
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IExamPeriod_ClassroomProps
): Types.IExamPeriod_ClassroomProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IExamPeriod_ClassroomProps = Object.assign({}, ownProps, {
    user: store.state.user,
    classroomList:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.classroom_hours &&
      store.state.examPeriodModal.classroom_hours.classroom_list,
    storedCalendar: store.state.examPeriodModal && store.state.examPeriodModal.storedCalendar,
    all_ids:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.classroom_hours &&
      store.state.examPeriodModal.classroom_hours.all_ids,
    filters:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.classroom_hours &&
      store.state.examPeriodModal.classroom_hours.classroom_hours_filters,
    selectOptions: store.state.select_options && store.state.select_options.classroomPage,
    buildings_related_campuses: store.state.select_options && store.state.select_options.buildings_related_campuses,
    general_settings: store.state.general_settings,
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(prev.state.examPeriodModal, next.state.examPeriodModal) &&
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.classroom_hours,
        next.state.examPeriodModal && next.state.examPeriodModal.classroom_hours
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.buildings_related_campuses,
        next.state.select_options && next.state.select_options.buildings_related_campuses
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.classroomPage,
        next.state.select_options && next.state.select_options.classroomPage
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ClassroomHours);

export default container;

import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  CourseFormInitialValues,
  ClassroomCombineTypes,
  ExamPeriodCourseTermLectureLocations,
  Grades,
  ExamTypes,
  ExamEnvironments,
  ExamOverlapTypes,
  InvigilatorStatuses,
} from '../../store/constants/course-const';
import * as Types from '../../store/types';
import { CourseFormValidation } from '../exam-period/course/validations/course-form-val';
import MultiSelectionTable from './components/multi-selection-table';
import Translator from '../../services/translate-factory';
import { filterClassroomTypesForEventModuleStatus } from '../../util/sort';
import { allRestirectedRoles, isUserEquals } from '../../util/authorize';

const T = Translator.create();
const Logger = Log.create('ClassroomForm');

let multiSelectedRow: boolean = false;
let saveButtonClicked: boolean = false;
let combined_values_selected: Array<any> = new Array<any>();

function getInitialState(): Types.ICourseFormState {
  const initialValues: Types.ICourseFormState = {
    model: Object.assign({}, CourseFormInitialValues),
    isAdded: false,
    alreadyInTheList: false
  };
  return Object.assign({}, initialValues);
}

class CourseHourForm extends Component<Types.ICourseHoursFormProps, Types.ICourseFormState> {
  state: Types.ICourseFormState = getInitialState();
  componentRef: any;

  constructor(props: any) {
    super(props);
    this.componentRef = React.createRef();
  }
  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
    this.getProgramsByFacultiesAtCourses([]);
    this.putToTable(this.createFieldValues(), false);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    combined_values_selected && combined_values_selected.splice(0, combined_values_selected.length);
  }

  getProgramsByFacultiesAtCourses = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'course-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    this.setState(this.state);
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.ICourseItem, FormActions: FormikActions<Types.ICourseItem>) => {
    if (saveButtonClicked) {

      const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
        if (result && result.code) {
          let errors: any = {};
          if (result.details) {
            const validations: Array<Types.IValidationResponse> = result.details;
            validations.forEach((m: Types.IValidationResponse) => {
              errors[m.field] = m.message[0];
            });
          }
          FormActions.setErrors(errors);
        }
        if (status === 200 || status === 201) {
          this.setClose(true);
        }
        else if (status === 406) {
          this.props.dispatch(
            Actions.ShowModal({
              title: T.t('gen_error'),
              body: result,
              name: 'failed',
              icon: 'warning',
              iconColor: 'red',
              cancel: T.t('gen_ok'),
            })
          );
          multiSelectedRow = false;
          this.setClose(true);
        }
      };

      this.props.course.courseInfo = model;
      const dataModel = this.props.course;

      let course_programs: any;
      course_programs = combined_values_selected.map((item: any, index: any) => (
        {
          faculty_id: item.faculty_id,
          campus_id: model.campus_id,
          program_id: item.program_id,
          class: item.class_id,
          section: item.section,
          instructor_id: item.instructor_id,
          student_count: item.student_count
        }));

      let newModel;
      if (combined_values_selected == undefined || combined_values_selected.length == 0) {
        this.props.dispatch(Actions.Notification(T.t("gen_list_isnot_left_blank"), 'gen_warning', 'danger'))
      } else {
        if (multiSelectedRow) {
          newModel = {
            courseInfo: {
              assistant_staff: model.assistant_staff,
              assistant_staff_ids: model.assistant_staff_ids,
              campus: model.campus,
              campus_id: model.campus_id,
              campuses: model.campuses,
              classroom_combine_status: model.classroom_combine_status,
              conjugate_course_code: model.conjugate_course_code,
              course_code: model.course_code,
              course_id: model.course_id,
              description: model.description,
              exam_duration: model.exam_duration,
              exam_session: model.exam_session,
              faculty_ids: combined_values_selected.map((item: any) => item.faculty_id),
              program_ids: combined_values_selected.map((item: any) => item.program_id),
              instructor_ids: combined_values_selected.map((item: any) => item.instructor_id),
              student_counts: combined_values_selected.map((item: any) => item.student_count),
              sections: combined_values_selected.map((item: any) => item.section),
              class_ids: combined_values_selected.map((item: any) => item.class_id),
              campus_ids: Array(combined_values_selected.length).fill(model.campus_id),
              invigilator_gap_after_exam: model.invigilator_gap_after_exam,
              invigilator_gap_before_exam: model.invigilator_gap_before_exam,
              lesson_count: model.lesson_count,
              name: model.name,
              status: model.status,
              exam_type: model.exam_type,
              exam_environment: model.exam_environment,
              course_language: model.course_language_select ? model.course_language_select!.value : '',
              classroom_type: model.classroom_type_select ? model.classroom_type_select!.value : '',
              exam_overlap_type: model.exam_overlap_type,
              invigilator_status: model.invigilator_status,
              term_id: model.term_id,
              combined_course_ids: [],
              combined_courses: [],
              course_programs: course_programs
            },
            course_hour_id: dataModel.course_hour_id,
            course_id: dataModel.course_id,
            course_ids: [],
            course_hour_ids: [],
            activity_noes: [],
            status: dataModel.status,
            term_id: dataModel.term_id,
            time_slots: dataModel.time_slots,
            status_classroom_assignment: dataModel.status_classroom_assignment
          }
          multiSelectedRow = false;
        }

        this.props.dispatch(
          Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_EDIT_COURSE, newModel, 'course-form-spin', resultCallback)
        );

        FormActions.setSubmitting(false);
        saveButtonClicked = false;
      }
    }
  };

  putToTable = (values: any, plusButtonClicked: boolean) => {
    values.section = values.section == '' ? '1' : values.section;

    combined_values_selected = values.faculty_ids && values.faculty_ids.map((element: any, index: number) => (
      {
        faculty_id: values.faculty_ids[index],
        faculty: values.faculties[index],
        program_id: values.program_ids[index],
        program: values.programs[index],
        class_id: values.class_ids[index],
        grade: values.classes[index],
        section: values.sections[index],
        instructor_id: values.instructor_ids[index],
        instructor: values.instructors[index],
        student_count: values.student_counts[index],
        index: [...Array(values.faculty_ids && values.faculty_ids.length)].map((element: any, index: number) => index + 1)[index],
      }));

    this.setState({
      isAdded: true
    })
    this.state.isAdded = true;

    plusButtonClicked = !plusButtonClicked;
  };

  multiSelecteds(
    combined_values: any
  ) {
    multiSelectedRow = true;
    combined_values_selected = combined_values
  }

  createFieldValues = (): Types.ICourseItem => {
    let fields: Types.ICourseItem = {
      course_code: '',
      conjugate_course_code: '',
      name: '',
      student_counts: [],
      instructor_ids: [],
      assistant_staff: [],
      assistant_staff_ids: [],
      programs: [],
      program_ids: [],
      campus: undefined,
      campus_id: 0,
      faculty: undefined,
      exam_duration: undefined,
      class: undefined,
      section: '',
      sections: [],
      exam_session: undefined,
      description: '',
      instructor_id: 0,
      class_id: 0,
      program_id: 0,
      faculty_id: 0,
    };
    let courseInfo = this.props.course.courseInfo;
    fields = {
      course_code: courseInfo && courseInfo.course_code ? courseInfo.course_code : '',
      conjugate_course_code: courseInfo && courseInfo.conjugate_course_code ? courseInfo.conjugate_course_code : '',
      name: courseInfo && courseInfo.name ? courseInfo.name : '',
      campus: courseInfo && courseInfo.campuses ? courseInfo.campuses[0] : undefined,
      campus_id: courseInfo && courseInfo.campus_ids ? courseInfo.campus_ids[0] : undefined,
      faculties: courseInfo && courseInfo.faculties ? courseInfo.faculties : [],
      faculty_ids: courseInfo && courseInfo.faculty_ids ? courseInfo.faculty_ids : undefined,
      student_counts: courseInfo && courseInfo.student_counts ? courseInfo.student_counts : [],
      invigilator_gap_before_exam: courseInfo && courseInfo.invigilator_gap_before_exam ? courseInfo.invigilator_gap_before_exam : 0,
      invigilator_gap_after_exam: courseInfo && courseInfo.invigilator_gap_after_exam ? courseInfo.invigilator_gap_after_exam : 0,
      instructors: courseInfo && courseInfo.instructors ? courseInfo.instructors : [],
      instructor_ids: courseInfo && courseInfo.instructor_ids ? courseInfo.instructor_ids : [],
      assistant_staff: courseInfo && courseInfo.assistant_staff ? courseInfo.assistant_staff : [],
      assistant_staff_ids: courseInfo && courseInfo.assistant_staff_ids ? courseInfo.assistant_staff_ids : [],
      programs: courseInfo && courseInfo.programs ? courseInfo.programs : [],
      program_ids: courseInfo && courseInfo.program_ids ? courseInfo.program_ids : [],
      class_ids: courseInfo && courseInfo.class_ids ? courseInfo.class_ids : [],
      classes: courseInfo && courseInfo.classes ? courseInfo.classes : [],
      sections: courseInfo && courseInfo.sections ? courseInfo.sections : undefined,
      section: courseInfo && courseInfo.section ? courseInfo.section : '',
      exam_duration: courseInfo && courseInfo.exam_duration ? courseInfo.exam_duration : undefined,
      exam_session: courseInfo && courseInfo.exam_session ? courseInfo.exam_session : undefined,
      classroom_combine_status: courseInfo && courseInfo.classroom_combine_status,
      description: courseInfo && courseInfo.description ? courseInfo.description : undefined,
    };
    return fields;
  };

  static getDerivedStateFromProps(props: Types.ICourseHoursFormProps, state: Types.ICourseFormState) {
    let hasNewState: boolean = false;

    if (props.course && props.course.course_hour_id) {
      state.model = props.course.courseInfo;
      state.model.campus = props.course.courseInfo && props.course.courseInfo.campuses ? props.course.courseInfo.campuses[0] : undefined;
      state.model.campus_id = props.course.courseInfo && props.course.courseInfo.campus_ids ? props.course.courseInfo.campus_ids[0] : undefined;
      hasNewState = true;
    }

    if (hasNewState) {
      return state;
    } else return null;
  }

  render() {
    const formInitialValues = this.createFieldValues();
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const CampusSelectOptions = [...ExamPeriodCourseTermLectureLocations(T), ...campusOptions];

    const scrollToTop = () => {
      this.componentRef.current.scrollIntoView({ block: 'nearest' });
    }

    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Spinner name="faculty-form-spin" />

        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={CourseFormValidation(T)}
        >
          {(props: FormikProps<Types.ICourseItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit } = props;

            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div ref={this.componentRef}></div>
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          <i className="material-icons mr-2">add_circle_outline</i>
                          <span>{T.t("gen_update_course")}</span>
                        </h5>
                        <button
                          id='button_close'
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.setCloseForm}
                        >
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                      </div>
                      <div className="row mt-3 mb-2">
                        <div className="col-md-12 ml-3">
                          {T.t('gen_course_opened_id')}:
                          <button className="multiple-info-tag small-tag group-ellipsis ml-1">
                            {props.values.course_opened_id ? props.values.course_opened_id : "-"}
                          </button>
                        </div>
                        <div className="col-md-12 ml-3">
                          {T.t('gen_course_opened_section')}:
                          <button className="multiple-info-tag small-tag group-ellipsis ml-1">
                            {props.values.course_opened_sections ? props.values.course_opened_sections.join("; ") : "-"}
                          </button>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="course_code"
                              name="course_code"
                              value={values.course_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="course_code">{T.t('gen_code')}</label>
                            {errors && errors.course_code && (
                              <div className="error">{T.t(errors && errors.course_code)}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="conjugate_course_code"
                              name="conjugate_course_code"
                              value={values.conjugate_course_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="conjugate_course_code">{T.t('gen_conjugate_course_code')}</label>
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="name">{T.t('gen_name')}</label>
                            {errors && errors.name && (
                              <div className="error">{T.t(errors && errors.name)}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="exam_duration"
                              name="exam_duration"
                              value={values.exam_duration}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="exam_duration">{T.t('gen_exam_duration')} ({T.t('gen_slot_count')})</label>
                            {errors && errors.exam_duration && (
                              <div className="error">{T.t(errors && errors.exam_duration)}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="invigilator_gap_before_exam"
                              name="invigilator_gap_before_exam"
                              value={values.invigilator_gap_before_exam}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="invigilator_gap_before_exam">{T.t('gen_invigilator_gap_before_exam')} ({T.t('gen_slot_count')})</label>
                            {errors && errors.invigilator_gap_before_exam && (
                              <div className="error">{T.t(errors && errors.invigilator_gap_before_exam)}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="invigilator_gap_after_exam"
                              name="invigilator_gap_after_exam"
                              value={values.invigilator_gap_after_exam}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="invigilator_gap_after_exam">{T.t('gen_invigilator_gap_after_exam')} ({T.t('gen_slot_count')})</label>
                            {errors && errors.invigilator_gap_after_exam && (
                              <div className="error">{T.t(errors && errors.invigilator_gap_after_exam)}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="exam_session"
                              name="exam_session"
                              value={values.exam_session}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="exam_session">{T.t('gen_session_count')}</label>
                            {errors && errors.exam_session && (
                              <div className="error">{T.t(errors && errors.exam_session)}</div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_exam_type')}</label>
                                <Select
                                  id='select_exam_type'
                                  className="react-select"
                                  isMulti={false}
                                  isClearable={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={ExamTypes(T)}
                                  placeholder={T.t('gen_select_exam_type')}
                                  value={
                                    values.exam_type != undefined
                                      ? ExamTypes(T).find(
                                        (option) => option.value === values.exam_type
                                      )
                                      : null
                                  }
                                  onChange={(option: any) =>
                                    props.setFieldValue('exam_type', option && option.value)
                                  }
                                  noOptionsMessage={(): string => T.t('gen_select_no_exam_type')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_course_language')}</label>
                                <Select
                                  id='select_course_language'
                                  className="react-select"
                                  isMulti={false}
                                  isClearable={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.course_languages_active
                                      ? this.props.selectOptions.course_languages_active
                                      : []
                                  }
                                  placeholder={T.t('gen_select_course_language')}
                                  value={props.values.course_language_select}
                                  onChange={(option: any) => {
                                    props.setFieldValue('course_language', option && option.value);
                                    props.setFieldValue('course_language_select', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_course_language')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_classroom_type')}</label>
                                <Select
                                  id='classroom_type'
                                  className="react-select"
                                  isMulti={false}
                                  isClearable={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.classroom_types_active
                                      ? this.props.selectOptions.classroom_types_active.filter(filterClassroomTypesForEventModuleStatus(this.props.general_settings))
                                      : []
                                  }
                                  placeholder={T.t('gen_select_classroom_type')}
                                  value={props.values.classroom_type_select}
                                  onChange={(option: any) => {
                                    props.setFieldValue('classroom_type', option && option.value);
                                    props.setFieldValue('classroom_type_select', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_exam_environment')}</label>
                                <Select
                                  id='select_exam_environmet'
                                  className="react-select"
                                  isMulti={false}
                                  isClearable={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={ExamEnvironments(T)}
                                  placeholder={T.t('gen_select_exam_environment')}
                                  value={
                                    values.exam_environment != undefined
                                      ? ExamEnvironments(T).find(
                                        (option) => option.value === values.exam_environment
                                      )
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('exam_environment', option && option.value)
                                    props.setFieldValue('exam_environment_select', option);
                                    if (option && option.value == ExamEnvironments(T)[1].value) {
                                      values.campus_id = ExamPeriodCourseTermLectureLocations(T)[0].value;
                                      values.campus = ExamPeriodCourseTermLectureLocations(T)[0];
                                      values.classroom_combine_status = ClassroomCombineTypes(T)[0].value;
                                    } else {
                                      if (values.campus_id == ExamPeriodCourseTermLectureLocations(T)[0].value) {
                                        values.campus_id = undefined;
                                        values.campus = undefined;
                                      }
                                    }
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_exam_environment')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_classroom_combine_status')}</label>
                                <Select
                                  id='select_status'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={ClassroomCombineTypes(T)}
                                  placeholder={T.t('gen_select_status')}
                                  value={
                                    values.classroom_combine_status != undefined
                                      ? ClassroomCombineTypes(T).find(
                                        (option) => option.value === values.classroom_combine_status
                                      )
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('classroom_combine_status', option.value)
                                    if (option.value != ClassroomCombineTypes(T)[0] && values.campus_id == ExamPeriodCourseTermLectureLocations(T)[0].value) {
                                      values.campus_id = undefined;
                                      values.campus = undefined;
                                    }
                                    if (option.value != ClassroomCombineTypes(T)[0] && values.exam_environment == ExamEnvironments(T)[1].value) {
                                      values.exam_environment = undefined;
                                      values.exam_environment_select = undefined;
                                    }
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_status')}
                                />
                              </div>
                              {errors && errors.classroom_combine_status && (
                                <div className="error">{T.t(errors && errors.classroom_combine_status)}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_assistants')}</label>
                                <Select
                                  id='select_assistant'
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={false}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.instructors
                                      ? this.props.selectOptions.instructors
                                      : []
                                  }
                                  placeholder={T.t('gen_select_assistant')}
                                  value={props.values.assistant_staff}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: Array<Types.ISelectOption> = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('assistant_staff', list);
                                    props.setFieldValue(
                                      'assistant_staff_ids',
                                      list.map((item) => item.value)
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_assistan')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_campus')}</label>
                                <Select
                                  id='select_campus'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={CampusSelectOptions}
                                  placeholder={T.t('gen_select_location')}
                                  value={props.values.campus ? props.values.campus : null}
                                  onChange={(option: any) => {
                                    props.setFieldValue('campus', option);
                                    props.setFieldValue('campus_id', option && option.value);
                                    if (option.value == ExamPeriodCourseTermLectureLocations(T)[0].value) {
                                      values.exam_environment = ExamEnvironments(T)[1].value;
                                      values.exam_environment_select = ExamEnvironments(T)[1];
                                      values.classroom_combine_status = ClassroomCombineTypes(T)[0].value;
                                    } else {
                                      if (values.exam_environment == ExamEnvironments(T)[1].value) {
                                        values.exam_environment = undefined;
                                        values.exam_environment_select = undefined;
                                      }
                                    }
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                              {errors && errors.campus_id && (
                                <div className="error">{T.t(errors && errors.campus_id)}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_exam_overlap_type')}</label>
                                <Select
                                  id='select_exam_overlap_type'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={ExamOverlapTypes(T)}
                                  placeholder={T.t('gen_select_exam_overlap_type')}
                                  value={
                                    values.exam_overlap_type != undefined
                                      ? ExamOverlapTypes(T).find(
                                        (option) => option.value === values.exam_overlap_type
                                      )
                                      : ExamOverlapTypes(T)[0]
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('exam_overlap_type', option.value)
                                    props.setFieldValue('exam_overlap_type_select', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_exam_overlap_type')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_invigilator_status')}</label>
                                <Select
                                  id='select_invigilator_status'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={InvigilatorStatuses(T)}
                                  placeholder={T.t('gen_select_invigilator_status')}
                                  value={
                                    values.invigilator_status != undefined
                                      ? InvigilatorStatuses(T).find(
                                        (option) => option.value === values.invigilator_status
                                      )
                                      : InvigilatorStatuses(T)[0]
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('invigilator_status', option.value)
                                    props.setFieldValue('invigilator_status_select', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_invigilator_status')}
                                />
                              </div>
                              {errors && errors.invigilator_status && props.submitCount > 0 && (
                                <div className="error">{errors && errors.invigilator_status}</div>
                              )}
                            </div>
                          </div>
                          <MultiSelectionTable
                            combined_values={combined_values_selected}
                            multiSelectionObjectFunction={this.multiSelecteds}
                          />
                          <div className="col-md-12 form-input form-group with-icon">
                            <textarea
                              name="description"
                              className="form-input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              rows={2}
                              id="description"
                              value={values.description}
                              placeholder={T.t('gen_description')}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="description" />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="text-left">
                              <h6>{T.t('gen_status')}</h6>
                              <div className="tick-radio position-relative d-inline-block">
                                <Checkbox name="status" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              id='button_cancel'
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-gray min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              id='button_save'
                              type="button"
                              data-dismiss="modal"
                              disabled={isUserEquals(this.props.user, allRestirectedRoles)}
                              onClick={() => {
                                props.handleSubmit();
                                saveButtonClicked = true;
                                scrollToTop();
                              }}
                              className="btn btn-green"
                              aria-label="alert-success"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.ICourseHoursFormProps
): Types.ICourseHoursFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.ICourseHoursFormProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.coursePage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    general_settings: store.state.general_settings,
    user: store.state.user,
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseHourForm);

export default container;
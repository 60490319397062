import { Formik, FormikActions, FormikProps } from 'formik';
import { Log, Logger } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import Paginate from '../../components/table/paginate';
import Spinner from '../../components/templates/spinner';
import SortedColumn from '../../components/table/sorted-column';
import * as Actions from '../../store/actions/general';
import Announce from '../../components/templates/announce';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import * as Constants from '../../store/constants/all';
import {
  CourseHoursSearchInitialValues,
  ExamPeriodCourseTermLectureLocations,
  ClassroomCombineTypes,
  CourseHourStatusTypes,
  EducationTypeSelectOptions,
  ExamTypes,
  ExamEnvironments,
  ExamOverlapTypes,
  InvigilatorStatuses,
} from '../../store/constants/course-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import ClassroomAddModal from './classroom-hours-add-modal';
import CombineExamsModal from './combined-exams-modal';
import CopyCourseModal from './course-copy-modal';
import BulkCombineExamsModal from './bulk-combine-exams-modal';
import CourseEditForm from './course-edit-form';
import HoursExportModal from '../planning/hours-export-modal';
import ImportModal, { ExcelImportKeys } from '../../components/excel-imports/import-modal';
import CourseHoursListPageTableHeaders from './components/course-hours-listpage-tableheaders';
import { SectionTypes } from '../../store/constants/enums';
import { UncontrolledTooltip } from 'reactstrap';
import Translator from '../../services/translate-factory';
import Switch from 'react-switch';
import { filterClassroomTypesForEventModuleStatus } from '../../util/sort';
import { ImEarth } from "react-icons/im";
import { allRestirectedRoles, isUserEquals } from '../../util/authorize';

const T = Translator.create();
const L = Log.create('CourseAddModal');

class CourseAddModal extends Component<Types.ICoursePageProps, Types.ICoursePageState> {
  state: Types.ICoursePageState = {
    filters: Object.assign({}, CourseHoursSearchInitialValues),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    selected_course_ids: [],
    selected_names: [],
    combined_list_items: [],
    selected_courses: [],
    classroomAddModalIsOpen: false,
    hasCombinedCourses: false,
    hasBulkCombinedCourses: false,
    combineExamsModalIsOpen: false,
    bulkCombineExamsModalIsOpen: false,
    courseCopyModalIsOpen: false,
    courseFormIsOpen: false,
    courseId: undefined,
    pageIsChanged: false,
    downloadHoursModalIsOpen: false
  };

  componentDidMount() {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.setState(this.state);
    this.getProgramsByFacultiesAtCourses([]);
  }

  componentDidUpdate(prevProps: Types.ICoursePageProps, prevState: Types.ICoursePageState) {
    if (prevProps.courseList !== this.props.courseList) {
      this.setState({
        pageIsChanged: false
      })
    }
    if (prevProps.courseList !== this.props.courseList && !this.state.pageIsChanged) {
      if (this.props.filters && !this.props.filters.select_all) {
        this.setState({
          selected_course_ids: [],
          selected_ids: [],
          selected_courses: [],
          selected_names: []
        })
      }
    }
    if (this.props.hoursSaved) {
      this.state.selected_ids = []
    }

    if (this.props.filters === undefined) {
      this.getCourseHours();
    }
  }

  getCourseHours() {
    const resultCallback = (response: any, status: number) => {
      if (status === 200) {

        const allCourseIds = response.all_course_ids ? response.all_course_ids as number[] : null;

        const selected_courses: ({ id: number, item: Types.IExamPeriod_CourseItem })[] | undefined = [];

        const checkedNameList = [];

        if (this.state.course_list) {
          response.combiningData.length != 0 ?
            response.combiningData.forEach((item: any) => {
              const info = item as Types.IExamPeriod_CourseItem;
              const courseId = info.course_id ? info.course_id : 0;
              if (courseId > 0 && allCourseIds && allCourseIds.includes(courseId) && info.courseInfo != undefined && this.state.selected_course_ids && this.state.selected_course_ids.includes(courseId)) {
                selected_courses.push({ id: courseId, item: info });
                checkedNameList.push(info.courseInfo.course_code + ' - ' + info.courseInfo.name);
              }
            })
            :
            this.state.course_list.forEach(a => {
              const info = a as Types.IExamPeriod_CourseItem;
              const courseId = info.course_id ? info.course_id : 0;
              if (courseId > 0 && allCourseIds && allCourseIds.includes(courseId) && info.courseInfo != undefined && this.state.selected_course_ids && this.state.selected_course_ids.includes(courseId)) {
                selected_courses.push({ id: courseId, item: info });
                checkedNameList.push(info.courseInfo.course_code + ' - ' + info.courseInfo.name);
              }
            })
        }

        if (this.state.selected_courses != undefined) {
          for (let index = 0; index < this.state.selected_courses.length; index++) {
            selected_courses.push(this.state.selected_courses[index]);
          }
        }

        if (this.state.selected_names != undefined) {
          for (let index = 0; index < this.state.selected_names.length; index++) {
            checkedNameList.push(this.state.selected_names[index]);
          }
        }

        this.setState({
          ...this.state,
          all_ids: response.all_ids,
          selected_names: checkedNameList,
          selected_courses: selected_courses
        });
      }
    };

    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;

    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS, this.state.filters, 'ep-course-list', resultCallback)
    );
  }

  getCourseSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_GET_SELECT_OPTIONS, 'course-list-spin'));
  }

  getProgramsByFacultiesAtCourses = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'course-list-spin'));
  }

  setShow = (refresh: boolean = false) => {
    if (this.props.onShowModal) {
      this.props.onShowModal(refresh);
    }
  };

  onShowModal = () => {
    this.setShow();
  };

  switchClassroomModalStatus = () => {
    let filtered_courses_by_exam_type = this.state.selected_courses && this.state.selected_courses.map((item: any) => item.item.courseInfo).filter((item: any) => ExamTypes(T)[1].value == item.exam_type);
    let filtered_courses_by_exam_session = this.state.selected_courses && this.state.selected_courses.map((item: any) => item.item.courseInfo).filter((item: any) => item.exam_session > 1);
    if (filtered_courses_by_exam_type && filtered_courses_by_exam_type.length && this.state.selected_courses && this.state.selected_courses.length > 1) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_warning'),
          body: T.t('gen_classroom_assignment_by_exam_type_info'),
          name: 'exam_classroom_assingment',
          icon: 'error_outline',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
    } else if (filtered_courses_by_exam_session && filtered_courses_by_exam_session.length && this.state.selected_courses && this.state.selected_courses.length > 1) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_warning'),
          body: T.t('gen_classroom_assignment_by_exam_session_info'),
          name: 'exam_classroom_assingment',
          icon: 'error_outline',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
    } else {
      this.setState({
        ...this.state,
        classroomAddModalIsOpen: !this.state.classroomAddModalIsOpen
      });
    }
  };

  switchCombineExamsModalStatus = () => {
    if (this.state.combineExamsModalIsOpen == true) {
      this.unCheckAllSelecteds();
    }
    this.setState({
      ...this.state,
      combineExamsModalIsOpen: !this.state.combineExamsModalIsOpen
    });
  };

  switchCopyExamModalStatus = () => {
    if (this.state.courseCopyModalIsOpen == true) {
      this.unCheckAllSelecteds();
    }
    this.setState({
      ...this.state,
      courseCopyModalIsOpen: !this.state.courseCopyModalIsOpen
    });
  };

  switchBulkCombineExamsModalStatus = () => {
    if (this.state.bulkCombineExamsModalIsOpen == true) {
      this.unCheckAllSelecteds();
    }
    this.setState({
      ...this.state,
      bulkCombineExamsModalIsOpen: !this.state.bulkCombineExamsModalIsOpen
    });
  };

  unCheckAllSelecteds = () => {
    this.setState({
      ...this.state,
      selected_ids: [],
      selected_names: [],
      selected_courses: []
    });
  };

  showCourseProgram = (e?: any, id?: number) => {
    if (this.props.onSelect) {
      this.props.onSelect(e, id);
      this.state.selectedCourseId = id;
      this.setState(this.state);
    }
  };

  onShowCourseProgram = (e?: any) => {
    let numID: number = -1;
    const id: string = e.currentTarget.dataset.id || '';
    numID = parseInt(id, 10);
    this.showCourseProgram(e, numID);
    this.onSelectCourse(e);
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      const resultCallback = (response: any, status: number) => {
        if (status === 200) {

          const allCourseIds = response.all_course_ids ? response.all_course_ids as number[] : null;

          const selected_courses: ({ id: number, item: Types.IExamPeriod_CourseItem })[] | undefined = [];

          if (this.state.course_list) {
            response.combiningData.length != 0 ?
              response.combiningData.forEach((item: any) => {
                const info = item as Types.IExamPeriod_CourseItem;
                const courseId = info.course_id ? info.course_id : 0;
                if (courseId > 0 && allCourseIds && allCourseIds.includes(courseId)) {
                  selected_courses.push({ id: courseId, item: info });
                }
              })
              :
              this.state.course_list.forEach(a => {
                const info = a as Types.IExamPeriod_CourseItem;
                const courseId = info.course_id ? info.course_id : 0;
                if (courseId > 0 && allCourseIds && allCourseIds.includes(courseId)) {
                  selected_courses.push({ id: courseId, item: info });
                }
              })
          }

          let checkedNameList = selected_courses && selected_courses.map((x: any) => x.item.courseInfo ? x.item.courseInfo.course_code + ' - ' + x.item.courseInfo.name : "");

          this.setState({
            ...this.state,
            all_ids: response.all_ids,
            selected_ids: response.all_ids,
            selected_course_ids: response.all_course_ids,
            selected_courses: selected_courses,
            selected_names: checkedNameList
          });
        }
      };

      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS,
            this.state.filters,
            'ep-course-list',
            resultCallback
          )
        );
      } else {
        this.setState({
          ...this.state,
          selected_ids: [],
          selected_course_ids: [],
          selected_courses: [],
          selected_names: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkIfAllCoursesSelected = () => {
    const resultCallback = (response: any, status: number) => {
      if (status === 200) {

        const all_ids = response.all_course_ids ? response.all_course_ids : [];
        const selected_course_ids = this.state.selected_course_ids ? this.state.selected_course_ids : [];
        let result: boolean = false;
        if (all_ids.length && selected_course_ids.length) {
          result = all_ids.every((item: number) => selected_course_ids.indexOf(item) !== -1);
        }

        if (result) {
          this.state.all_ids = response.all_course_ids;
          this.state.filters.select_all = true;
          this.setState(this.state);
        }
      }
    };
    let filter = this.state.filters;
    filter.select_all = true;
    this.props.dispatch(
      Actions.ApiRequest(
        Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS,
        filter,
        'course-hours-spinner',
        resultCallback
      )
    );
  };

  onSelectCourseCalledFromCoursesTab = (e: any) => {
    if (e && e.currentTarget) {
      if (this.props.onCheck) {
        this.props.onCheck(e);
      }
      let checkedIDList: Array<number> = Object.assign([], this.state.selected_ids);
      let selected_course_ids: Array<number> = Object.assign([], this.state.selected_course_ids);

      let checkedNameList = Object.assign([], this.state.selected_names);
      let checkedCourses = Object.assign([], this.state.selected_courses);
      let stringID: any = e.currentTarget.dataset.id || -1;
      let courseName: string = e.currentTarget.dataset.name || '';
      let string_courseItem: string = e.currentTarget.dataset.item || '';
      let courseItem: Types.IExamPeriod_CourseItem = JSON.parse(string_courseItem);
      let courseID = parseInt(stringID, 10);

      if (selected_course_ids.findIndex((_id: number) => _id === courseItem.course_id) === -1) {
        selected_course_ids.push(courseItem.course_id ? courseItem.course_id : -1)
      } else {
        selected_course_ids = selected_course_ids.filter((_id: number) => _id != courseItem.course_id)
      }
      if (checkedIDList.findIndex((_id: number) => _id === courseID) === -1) {
        checkedIDList.push(courseID);
        checkedNameList.push(courseName);
        checkedCourses.push({ id: courseID, item: courseItem });
      } else {
        let index = checkedIDList.indexOf(courseID);
        let nameIndex = checkedNameList.indexOf(courseName);
        let courseItem: any = {};
        courseItem = checkedCourses.find(
          (item: { id: number; item: Types.IExamPeriod_CourseItem }) => item.id === courseID
        );
        let courseItemIndex = courseItem ? courseItem.id : -1;
        if (index !== -1) {
          checkedIDList.splice(index, 1);
        }
        if (nameIndex !== -1) {
          checkedNameList.splice(nameIndex, 1);
        }
        if (index !== -1) {
          checkedCourses.splice(index, 1);
        }
        if (courseItemIndex !== -1) {
          checkedCourses = checkedCourses.filter((item: any) => item.id != courseID);
        }
        this.setState({
          selected_ids: checkedIDList,
          selected_names: checkedNameList,
          selected_course: courseItem,
          selected_courses: checkedCourses,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        selected_ids: checkedIDList,
        selected_course_ids: selected_course_ids,
        selected_names: checkedNameList,
        selected_course: courseItem,
        selected_courses: checkedCourses
      });
      if (this.state.course_list && this.state.course_list.length > 0 && this.state.course_list.length == selected_course_ids.length) {
        this.checkIfAllCoursesSelected();
      }
    }
  };

  onSelectCourse = (e: any) => {
    if (e && e.currentTarget) {
      if (this.props.onCheck) {
        this.props.onCheck(e);
      }
      let checkedIDList: Array<number> = Object.assign([], this.state.selected_ids);
      let selected_course_ids: Array<number> = Object.assign([], this.state.selected_course_ids);

      let checkedNameList = Object.assign([], this.state.selected_names);
      let checkedCourses = Object.assign([], this.state.selected_courses);
      let stringID: any = e.currentTarget.dataset.id || -1;
      let courseName: string = e.currentTarget.dataset.name || '';
      let string_courseItem: string = e.currentTarget.dataset.item || '';
      let courseItem: Types.IExamPeriod_CourseItem = JSON.parse(string_courseItem);
      let courseID = parseInt(stringID, 10);

      if (selected_course_ids.findIndex((_id: number) => _id === courseItem.course_id) === -1) {
        selected_course_ids.push(courseItem.course_id ? courseItem.course_id : -1)
      } else {
        selected_course_ids = selected_course_ids.filter((_id: number) => _id != courseItem.course_id)
      }
      if (checkedIDList.findIndex((_id: number) => _id === courseID) === -1) {
        checkedIDList.push(courseID);
        checkedNameList.push(courseName);
        checkedCourses.push({ id: courseID, item: courseItem });
      } else {
        let index = checkedIDList.indexOf(courseID);
        let nameIndex = checkedNameList.indexOf(courseName);
        let courseItem: any = {};
        courseItem = checkedCourses.find(
          (item: { id: number; item: Types.IExamPeriod_CourseItem }) => item.id === courseID
        );
        let courseItemIndex = courseItem ? courseItem.id : -1;
        if (index !== -1) {
          checkedIDList.splice(index, 1);
        }
        if (nameIndex !== -1) {
          checkedNameList.splice(nameIndex, 1);
        }
        if (courseItemIndex !== -1) {
          checkedCourses = checkedCourses.filter((item: any) => item.id != courseID);
        }
        this.setState({
          //...this.state,
          selected_ids: checkedIDList,
          selected_names: checkedNameList,
          selected_course: courseItem,
          selected_courses: checkedCourses,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        //...this.state,
        selected_ids: checkedIDList,
        selected_course_ids: selected_course_ids,
        selected_names: checkedNameList,
        selected_course: courseItem,
        selected_courses: checkedCourses
      });
    }
    this.setState({
      ...this.state,
      selected_course_ids: this.state.selected_course_ids
    });
    if (this.props.updateCourseHourIdList) {
      this.props.updateCourseHourIdList(this.state.selected_ids);
    }
    if (this.props.updateCheckedList) {
      this.props.updateCheckedList(this.state.selected_course_ids);
    }
    if (this.state.course_list && this.state.selected_course_ids && this.state.course_list.length > 0 && this.state.course_list.length == this.state.selected_course_ids.length) {
      this.checkIfAllCoursesSelected();
    }
  };

  onSelectCourseCheckbox = async (e: any) => {
    if (e && e.currentTarget) {
      //this.state.selected_ids = this.state.selected_courses && this.state.selected_courses.map(i => i.id)
      let checkedIDList: Array<number> = Object.assign([], this.state.selected_ids);
      let selected_course_ids: Array<number> = Object.assign([], this.state.selected_course_ids);

      let checkedNameList = Object.assign([], this.state.selected_names);
      let checkedCourses = Object.assign([], this.state.selected_courses);
      let stringID: any = e.currentTarget.dataset.id || -1;
      let courseName: string = e.currentTarget.dataset.name || '';
      let string_courseItem: string = e.currentTarget.dataset.item || '';
      let courseItem: Types.IExamPeriod_CourseItem = JSON.parse(string_courseItem);
      let courseID = parseInt(stringID, 10);

      if (selected_course_ids.findIndex((_id: number) => _id === courseItem.course_id) === -1) {
        selected_course_ids.push(courseItem.course_id ? courseItem.course_id : -1)
      } else {
        selected_course_ids = selected_course_ids.filter((_id: number) => _id != courseItem.course_id)
      }
      if (checkedIDList.findIndex((_id: number) => _id === courseID) === -1) {
        checkedIDList.push(courseID);
        checkedNameList.push(courseName);
        checkedCourses.push({ id: courseID, item: courseItem });
      } else {
        let index = checkedIDList.indexOf(courseID);
        let nameIndex = checkedNameList.indexOf(courseName);
        let courseItem: any = {};
        courseItem = checkedCourses.find(
          (item: { id: number; item: Types.IExamPeriod_CourseItem }) => item.id === courseID
        );
        let courseItemIndex = courseItem ? courseItem.id : -1;
        if (index !== -1) {
          checkedIDList.splice(index, 1);
        }
        if (nameIndex !== -1) {
          checkedNameList.splice(nameIndex, 1);
        }
        if (courseItemIndex !== -1) {
          checkedCourses = checkedCourses.filter((item: any) => item.id != courseID);
        }

        this.setState({
          selected_ids: checkedIDList,
          selected_names: checkedNameList,
          selected_course: courseItem,
          selected_courses: checkedCourses,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      await this.setState({
        selected_ids: checkedIDList,
        selected_course_ids: selected_course_ids,
        selected_names: checkedNameList,
        selected_course: courseItem,
        selected_courses: checkedCourses
      });
    }
    if (this.props.updateCourseHourIdList) {
      this.props.updateCourseHourIdList(this.state.selected_ids);
    }
    if (this.props.updateCheckedList) {
      this.props.updateCheckedList(this.state.selected_course_ids);
    }
    if (this.props.saveAllCheckboxes) {
      this.props.saveAllCheckboxes(this.state.selected_ids);
    }
    if (this.state.course_list && this.state.selected_course_ids && this.state.course_list.length > 0 && this.state.course_list.length == this.state.selected_course_ids.length) {
      this.checkIfAllCoursesSelected();
    }
  };

  saveCombinedCourses = (
    combinedCourses?: Array<Types.IExamPeriod_CourseItem>,
    courseObject?: Types.IExamPeriod_CourseItem
  ) => {
    let courseArray: any = [];
    if (combinedCourses) {
      combinedCourses.map((item: Types.IExamPeriod_CourseItem) => {
        courseArray.push({ id: item.course_id, item: item });
      });
    }
    this.setState({
      ...this.state,
      hasCombinedCourses: true,
      combined_courses: courseArray,
      courseObject: courseObject,
      combineExamsModalIsOpen: !this.state.combineExamsModalIsOpen
    });
  };

  cleanCombinedCourse() {
    this.setState((prev) => ({
      ...prev,
      hasCombinedCourses: true,
      combined_courses: [],
      courseObject: undefined,
      combineExamsModalIsOpen: false
    }));
  }

  deleteCourses = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_courses_remove_planning'),
          content: (
            <div ><br />
              <ul style={{ listStyleType: 'none' }} className="table-scrollable-td text-center">
                {this.state.filters && this.state.filters.select_all ? T.t('gen_all_courses_selected') :
                  this.state.selected_ids && this.state.selected_ids.length > 10 ? T.t('gen_more_than_x_courses_selected').replace("{0}", 10) :
                    this.state.selected_names && this.state.selected_names.map((courseName: string) => <li>{courseName ? courseName.split(';').join(', ') : ""}</li>)}
              </ul>
              <p>{T.t('gen_remove_courses_from_exam_schedule_question')}</p>
            </div>
          ),
          name: 'examPeriod_courses_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                if (this.props.clearCheckedItems) {
                  this.props.clearCheckedItems();
                }
                this.state.selected_ids = [];
                this.state.selected_courses = [];
                this.state.selected_names = [];
                this.state.selected_course_ids = [];
                this.state.filters.course_ids = [];
                this.state.filters.delete_list = [];
                this.getCourseHours();
              }
            };
            /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
            let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
            var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
            let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
            this.state.filters.term_id = id;
            this.state.filters.delete_list = this.state.selected_ids;
            this.state.filters.course_ids = this.state.selected_course_ids

            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_DELETE_COURSES_FROM_PERIOD,
                this.state.filters,
                'ep-course-list',
                resultCallback
              )
            );
            this.state.selected_ids = [];
            this.state.selected_courses = [];
            this.state.selected_names = [];
            this.state.selected_course_ids = [];
          }
        })
      );
    }
  };

  onInputChange = (inputValue: string, action: any) => {
    if (action.action === "input-change") {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes_select.filter((item: any) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    if (action.action === "set-value" && this.state.course_codes_filtered == undefined) {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes_select;
    }

    if (action.action === "menu-close" && this.state.course_codes_filtered != undefined) {
      this.state.course_codes_filtered = undefined;
    }
  };

  filterOption = (option: Types.ISelectOption, inputValue: string) => {
    if (option.value === -1) {
      return true;
    } else if (inputValue) {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    } else {
      return true;
    }
  };

  sort = (sortkey: string, order_by: string) => {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getCourseHours();
  };

  onPageChange = (page: number, size?: number) => {
    this.setState(prev => ({
      ...prev,
      pageIsChanged: true,
      filters: {
        ...prev.filters,
        page: page,
        size: size ? size : 10
      }
    }), this.getCourseHours)
  };

  onSizeChange = (size: number) => {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        size: size
      }
    }), this.getCourseHours)
  }

  onFormReset = () => {
    this.state.filters = Object.assign({}, CourseHoursSearchInitialValues);
    GT.GetTermIdFromURL(window, this.state.filters.term_id);
    this.setState(this.state);
    this.getCourseHours();
  };

  onFilterCourse(model: Types.IFilterCourse, FormActions: FormikActions<Types.IFilterCourse>) {
    if (model.course_codes_ids && model.course_codes_ids.length == 0)
      model.course_code_inverse = false;

    GT.GetTermIdFromURL(window, this.state.filters.term_id);
    this.setState(prev => ({
      ...prev,
      selected_ids: [],
      selected_names: [],
      selected_courses: [],
      selected_course_ids: [],
      all_ids: [],
      filters: {
        ...model,
        page: 1,
      },
      filterIsOpen: true,
      pageIsChanged: true
    }));
    this.getCourseHours();
    FormActions.setSubmitting(false);
  }

  switchCombiningModal = () => {
    this.setState({
      ...this.state,
      hasCombinedCourses: false,
      combineExamsModalIsOpen: !this.state.combineExamsModalIsOpen
    });

  };

  switchBulkCombineModal = () => {
    this.setState({
      ...this.state,
      hasBulkCombinedCourses: false,
      bulkCombineExamsModalIsOpen: !this.state.bulkCombineExamsModalIsOpen
    });

  };

  onHoursImported = () => {
    this.getCourseHours();
  };

  onDownloadHours = () => {
    this.setState({
      ...this.state,
      downloadHoursModalIsOpen: !this.state.downloadHoursModalIsOpen
    });
  }

  switchDownloadHoursModalStatus = () => {
    this.setState({
      ...this.state,
      downloadHoursModalIsOpen: !this.state.downloadHoursModalIsOpen
    });
  };

  static getDerivedStateFromProps(props: Types.ICoursePageProps, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;
    if (props.courseList) {
      hasNewState = true;
      state.course_list = props.courseList;
    }

    if (props.compactTable && props.selectedCourseId && !!!state.selectedCourseId) {
      hasNewState = true;
      state.selectedCourseId = props.selectedCourseId;
    }

    if (props.all_ids && props.all_ids.length) {
      hasNewState = true;
      state.all_ids = props.all_ids;
    }

    if (
      props.compactTable &&
      props.checkedCourseIds &&
      props.checkedCourseIds.length > 0 &&
      state.selected_ids &&
      state.selected_ids.length == 0
    ) {
      hasNewState = true;
      //state.selected_ids = props.checkedCourseIds;
    }

    if (
      props.compactTable &&
      props.checkedCourseIds &&
      props.checkedCourseIds.length == 0 &&
      state.selected_ids &&
      state.selected_ids.length > 0
    ) {
      hasNewState = true;
      //state.selected_ids = [];
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    const courseCodeOptions = this.props.selectOptions && this.props.selectOptions.course_codes_select ? this.props.selectOptions.course_codes_select : []
    const courseCodeSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...courseCodeOptions];

    const programOptions = this.props && this.props.programs_related_faculty ? this.props.programs_related_faculty : []
    const programSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...programOptions];

    if (this.props.getCurrentPage) {
      this.props.getCurrentPage(this.state)
    }

    let courseList = this.state.course_list;
    if (this.props.compactTable) {
      if (this.state.filters.select_all) {
        //this.state.selected_ids = this.state.all_ids
      }
      if (this.props.hoursSaved) {
        this.state.selected_ids = []
      }
    }
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const CampusSelectOptions = [...ExamPeriodCourseTermLectureLocations(T), ...campusOptions];
    // TODO: dersler için bu gerekli değilse kaldırılmalı
    let placeHolder = (
      <div className="text-center">
        <p>{T.t('gen_classrooms_info_placeholder')}</p>
        {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e' || this.props.user.role == 'p') ? (
          <button id='playlist_add' className="category-tag-square tag-green" onClick={this.onShowModal}>
            <i className="material-icons mr-2">playlist_add</i>
            <span>{T.t('gen_add_course')}</span>
          </button>
        ) : null}
      </div>
    );

    let Table = (
      <div className="row">
        <div className="col-12">
          <div className="quick-actions">
            <span
              id='filter'
              className="category-tag-square tag-glass float-right"
              onClick={() => {
                this.state.filterIsOpen = !this.state.filterIsOpen;
                this.setState(this.state);
              }}
            >
              <i data-toggle="tooltip" className="material-icons" data-original-title="Listeye Ekle">
                filter_list
              </i>
              {T.t('gen_filter')}
            </span>
            {this.state.selected_course_ids && this.state.selected_course_ids.length ? (
              <React.Fragment>
                {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e' || this.props.user.role == 'p') ? (
                  this.state.selected_course_ids && this.state.selected_course_ids.length !== 0 ? (
                    <button
                      id='button_cancel_selection'
                      className="category-tag-square tag-gray float-left pr-2 pl-2"
                      style={{ margin: '2px' }}
                      onClick={() => {
                        if (this.props.clearCheckedItems) {
                          this.props.clearCheckedItems();
                        }
                        this.setState({
                          ...this.state,
                          selected_ids: [],
                          selected_names: [],
                          selected_courses: [],
                          selected_course_ids: [],
                          all_ids: []
                        });
                        this.state.filters.select_all = false;
                      }}
                    >
                      <i className="material-icons mr-2">close</i>
                      <span>{this.props.compactTable ? T.t('gen_cancel') : T.t('gen_cancel_selection')}</span>
                      <b>
                        &nbsp;(
                        {this.state.selected_course_ids && this.state.selected_course_ids.length})
                      </b>
                    </button>
                  ) : (
                    <button
                      id='button_playlist_add'
                      className="category-tag-square tag-green float-left pr-2 pl-2"
                      onClick={() => {
                        if (this.props.compactTable && this.props.toggleAddModal) {
                          this.props.toggleAddModal();
                        } else {
                          this.onShowModal();
                        }
                      }}
                    >
                      <i className="material-icons mr-2">playlist_add</i>
                      <span>{T.t('gen_add_course')}</span>
                    </button>
                  )
                ) : null}
                {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e' || this.props.user.role == 'p') ? (
                  <button
                    id='button_delete'
                    className="category-tag-square float-left pr-2 pl-2"
                    style={{ margin: '2px', color: '#fff', backgroundColor: '#dc3545' }}
                    onClick={this.deleteCourses}
                    disabled={isUserEquals(this.props.user, allRestirectedRoles)}
                  >
                    <i className="material-icons mr-2" style={{ color: 'white' }}>
                      delete_outline
                    </i>
                    <span>{this.props.compactTable ? T.t('gen_delete') : T.t('gen_delete_selected')}</span>
                  </button>
                ) : null}
                {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e' || this.props.user.role == 'p') ? (
                  <button
                    id='button_assign_classroom'
                    className="category-tag-square tag-green float-left pr-2 pl-2"
                    style={{ margin: '2px' }}
                    onClick={this.switchClassroomModalStatus}
                  >
                    <i className="material-icons mr-2">input</i>
                    <span>{T.t('gen_assign_classroom')}</span>
                  </button>
                ) : null}
                {!this.props.compactTable &&
                  this.state.selected_ids &&
                  this.state.selected_ids.length &&
                  this.state.selected_ids.length < 2 ? (
                  <button
                    id='button_content_copy'
                    className="category-tag-square float-left pr-2 pl-2"
                    style={{ margin: '2px', color: '#fff', background: 'indigo' }}
                    onClick={() =>
                      this.setState({
                        courseCopyModalIsOpen: !this.state.courseCopyModalIsOpen
                      })
                    }
                    disabled={isUserEquals(this.props.user, allRestirectedRoles)}
                  >
                    <i className="material-icons mr-2" style={{ color: 'white' }}>
                      content_copy
                    </i>
                    <span>{T.t('gen_copy_exam')}</span>,
                  </button>
                ) : null}
                {!this.props.compactTable && this.state.selected_ids && this.state.selected_ids.length > 1 ? (
                  <button
                    id='button_call_merge'
                    className="category-tag-square tag-blue float-left pr-2 pl-2"
                    style={{ margin: '2px' }}
                    onClick={this.switchCombiningModal}
                    disabled={isUserEquals(this.props.user, allRestirectedRoles)}
                  >
                    <i className="material-icons mr-2" style={{ color: 'white' }}>
                      call_merge
                    </i>
                    <span>{T.t('gen_combine_exams')}</span>
                  </button>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e' || this.props.user.role == 'p') ? (
                  <button
                    id='button_add_course'
                    className="category-tag-square tag-green float-left pr-2 pl-2"
                    onClick={() => {
                      if (this.props.compactTable && this.props.toggleAddModal) {
                        this.props.toggleAddModal();
                      } else {
                        this.onShowModal();
                      }
                    }}
                  >
                    <i className="material-icons mr-2">playlist_add</i>
                    <span>{T.t('gen_add_course')}</span>
                  </button>
                ) : null}
                {!this.props.compactTable && this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e' || this.props.user.role == 'p') ? (
                  <button
                    id='button_bulk_combine_exams'
                    className="category-tag-square float-left pr-2 pl-2 ml-2 mr-1"
                    style={{ color: '#fff', background: 'indigo' }}
                    onClick={this.switchBulkCombineModal}
                    disabled={isUserEquals(this.props.user, allRestirectedRoles)}
                  >
                    <i className="material-icons mr-2" style={{ color: 'white' }}>
                      merge
                    </i>
                    <span>{T.t('gen_bulk_combine_exams')}</span>
                  </button>
                ) : null}
                {this.props.compactTable && this.props.user && (this.props.user.role === 's') ? (
                  <ImportModal
                    title={T.t('gen_add_with_excel')}
                    componentKey={ExcelImportKeys.PlanningCourseHours}
                    dispatch={this.props.dispatch}
                    onImport={this.onHoursImported}
                  />
                ) : null}
                {this.props.compactTable && this.props.user && this.props.user.role == 's' ? (
                  <button className="category-tag-square tag-blue ml-1 pl-2" onClick={this.onDownloadHours}>
                    <i className="material-icons mr-2" style={{ color: 'white' }}>cloud_download</i>
                    <span>{T.t('gen_download')}</span>
                  </button>
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>
        <div
          className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
          id="advance-search"
        >
          <div className="advance-search d-block mt-3">
            <Formik
              initialValues={CourseHoursSearchInitialValues}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                this.onFilterCourse(values, actions);
              }}
              onReset={this.onFormReset}
            >
              {(props: FormikProps<Types.IFilterCourse>) => {
                const { values, handleChange, handleBlur, handleSubmit } = props;

                return (
                  <form onSubmit={props.handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <h6>{T.t('gen_filter_parameters')}</h6>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_course_no')}</label>
                            <Select
                              id='select_course'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.course_ids_select
                                  ? this.props.selectOptions.course_ids_select
                                  : []
                              }
                              placeholder={T.t('gen_select_course_no')}
                              value={props.values.course_ids_select}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_ids_select', list);
                                props.setFieldValue(
                                  'course_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_course_no')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label id="filter_course_code_inverse" className="tick-radio position-relative">
                              {T.t('gen_course_code')}
                              <Switch
                                id="course_code_inverse"
                                className="react-switch"
                                onChange={(checked: boolean) => {
                                  this.state.filters!.course_code_inverse = checked;
                                  this.setState(this.state);
                                }}
                                checked={this.state.filters!.course_code_inverse}
                              />
                            </label>
                            <UncontrolledTooltip placement="top" target="filter_course_code_inverse">
                              {T.t('gen_filter_inverse_selected_course_code')}
                            </UncontrolledTooltip>
                            <Select
                              id='select_course_code'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={courseCodeSelectOptions}
                              placeholder={T.t('gen_select_course_code')}
                              value={props.values.course_codes_select}
                              filterOption={this.filterOption}
                              onInputChange={this.onInputChange}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_codes_select', list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered! : list);
                                props.setFieldValue(
                                  'course_codes_ids',
                                  list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered!.map((item: any) => item.value) : list.map(item => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_course_code')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-input form-group date-picker mt-3">
                          <input
                            id="conjugate_course_code"
                            name="conjugate_course_code"
                            value={props.values.conjugate_course_code}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="conjugate_course_code">{T.t('gen_conjugate_course_code')}</label>
                          <i className="material-icons">filter_1</i>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-input form-group date-picker mt-3">
                          <input
                            id="name"
                            name="name"
                            value={props.values.name}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="name">{T.t('gen_name')}</label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                      <div className="col-md-4 form-input form-group with-icon mt-3">
                        <input
                          id="exam_session"
                          name="exam_session"
                          value={props.values.exam_session}
                          onChange={props.handleChange}
                          type="number"
                          required
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor="exam_session">{T.t('gen_session_count')}</label>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_campus')}</label>
                            <Select
                              id='select_location'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={CampusSelectOptions}
                              placeholder={T.t('gen_select_location')}
                              value={props.values.campuses ? props.values.campuses : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('campuses', list);
                                props.setFieldValue(
                                  'campus_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_education_type')}</label>
                            <Select
                              id='select_education_type'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={EducationTypeSelectOptions(T)}
                              placeholder={T.t('gen_select_education_type')}
                              value={props.values.education_types_select}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('education_types_select', list);
                                props.setFieldValue(
                                  'education_types',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_education_type')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_exam_duration')} ({T.t('gen_slot_count')})</label>
                            <Select
                              id='select_exam_duration'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.exam_durations_select
                                  ? this.props.selectOptions.exam_durations_select
                                  : []
                              }
                              placeholder={T.t('gen_exam_duration')}
                              value={props.values.exam_durations_select ? props.values.exam_durations_select : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('exam_durations_select', list);
                                props.setFieldValue(
                                  'exam_durations',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_exam_duration')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_faculty_college')}</label>
                            <Select
                              id='select_faculty'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.faculties
                                  ? this.props.selectOptions.faculties
                                  : []
                              }
                              placeholder={T.t('gen_select_faculty')}
                              value={props.values.faculties ? props.values.faculties : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('faculties', list);
                                props.setFieldValue(
                                  'faculty_ids',
                                  list.map((item) => item.value)
                                );
                                props.setFieldValue('programs', []);
                                props.setFieldValue('program_ids', []);
                                this.getProgramsByFacultiesAtCourses(list.map(item => item.value))
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_connected_programs')}</label>
                            <Select
                              id='select_program'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={programSelectOptions}
                              placeholder={T.t('gen_select_program')}
                              value={props.values.programs}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('programs',
                                  list.map(item => item.value).includes(-1) ? this.props.programs_related_faculty : list);
                                props.setFieldValue(
                                  'program_ids',
                                  list.map(item => item.value).includes(-1) ? programOptions.map((item: any) => item.value) : list.map(item => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_program')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_grade')}</label>
                            <Select
                              id='select_grade'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.class
                                  ? this.props.selectOptions.class
                                  : []
                              }
                              placeholder={T.t('gen_select_grade')}
                              value={props.values.class ? props.values.class : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('class', list);
                                props.setFieldValue(
                                  'class_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_section')}</label>
                            <Select
                              id='select_section'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.sections_select
                                  ? this.props.selectOptions.sections_select
                                  : []
                              }
                              placeholder={T.t('gen_select_section')}
                              value={props.values.sections_select ? props.values.sections_select : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('sections_select', list);
                                props.setFieldValue(
                                  'sections',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_section')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_classroom_combine_status')}</label>
                            <Select
                              id='select_classroom_combine_status'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={ClassroomCombineTypes(T)}
                              placeholder={T.t('gen_classroom_combine_status')}
                              value={props.values.classroom_combine_status ? props.values.classroom_combine_status : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('classroom_combine_status', list);
                                props.setFieldValue(
                                  'classroom_combine_status_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_exam_type')}</label>
                            <Select
                              id='select_exam_type'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={ExamTypes(T)}
                              placeholder={T.t('gen_select_exam_type')}
                              value={props.values.exam_type ? props.values.exam_type : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('exam_type', list);
                                props.setFieldValue(
                                  'exam_type_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_exam_type')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_exam_environment')}</label>
                            <Select
                              id='select_exam_environmet'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={ExamEnvironments(T)}
                              placeholder={T.t('gen_select_exam_environment')}
                              value={props.values.exam_environment ? props.values.exam_environment : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('exam_environment', list);
                                props.setFieldValue(
                                  'exam_environment_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_exam_environment')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_exam_overlap_type')}</label>
                            <Select
                              id='select_exam_overlap_type'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={ExamOverlapTypes(T)}
                              placeholder={T.t('gen_select_exam_overlap_type')}
                              value={props.values.exam_overlap_type ? props.values.exam_overlap_type : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('exam_overlap_type', list);
                                props.setFieldValue(
                                  'exam_overlap_type_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_exam_overlap_type')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_instructors')}</label>
                            <Select
                              id='select_instructor'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.instructors
                                  ? this.props.selectOptions.instructors
                                  : []
                              }
                              placeholder={T.t('gen_select_instructor')}
                              value={props.values.instructors}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('instructors', list);
                                props.setFieldValue(
                                  'instructor_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_assistants')}</label>
                            <Select
                              id='select_assistant'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.instructors
                                  ? this.props.selectOptions.instructors
                                  : []
                              }
                              placeholder={T.t('gen_select_assistant')}
                              value={props.values.assistant_staff}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('assistant_staff', list);
                                props.setFieldValue(
                                  'assistant_staff_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_assistan')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_course_language')}</label>
                            <Select
                              id='select_course_language'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={true}
                              options={
                                this.props.selectOptions && this.props.selectOptions.course_languages_active
                                  ? this.props.selectOptions.course_languages_active
                                  : []
                              }
                              placeholder={T.t('gen_select_course_language')}
                              value={props.values.course_languages ? props.values.course_languages : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_languages', list);
                                props.setFieldValue(
                                  'course_language_ids',
                                  list.map((item) => item.value.toString())
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_course_language')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_classroom_type')}</label>
                            <Select
                              id='select_type'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.classroom_types
                                  ? this.props.selectOptions.classroom_types.filter(filterClassroomTypesForEventModuleStatus(this.props.general_settings))
                                  : []
                              }
                              placeholder={T.t('gen_select_type')}
                              value={props.values.classroom_types ? props.values.classroom_types : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('classroom_types', list);
                                props.setFieldValue(
                                  'classroom_type_ids',
                                  list.map((item) => item.value.toString())
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_type')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_invigilator_status')}</label>
                            <Select
                              id='select_invigilator_status'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={InvigilatorStatuses(T)}
                              placeholder={T.t('gen_select_invigilator_status')}
                              value={props.values.invigilator_status ? props.values.invigilator_status : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('invigilator_status', list);
                                props.setFieldValue(
                                  'invigilator_status_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_invigilator_status')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_status_hour_assignment')}</label>
                            <Select
                              id='select_course_hour'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={CourseHourStatusTypes(T)}
                              placeholder={T.t('gen_status_hour_assignment')}
                              value={props.values.course_hour_status ? props.values.course_hour_status : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_hour_status', list);
                                props.setFieldValue(
                                  'course_hour_status_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_status_classroom_assignment')}</label>
                            <Select
                              id='select_course_hour_classroom'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={CourseHourStatusTypes(T)}
                              placeholder={T.t('gen_status_classroom_assignment')}
                              value={props.values.course_hour_classroom_status ? props.values.course_hour_classroom_status : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_hour_classroom_status', list);
                                props.setFieldValue(
                                  'course_hour_classroom_status_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="description"
                            name="description"
                            value={props.values.description}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="description">{T.t('gen_description')}</label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row mt-3">
                      <div className="col-6">
                        <button
                          id='button_arrow_upward'
                          type="button"
                          onClick={() => {
                            this.state.filterIsOpen = false;
                            this.setState(this.state);
                          }}
                          className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                        >
                          <i className="material-icons">arrow_upward</i>
                        </button>
                        <button
                          id='button_delete_sweep'
                          type="reset"
                          onClick={props.handleReset}
                          className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                        >
                          <i className="material-icons">delete_sweep</i>
                        </button>
                      </div>
                      <div className="col-6 text-right">
                        <button
                          id='button_search'
                          type="button"
                          className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                          onClick={() => props.handleSubmit()}
                          disabled={props.isSubmitting}
                        >
                          <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="col-12">
          <table
            className={this.props.compactTable ? "aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table table-course-hours-classrooms" : "aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table"}
            style={this.props.compactTable ? { cursor: 'pointer' } : {}}
          >
            <CourseHoursListPageTableHeaders
              compactTable={this.props.compactTable}
              fnSelectAll={this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e' || this.props.user.role == 'p') ? (this.onSelectAll) : null}
              fnColumnSorter={this.sort}
              selected_ids={this.state.selected_ids}
              selected_course_ids={this.state.selected_course_ids}
              all_ids={this.state.all_ids}
              filters={this.state.filters}
              courseList={this.props.courseList}
            />

            <tbody>
              {courseList && courseList.length ? (
                courseList.map((item: Types.IExamPeriod_CourseItem, index: any) => {
                  let ClassroomCombineStatusObject: any = {};
                  if (item.courseInfo && item.courseInfo.term) {
                    let term = item.courseInfo.term;
                  }

                  let combineStatus = item.courseInfo && item.courseInfo.classroom_combine_status;
                  ClassroomCombineStatusObject = ClassroomCombineTypes(T).find(
                    (object) => object.value == combineStatus
                  );

                  let examType = item.courseInfo && item.courseInfo.exam_type;
                  let ExamTypesObject = ExamTypes(T).find(
                    (object) => object.value == examType
                  );

                  let examEnvironment = item.courseInfo && item.courseInfo.exam_environment;
                  let ExamEnvironmentsObject = ExamEnvironments(T).find(
                    (object) => object.value == examEnvironment
                  );

                  let examOverlapType = item.courseInfo && item.courseInfo.exam_overlap_type;
                  let ExamOverlapTypesObject = ExamOverlapTypes(T).find(
                    (object) => object.value == examOverlapType
                  );

                  let combinedCourses = item.courseInfo && item.courseInfo.combined_courses;

                  let hasCombinedCourses =
                    item.courseInfo && item.courseInfo.combined_courses && item.courseInfo.combined_courses.length > 0;

                  if (hasCombinedCourses) {
                    this.state.combined_list_items && this.state.combined_list_items.push(item.course_id);
                  }

                  let tableContent = null;

                  if (this.props.compactTable) {
                    tableContent = (
                      <tr className="table-course-hours-classrooms-tr" key={index}>
                        <td data-cell="select">
                          {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e' || this.props.user.role == 'p') ? (
                            <div className="tick-radio position-relative">
                              <input
                                id='course_info'
                                type="checkbox"
                                className="form-radio"
                                onChange={this.onSelectCourseCheckbox}
                                checked={
                                  this.state.selected_ids && this.state.selected_ids.indexOf(item.course_hour_id ? item.course_hour_id : -1) > -1
                                }
                                data-id={item.course_hour_id}
                                data-name={item.courseInfo && item.courseInfo.course_code + ' - ' + item.courseInfo.name}
                                data-item={JSON.stringify(item)}
                              />
                            </div>
                          ) : null}
                        </td>
                        <td
                          key={'course-' + item.course_id}
                          className="examperiod-coursehours-td"
                          data-title={item.courseInfo && item.courseInfo.name}
                          data-id={item.course_id}
                          data-course_id={item.course_id}
                          data-name={item.courseInfo && item.courseInfo.course_code + ' - ' + item.courseInfo.name}
                          data-item={JSON.stringify(item)}
                          onClick={this.onShowCourseProgram}
                          style={
                            this.state.selectedCourseId == item.course_id
                              ? {
                                backgroundColor: 'rgba(218, 225, 232, 0.96)',
                                border: '1px solid darkgrey'
                              }
                              : {}
                          }
                        >
                          <table>
                            <tbody>
                              <tr>
                                <td className="examp-compt-select">
                                  {item.status ? (
                                    <i className="material-icons" style={{ color: '#33c900c4' }}>
                                      check_circle
                                    </i>
                                  ) : (
                                    <i className="material-icons" style={{ color: '#ff0000c4' }}>
                                      cancel
                                    </i>
                                  )}
                                </td>
                                <td className="examp-compt-select">
                                  {(item.courseInfo && item.courseInfo.campus_ids && item.courseInfo.campus_ids.filter(value => ExamPeriodCourseTermLectureLocations(T).map((x: any) => x.value).includes(value)).length > 0) ? (
                                    <i className="material-icons" style={{ color: '#2A8CD9' }}>
                                      vpn_lock
                                    </i>
                                  ) : (item.status_classroom_assignment ? (
                                    <i className="material-icons" style={{ color: '#33c900c4' }}>
                                      check_circle
                                    </i>
                                  ) : (
                                    <i className="material-icons" style={{ color: '#ff0000c4' }}>
                                      cancel
                                    </i>
                                  ))}
                                </td>
                                <td className="table-course-hours-classrooms-td-8 text-center">
                                  <div id="clickList" className="table-scrollable-td">{item.courseInfo && `${item.courseInfo.course_id} - (${item.courseInfo.course_code.split(';').join(', ')}) ${item.courseInfo.name.split(';').join(', ')}`}</div>
                                </td>
                                <td className="table-course-hours-classrooms-td-8 text-center" >
                                  <div className="table-scrollable-td">{item.courseInfo && `${item.courseInfo.exam_duration} - ${item.courseInfo.campuses && item.courseInfo.campuses.map(item => item.label).filter((value, index, self) => self.indexOf(value) === index).join(', ')} - ${item.courseInfo.student_count_total}`}</div>
                                </td>
                                <td className="table-course-hours-classrooms-td-8 text-center">
                                  {
                                    item.courseInfo && item.courseInfo.instructors && item.courseInfo.instructors.length > 0 ?
                                      <div className="table-scrollable-td">{item.courseInfo.instructors && item.courseInfo.instructors.map((i: any, index: any) => (index == item.courseInfo!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td className="table-course-hours-classrooms-td-8 text-center" >
                                  {
                                    item.courseInfo && item.courseInfo.programs && item.courseInfo.programs.length > 0 ?
                                      <div className="table-scrollable-td">
                                        {item.courseInfo.programs && item.courseInfo.programs.map((i: any, index: any) => <>{`${item.courseInfo && item.courseInfo.student_counts && item.courseInfo.student_counts[index] != undefined ? item.courseInfo && item.courseInfo.student_counts && item.courseInfo.student_counts[index] : '-'} - ${i.label} - ${item.courseInfo && item.courseInfo.classes && item.courseInfo.classes[index] != undefined ? item.courseInfo && item.courseInfo.classes && item.courseInfo.classes[index].label : '-'}`}, <br /></>)}
                                      </div> : '-'
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                  } else {
                    tableContent = (
                      <tr key={'course-' + item.course_id} data-title={item.courseInfo && item.courseInfo.name}>
                        <td data-cell="select">
                          {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e' || this.props.user.role == 'p') ? (
                            <div className="tick-radio position-relative">
                              <input
                                id='course_hour'
                                type="checkbox"
                                className="form-radio"
                                checked={
                                  this.state.selected_ids &&
                                  this.state.selected_ids.indexOf(item.course_hour_id ? item.course_hour_id : -1) > -1
                                }
                                data-id={item.course_hour_id}
                                data-name={
                                  item.courseInfo && item.courseInfo.course_code + ' - ' + item.courseInfo.name
                                }
                                data-item={JSON.stringify(item)}
                                onChange={this.onSelectCourseCalledFromCoursesTab}
                              />
                            </div>
                          ) : null}
                        </td>
                        <td scope="row" data-label={T.t('gen_status')}>
                          <div className="tags ml-1 mr-4">
                            <button
                              id='button_status'
                              className={
                                `small-tag text-uppercase` +
                                (item.courseInfo && item.courseInfo.status == 1 ? ` tag-green` : ` tag-red`)
                              }
                            >
                              {GT.GetActiveStatus(item.courseInfo && item.courseInfo.status)}
                            </button>
                          </div>
                        </td>
                        <td data-label="Onay" className="text-center">
                          {item.status == 1 ? (
                            <span
                              className="badge badge-success"
                              style={{
                                fontSize: '95%',
                                textAlign: 'center'
                              }}
                            >
                              {T.t('gen_badge_assigned')}
                            </span>
                          ) : (
                            <span
                              className="badge badge-warning"
                              style={{
                                fontSize: '95%',
                                textAlign: 'center'
                              }}
                            >
                              {T.t('gen_badge_unassigned')}
                            </span>
                          )
                          }
                          {combinedCourses && combinedCourses.length > 0 ? (
                            <span
                              className="badge badge-danger"
                              style={{
                                fontSize: '95%',
                                textAlign: 'center'
                              }}
                            >
                              {T.t('gen_badge_combined_exams')}
                            </span>
                          ) : null}
                        </td>
                        <td data-label="classroom_assignment_status" className="text-center">
                          {item.status_classroom_assignment == 1 ? (
                            <span
                              className="badge badge-success"
                              style={{
                                fontSize: '95%',
                                textAlign: 'center'
                              }}
                            >
                              {T.t('gen_badge_assigned')}
                            </span>
                          ) :
                            examEnvironment === 2
                              ? <div className='tw-text-xl tw-text-[#28a745] tw-text-center tw-items-center tw-justify-center tw-flex'><ImEarth /></div>
                              : (<span
                                className="badge badge-warning"
                                style={{
                                  fontSize: '95%',
                                  textAlign: 'center'
                                }}
                              >
                                {T.t('gen_badge_unassigned')}
                              </span>
                              )}
                        </td>
                        <td scope="row" data-label={T.t('gen_course_no')} className="text-center">
                          {item.courseInfo && item.courseInfo.course_id}
                        </td>
                        <td scope="row" data-label={T.t('gen_code')} className="text-center">
                          <div className="table-scrollable-td">{item.courseInfo && item.courseInfo.course_code ? item.courseInfo.course_code.split(';').join(', ') : '-'}</div>
                        </td>
                        <td scope="row" data-label={T.t('gen_name')} className="text-center">
                          <div className="table-scrollable-td">{item.courseInfo && item.courseInfo.conjugate_course_code ? item.courseInfo.conjugate_course_code.split(';').join(', ') : '-'}</div>
                        </td>
                        <td scope="row" data-label={T.t('gen_name')} className="text-center">
                          <div className="table-scrollable-td">{item.courseInfo && item.courseInfo.name ? item.courseInfo.name.split(';').join(', ') : '-'}</div>
                        </td>
                        <td className="text-center">
                          {
                            item.courseInfo && item.courseInfo.student_counts && item.courseInfo.student_counts.length > 0 ?
                              <div className="table-scrollable-td">{item.courseInfo.student_counts && item.courseInfo.student_counts.map((i: any, index: any) => (index == item.courseInfo!.student_counts!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                          }
                        </td>
                        <td className="text-center">
                          {
                            item.courseInfo && item.courseInfo.student_counts && item.courseInfo.student_counts.length > 0 ?
                              <div className="table-scrollable-td">{item.courseInfo.student_count_total}</div> : '-'
                          }
                        </td>
                        <td className="text-center">
                          {
                            item.courseInfo && item.courseInfo.classes && item.courseInfo.classes.length > 0 ?
                              <div className="table-scrollable-td">{item.courseInfo.classes && item.courseInfo.classes.map((i: any, index: any) => (index == item.courseInfo!.classes!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                          }
                        </td>
                        <td className="text-center">
                          {
                            item.courseInfo && item.courseInfo.sections && item.courseInfo.sections.length > 0 ?
                              <div className="table-scrollable-td">{item.courseInfo.sections && item.courseInfo.sections.map((i: any, index: any) => (index == item.courseInfo!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                          }
                        </td>
                        <td className="text-center">
                          {
                            item.courseInfo && item.courseInfo.faculties && item.courseInfo.faculties.length > 0 ?
                              <div className="table-scrollable-td">{item.courseInfo.faculties && item.courseInfo.faculties.map((i: any, index: any) => (index == item.courseInfo!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                          }
                        </td>
                        <td className="text-center">
                          {
                            item.courseInfo && item.courseInfo.campuses && item.courseInfo.campuses.length > 0 ?
                              <div className="table-scrollable-td">{item.courseInfo.campuses && item.courseInfo.campuses.map((i: any, index: any) => (index == item.courseInfo!.campuses!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                          }
                        </td>
                        <td scope="row" data-label={T.t('gen_exam_duration')} className="text-center">
                          {item.courseInfo && item.courseInfo.exam_duration}
                        </td>
                        <td data-label={T.t('gen_session_count')} className="text-center">
                          {item.courseInfo && item.courseInfo.exam_session}
                        </td>
                        <td data-label={T.t('gen_classroom_combine_status')} className="text-center">
                          {ClassroomCombineStatusObject && ClassroomCombineStatusObject.label}
                        </td>
                        <td data-label={T.t('gen_exam_type')} className="text-center">
                          {ExamTypesObject && ExamTypesObject.label ? ExamTypesObject && ExamTypesObject.label : '-'}
                        </td>
                        <td data-label={T.t('gen_exam_environment')} className="text-center">
                          {ExamEnvironmentsObject && ExamEnvironmentsObject.label ? ExamEnvironmentsObject && ExamEnvironmentsObject.label : '-'}
                        </td>
                        <td data-label={T.t('gen_exam_overlap_type')} className="text-center">
                          {ExamOverlapTypesObject && ExamOverlapTypesObject.label ? ExamOverlapTypesObject && ExamOverlapTypesObject.label : '-'}
                        </td>
                        <td className="text-center">
                          {
                            item.courseInfo && item.courseInfo.programs && item.courseInfo.programs.length > 0 ?
                              <div className="table-scrollable-td">{item.courseInfo.programs && item.courseInfo.programs.map((i: any, index: any) => (index == item.courseInfo!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                          }
                        </td>
                        <td className="text-center">
                          {
                            item.courseInfo && item.courseInfo.instructors && item.courseInfo.instructors.length > 0 ?
                              <div className="table-scrollable-td">{item.courseInfo.instructors && item.courseInfo.instructors.map((i: any, index: any) => (index == item.courseInfo!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                          }
                        </td>
                        <td className="text-center">
                          {
                            item.courseInfo && item.courseInfo.assistant_staff && item.courseInfo.assistant_staff.length > 0 ?
                              <div className="table-scrollable-td">{item.courseInfo.assistant_staff && item.courseInfo.assistant_staff.map((i: any, index: any) => (index == item.courseInfo!.assistant_staff!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                          }
                        </td>
                        <td data-label={T.t('gen_description')} className="text-center">
                          {item.courseInfo && item.courseInfo.description && item.courseInfo.description.length ?
                            <div className="table-scrollable-td">{item.courseInfo.description}</div> : '-'}
                        </td>
                        <td data-label={T.t('gen_actions')} className="table-buttons">
                          <div className="table-buttons-wrapper">
                            {combinedCourses && combinedCourses.length ? (
                              <button
                                id='button_library_books'
                                data-toggle="tooltip"
                                title={T.t('gen_badge_combined_exams')}
                                className="btn btn-light btn-sm table-button"
                                onClick={() => {
                                  this.saveCombinedCourses(combinedCourses, item);
                                }}
                              >
                                <span className="d-block">
                                  <i className="material-icons">library_books</i>
                                </span>
                              </button>
                            ) : (
                              <button
                                id='button_edit'
                                data-toggle="tooltip"
                                title={T.t('gen_activity_edit')}
                                className="btn btn-light btn-sm table-button"
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    courseFormIsOpen: !this.state.courseFormIsOpen,
                                    editCourse: item
                                  });
                                }}
                              >
                                <span className="d-block">
                                  <i className="material-icons">edit</i>
                                </span>
                              </button>
                            )}
                            <button
                              id='button_access_time'
                              data-toggle="tooltip"
                              data-id={item.course_id}
                              onClick={() =>
                                this.props.dispatch(
                                  Actions.ShowModal({
                                    title: T.t('gen_hours'),
                                    body: T.t('gen_exam_dates_default_assigned_hours_info'),
                                    name: 'examPeriod_delete',
                                    icon: 'error_outline',
                                    iconColor: 'red',
                                    cancel: T.t('gen_close')
                                  })
                                )
                              }
                              title={T.t('gen_display_hours')}
                              className="btn btn-light btn-sm table-button"
                            >
                              <span className="d-block" data-toggle="modal" data-target="#addNew">
                                <i className="material-icons">access_time</i>
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }

                  return tableContent;
                })
              ) : (
                <tr>
                  <td colSpan={24}>
                    <Announce title={T.t('gen_no_records_found')} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row-options justify-content-end">
            <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
              {this.state.course_list && this.state.course_list.length > 0 ? (
                <Paginate filters={this.props.filters} onPageChange={this.onPageChange} onSizeChange={this.onSizeChange} />
              ) : null}
            </div>
          </div>
        </div>
        {
          this.state.classroomAddModalIsOpen && (
            <ClassroomAddModal
              modalIsOpen={this.state.classroomAddModalIsOpen}
              selectedCourse={this.state.selected_course ? this.state.selected_course.courseInfo : null}
              courseList={this.state.selected_courses ? this.state.selected_courses.map((item: any) => item.item.courseInfo) : null}
              onClose={this.switchClassroomModalStatus}
              onUpdateList={() => this.getCourseHours()}
              calledFromCourseTab={true}
              selectedCourseIDs={this.state.selected_course_ids}
            />
          )
        }
        {
          this.state.combineExamsModalIsOpen && (
            <CombineExamsModal
              courseList={this.state.selected_courses}
              combinedDataList={this.state.combined_courses}
              modalIsOpen={this.state.combineExamsModalIsOpen}
              courseObject={this.state.courseObject}
              onClose={() => {
                this.switchCombineExamsModalStatus()
                this.cleanCombinedCourse()
              }}
              showCoursesOnly={this.state.hasCombinedCourses}
              onUpdateList={() => this.getCourseHours()}
              coursesAreCombined={this.state.hasCombinedCourses ? true : false}
            />
          )
        }
        {
          this.state.courseCopyModalIsOpen && (
            <CopyCourseModal
              course={this.state.selected_courses}
              modalIsOpen={this.state.courseCopyModalIsOpen}
              onClose={this.switchCopyExamModalStatus}
              showCoursesOnly={this.state.hasCombinedCourses}
              onUpdateList={() => this.getCourseHours()}
            />
          )
        }
        {
          this.state.courseFormIsOpen && (
            <CourseEditForm
              programs_related_faculty={[]}
              course={this.state.editCourse}
              formIsOpen={this.state.courseFormIsOpen}
              onClose={() => {
                this.setState({
                  courseFormIsOpen: !this.state.courseFormIsOpen
                });
                this.getCourseHours();
              }}
            />
          )
        }
        {
          this.state.bulkCombineExamsModalIsOpen && (
            <BulkCombineExamsModal
              modalIsOpen={this.state.bulkCombineExamsModalIsOpen}
              onClose={this.switchBulkCombineExamsModalStatus}
              onUpdateList={() => this.getCourseHours()}
            />
          )
        }
        {
          this.state.downloadHoursModalIsOpen ? (
            <HoursExportModal
              onClose={this.switchDownloadHoursModalStatus}
              modalIsOpen={this.state.downloadHoursModalIsOpen}
              termID={this.state.filters.term_id}
              sectionType={SectionTypes.Planning_Courses}
            />
          ) : null
        }
      </div >
    );

    return <div className="container-fluid p-0"><Spinner name="course-hours-spinner" />{Table}</div>;
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICoursePageProps): Types.ICoursePageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICoursePageProps = Object.assign({}, ownProps, {
    user: store.state.user,
    courseList:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.course_hours &&
      store.state.examPeriodModal.course_hours.course_list,
    results: store.state.course_page && store.state.course_page.results,
    filters: store.state.examPeriodModal && store.state.examPeriodModal.filters,
    all_ids:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.course_hours &&
      store.state.examPeriodModal.course_hours.all_ids,
    selectOptions: store.state.select_options && store.state.select_options.coursePage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    general_settings: store.state.general_settings,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseAddModal);

export default container;

import React, { Component } from 'react';
import Select from 'react-select';
import { Formik, ErrorMessage, FormikProps, FormikActions } from 'formik';
import { Log } from 'ng2-logger';
import { connect } from 'react-redux';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';

import * as Types from '../../store/types';
import * as Constants from '../../store/constants/all';
import * as Actions from '../../store/actions/general';
import { ProgramFormValidation } from './validations/program-form-val';
import {
  ProgramFormInitialValues,
  ProgramSearchInitialValues,
  ProgramGradeSelectOptions,
  EducationTypeSelectOptions
} from '../../store/constants/program-const';

import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import Translator from '../../services/translate-factory';
const T = Translator.create();
const Logger = Log.create('ClassroomForm');

function getInitialState(): Types.IProgramFormState {
  const initialValues: Types.IProgramFormState = {
    model: Object.assign({}, ProgramFormInitialValues)
  };
  return Object.assign({}, initialValues);
}

function getGradeSelectOptions(term_type: any, props: any) {
  let gradeSelectOptions = ProgramGradeSelectOptions;
  if (term_type !== undefined && term_type === -1 && props.values.grades !== undefined && props.values.grades.length > 0) {
    gradeSelectOptions = gradeSelectOptions.filter((x) => !(props.values.grades!.map((y: any) => parseInt(y.value)).includes(x.value)));
  }
  return gradeSelectOptions;
}

class ProgramForm extends Component<Types.IProgramFormProps, Types.IProgramFormState> {
  state: Types.IProgramFormState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.addEventListener('keydown', this.handleKeyDown);
  }
  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setCloseForm();
    }
  };

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };
  disableAllChildren = (isDisabled: boolean) => {
    const disableOnLoading = document.getElementById('disableOnLoading');

    if (disableOnLoading) {
      disableOnLoading.querySelectorAll('input, button, textarea, select').forEach((element) => {
        if (element instanceof HTMLInputElement ||
          element instanceof HTMLButtonElement ||
          element instanceof HTMLTextAreaElement ||
          element instanceof HTMLSelectElement) {
          element.disabled = isDisabled;
        }
      });
    }
  };

  onFormSave = (model: Types.IProgramItem, FormActions: FormikActions<Types.IProgramItem>) => {
    this.disableAllChildren(true)

    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.setClose(true);
      }
      this.disableAllChildren(false)
      FormActions.setSubmitting(false);
    };

    console.log("model", model)

    if (this.props.term_type === -1) {
      if (model.grade_ids && model.grade_ids.length > 0) {
        model.class = model.grade_ids.join(';');
      } else {
        this.disableAllChildren(false)
        return;
      }
    } else if (model.grade_ids && model.grade_ids.length == 1) {
      model.grade = model.grade_ids[0];
    }

    if (this.props.programId) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.program.PROGRAM_UPDATE, model, 'program-form-spin', resultCallback)
      );
    } else if (this.props.term_type === 0) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.program.PROGRAM_MULTI_CREATE, model, 'program-form-spin', resultCallback)
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(Constants.program.PROGRAM_CREATE, model, 'program-form-spin', resultCallback)
      );
    }
  };

  static getDerivedStateFromProps(props: Types.IProgramFormProps, state: Types.IProgramFormState) {
    if (!props || !state) return null;

    let hasNewState = false;

    if (props.programId && props.programId !== state.model.program_id) {
      state.model.program_id = props.programId;
      props.dispatch(Actions.ApiRequest(Constants.program.PROGRAM_GET_BY_ID, props.programId, 'program-form-spin'));
      hasNewState = true;
    }

    if (props.form && props.programId === state.model.program_id) {
      state.model = { ...props.form, faculty_id: (props.form.faculty && props.form.faculty.value) || state.model.faculty_id };
      hasNewState = true;
    }

    ProgramForm.updateGradeAndEducationType(state, props.form);

    if (hasNewState) return state;

    if (!props.programId && state.model.program_id) return getInitialState();

    return null;
  }

  static updateGradeAndEducationType(state: Types.IProgramFormState, form: any) {
    if (!form) return;

    if (form.grade !== undefined) {
      const gradeObject = ProgramGradeSelectOptions.find((item) => item.value === form.grade);
      state.model.grade_object = gradeObject;
    }

    if (form.education_type !== undefined) {
      const educationTypeObject = EducationTypeSelectOptions(T).find((item) => item.value === form.education_type);
      state.model.educationType_object = educationTypeObject;
    }

    if (state.model.grades && state.model.grades.length > 0) {
      state.model.grade_ids = state.model.grades.map((item) => item.value);
    }
  }

  getCustomGradeErrors = (props: FormikProps<Types.IProgramItem>) => {
    if (props.submitCount > 0 && (props.values.grades == undefined || (props.values.grade_ids && props.values.grade_ids.length == 0))) {
      return <div className="error">{T.t('gen_cannot_leave_empty')}</div>
    }
    return null;
  }
  render() {
    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={ProgramFormValidation(T, this.props.term_type!)}
        >
          {(props: FormikProps<Types.IProgramItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit } = props;

            let gradeSelectOptions = getGradeSelectOptions(this.props.term_type, props);

            return (
              <form onSubmit={handleSubmit}>
                <div id="disableOnLoading">
                  <div className="" id="addNew">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">
                            {this.props.programId ? <><i className="material-icons mr-2">edit</i>{T.t('gen_update_program')}</> : <><i className="material-icons mr-2">add_circle_outline</i> {T.t('gen_add_program')}</>}
                          </h5>
                          <button
                            id='button_close'
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={this.setCloseForm}
                          >
                            <i className="material-icons">close</i>
                            <span>ESC</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-md-6 form-input form-group with-icon">
                              <input
                                id="program_code"
                                name="program_code"
                                value={values.program_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="program_code">{T.t('gen_code')}</label>
                              {errors && errors.program_code && props.submitCount > 0 && (
                                <div className="error">{errors && errors.program_code}</div>
                              )}
                            </div>
                            <div className="col-md-6 form-input form-group with-icon">
                              <input
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="name">{T.t('gen_name')}</label>
                              {errors && errors.name && props.submitCount > 0 && (
                                <div className="error">{errors && errors.name}</div>
                              )}
                            </div>
                            <div className="col-md-12">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_faculty_college')}</label>
                                  <Select
                                    id='select_faculty'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.faculties
                                        ? this.props.selectOptions.faculties
                                        : []
                                    }
                                    placeholder={T.t('gen_select_faculty')}
                                    value={props.values.faculty}
                                    onChange={(option: any) => {
                                      props.setFieldValue('faculty_id', option.value);
                                      props.setFieldValue('faculty', option);
                                      gradeSelectOptions = getGradeSelectOptions(this.props.term_type, props);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                  />
                                </div>
                                {errors && errors.faculty_id && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.faculty_id}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 form-input form-group with-icon">
                              <input
                                id="daily_max_class"
                                name="daily_max_class"
                                value={values.daily_max_class}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="daily_max_class">{T.t('gen_daily_max_course')}</label>
                              {errors && errors.daily_max_class && props.submitCount > 0 && (
                                <div className="error">{errors && errors.daily_max_class}</div>
                              )}
                            </div>
                            <div className="col-md-6 form-input form-group with-icon">
                              <input
                                id="min_class_interval_bw_campuses"
                                name="min_class_interval_bw_campuses"
                                value={values.min_class_interval_bw_campuses}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="min_class_interval_bw_campuses">{T.t('gen_min_gap_between_campuses')}</label>
                              {errors && errors.min_class_interval_bw_campuses && props.submitCount > 0 && (
                                <div className="error">{errors && errors.min_class_interval_bw_campuses}</div>
                              )}
                            </div>
                            {this.props && this.props.term_type !== 1 ? (
                              <div className="col-md-6 form-input form-group with-icon">
                                <input
                                  id="max_gap_between_courses"
                                  name="max_gap_between_courses"
                                  value={values.max_gap_between_courses}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="max_gap_between_courses">{T.t('gen_max_gap_between_courses')}</label>
                                {errors && errors.max_gap_between_courses && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.max_gap_between_courses}</div>
                                )}
                              </div>
                            ) : null}
                            <div className="col-md-6 form-input form-group with-icon">
                              <input
                                id="weekly_max_course_days"
                                name="weekly_max_course_days"
                                value={values.weekly_max_course_days}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                max={7}
                                min={1}
                                defaultValue={"5"}
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="weekly_max_course_days">{T.t('gen_weekly_max_course_days')}</label>
                              {errors && errors.weekly_max_course_days && props.submitCount > 0 && (
                                <div className="error">{errors && errors.weekly_max_course_days}</div>
                              )}
                            </div>
                            {this.props && this.props.term_type === -1 ? (
                              <div className="col-md-12">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_grade')}</label>
                                    <Select
                                      id='grades'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={gradeSelectOptions}
                                      placeholder={T.t('gen_select_grade')}
                                      value={props.values.grades}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('grades', list);
                                        props.setFieldValue(
                                          'grade_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_classroom')}
                                    />
                                  </div>
                                  {
                                    this.getCustomGradeErrors(props)
                                  }
                                </div>
                              </div>
                            ) : null}
                            <div className="col-md-12">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_education_type')}</label>
                                  <Select
                                    id='education_type'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={EducationTypeSelectOptions(T)}
                                    placeholder={T.t('gen_select_education_type')}
                                    value={
                                      props.values.education_type != undefined && props.values.education_type != null
                                        ? T.t_options(EducationTypeSelectOptions(T)).find((option) => option.value == props.values.education_type)
                                        : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('education_type', option.value);
                                      props.setFieldValue('education_type_object', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                  />
                                </div>
                                {errors && errors.education_type && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.education_type}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 form-input form-group with-icon">
                              <textarea
                                name="description"
                                className="form-input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={2}
                                id="description"
                                value={values.description}
                                placeholder={T.t('gen_description')}
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="description" />
                              {errors && errors.description && props.submitCount > 0 && (
                                <div className="error">{errors && errors.description}</div>
                              )}
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-6">
                              <div className="text-left">
                                <h6>{T.t('gen_status')}</h6>
                                <div className="tick-radio position-relative d-inline-block">
                                  <Checkbox name="status" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Spinner name="program-form-spin" />
                        <div className="modal-footer d-block">
                          <div className="row">
                            <div className="col-md">
                              <button
                                id='button_cancel'
                                type="button"
                                data-dismiss="modal"
                                className="btn btn-gray min-auto"
                                aria-label="Close"
                                onClick={this.setCloseForm}
                              >
                                {T.t('gen_cancel')}
                              </button>
                            </div>
                            <div className="col-md text-md-right">
                              <button
                                id='button_save'
                                type="button"
                                data-dismiss="modal"
                                aria-label="alert-success"
                                onClick={() => handleSubmit()}
                                disabled={props.isSubmitting}
                                className="btn btn-green"
                              >
                                <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IProgramFormProps): Types.IProgramFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IProgramFormProps = Object.assign({}, ownProps, {
    form: store.state.program_page && store.state.program_page.form ? store.state.program_page.form : getInitialState(),
    selectOptions: store.state.select_options && store.state.select_options.programPage,
    term_id: store.state.term_id,
    term_type: store.state.term_type
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.program_page) {
    return (
      !!equal(
        prev.state.program_page && prev.state.program_page.form,
        next.state.program_page && next.state.program_page.form
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programPage,
        next.state.select_options && next.state.select_options.programPage
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(ProgramForm);

export default container;

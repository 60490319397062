import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import Translator from '../../../services/translate-factory';
import * as Constants from '../../../store/constants/all';
import * as Actions from '../../../store/actions/general';
import SolutionEditModal from './solution-edit-modal';
import { ExamTypes } from '../../../store/constants/course-const';
import * as Types from '../../../store/types';
import { connect } from 'react-redux';

const T = Translator.create();
const Logger = Log.create('DisplayEventModal');

class DisplayEventModal extends Component<any, any> {
  state: any = {
    term_id: -1,
    combined_courses: [],
    formValues: {
      student_count: -1,
      practical_staff: [],
      practical_staff_ids: undefined,
      lecture_staff: [],
      lecture_staff_ids: undefined
    },
    solutionEditModalIsOpen: false
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getGeneralSettings();

    /*let regexObject = window.location.pathname.match(/([^\/]+$)/);
    let id = regexObject ? parseInt(regexObject[0], 10) : -1;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  getGeneralSettings() {
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_GENERAL_SETTINGS, 'general-settings-spin'));
  }

  getSolutionCourseSelectOptions() {
    let term_id = this.props.termId === undefined ? this.props.term_id : this.props.termId;
    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_COURSE_SELECT_OPTIONS, term_id, 'solution-course-select-options-spin'));
  };

  onSolutionEdit = (event: any) => {
    if (event.solutionPublishmentInfo.isPublished) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_edit'),
          body: T.t("gen_solution_process_info"),
          name: 'solution_edit',
          icon: 'error_outline',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
    } else {
      this.getSolutionCourseSelectOptions();

      this.setState({
        ...this.state,
        solutionEditModalIsOpen: !this.state.solutionEditModalIsOpen
      });
    }
  }

  switchSolutionEditModalStatus = () => {
    this.getSolutionCourseSelectOptions();

    this.setState({
      ...this.state,
      solutionEditModalIsOpen: !this.state.solutionEditModalIsOpen
    });

    if (this.state.solutionEditModalIsOpen === false) {
      this.setCloseModal();
    }
  };

  onDeleteSolutionCourse = (event: any) => {
    if (event.solutionPublishmentInfo.isPublished) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete'),
          body: T.t("gen_solution_process_info"),
          name: 'solution_delete',
          icon: 'error_outline',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
    } else {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_action'),
          content: (
            <div>
              {event.name} ({event.code}) - {event.id}
              <p> {T.t('gen_delete_lecture_question')} </p>
            </div>
          ),
          name: 'solution_course_delete',
          icon: 'delete',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: any, status: number) => {
              if (status === 200) {
                this.setCloseModal();
              }
            };

            let model = {
              term_id: event.term_id,
              solution_id: event.solution_id,
              course_id: event.id,
              solution_view_course_id: event.view_id,
              user_id: this.props.user.userId,
              username: this.props.user.username
            }

            this.props.dispatch(
              Actions.ApiRequest(Constants.solution.SOLUTION_DELETE_COURSE, model, 'solution-course-list-spin', resultCallback)
            );
          }
        })
      );
    }
  };

  render() {
    const event = JSON.parse(this.props.event.desc);
    let calendar_classroom_display_type: number = this.props.settings != undefined ? this.props.settings.calendar_classroom_display_type : this.state.settings.calendar_classroom_display_type;
    let settings_solution_show_course_opened_sections: boolean = this.props.settings != undefined ? this.props.settings.solution_show_course_opened_sections : this.state.settings.solution_show_course_opened_sections;

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '90%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-xs-12">
                  <span className="lesson-code">{event.code}</span>
                  <span className="lesson-code">{event.id}</span>
                  <span className="lesson-name">{event.name}</span>
                </div>
                <div className="col-md-1 col-xs-12">
                  {this.props.user && this.props.user.role === 's' ? (
                    <button
                      className="category-tag-square float-rigth"
                      style={{ color: '#fff', backgroundColor: '#dc3545' }}
                      onClick={() => this.onDeleteSolutionCourse(event)}
                    >
                      <i className="material-icons mr-2">delete_outline</i>
                      <span> {T.t('gen_delete')}</span>
                    </button>
                  ) : null}
                </div>
                <div className="col-md-1 col-xs-12">
                  {this.props.user && this.props.user.role === 's' ? (
                    <button
                      className="category-tag-square tag-orange"
                      onClick={() => this.onSolutionEdit(event)}
                    >
                      <i className="material-icons mr-2">edit</i>
                      <span>{T.t('gen_edit')}</span>
                    </button>
                  ) : null}
                </div>
                <div className="col-md-1 col-xs-12">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.setCloseModal}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body  text-center">
            <div className="container">
              <div className="row tw-pb-4">
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-orange tw-h-full">
                    <span className="tits">{T.t('gen_date') + ' ' + T.t('gen_time')}</span>
                    <span className="text">{event.date}</span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-green tw-h-full">
                    <span className="tits">{T.t('gen_max_exam_duration')}</span>
                    <span className="text">
                      {event.duration} {T.t('gen_minute')}
                    </span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-red tw-h-full">
                    <span className="tits">{T.t('gen_campus')}</span>
                    <span className="text p-0-15">
                      {(event.campuses && event.campuses.length > 0) ? event.campuses.map((campus: { campusCode: string; campusName: string; }, index: any) => (
                        <React.Fragment key={index}>
                          {campus.campusName} <br />
                        </React.Fragment>
                      ))
                        : <>{T.t("gen_remote_exam")} <br /> </>
                      }
                    </span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-blue tw-h-full">
                    <span className="tits">{T.t('gen_building')}</span>
                    <span className="text p-0-15">
                      {(event.buildings && event.buildings.length > 0) ? event.buildings.map((building: { buildingCode: string; buildingName: string; }, index: any) => (
                        <React.Fragment key={index}>
                          {building.buildingName} <br />
                        </React.Fragment>
                      ))
                        : <>- <br /></>
                      }
                    </span>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="row b-bottom-gray">
                <div className="col-md-3 col-xs-12 p-15">
                  <span className="tits p-0-15">{T.t('gen_instructor')}</span>
                  <span className="text p-0-15">
                    {event.instructors ? event.instructors : '-'}
                  </span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_faculty')}</span>
                  <span className="text p-0-15">
                    {event.faculties.map((faculty: { facultyCode: string; facultyName: string; }, index: any) => (
                      <React.Fragment key={index}>
                        {faculty.facultyName} <br />
                      </React.Fragment>
                    ))}
                  </span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_program')}</span>
                  <span className="text p-0-15">
                    {event.programs.map((program: { programCode: string; programName: string; }, index: any) => (
                      <React.Fragment key={index}>
                        {program.programName + ' (' + program.programCode + ') '} <br />
                      </React.Fragment>
                    ))}
                  </span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_total_student_count')}</span>
                  <span className="text p-0-15">{event.studentCount}</span>
                </div>
                <div className="clearfix" />
              </div>
              <div className="row">
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_grade')}</span>
                  <span className="text p-0-15">{event.grade}</span>
                </div>
                <div className="col-md-3 col-xs-12 p-15">
                  <span className="tits p-0-15">{T.t('gen_section')}</span>
                  <span className="text p-0-15">{(settings_solution_show_course_opened_sections && event.courseOpenedSection != undefined) ? event.courseOpenedSection : event.branch}</span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_session_count')}</span>
                  <span className="text p-0-15">{event.sessionCount}</span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_exam_type')}</span>
                  <span className="text p-0-15">{event.examType > 0 ? ExamTypes(T).find((object) => object.value == event.examType)!.label : "-"}</span>
                </div>
                <div className="col-md-3 col-xs-12">
                </div>
                <div className="clearfix" />
              </div>
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <div className="row b-c-red border">
                    {(event.campuses && event.campuses.length > 0)
                      ? event.classrooms.map(
                        (classroom: {
                          id: number;
                          code: string;
                          classroomType: number;
                          classroomName: string;
                          classroomCode: string;
                          session: number;
                          classroomStudentCount: number;
                          examCapacity: number;
                          invigilators: Array<{ id: number; title: string; name: string }>;
                        }) => {
                          let classroomStudent = event.students.find(
                            (object: any) => object.classroomCode.replace(/\s/g, '') === classroom.classroomCode.replace(/\s/g, '')
                          );

                          let commonClassroom = event.classrooms.filter((x: any) => x.classroomCode == classroom.classroomCode).sort((x: { session: number; }, y: { session: number; }) => x.session - y.session);
                          let classroomStudentCounts = commonClassroom.map((x: any) => x.classroomStudentCount).join(", ");
                          let sessions = commonClassroom.map((x: any) => x.session).join(", ");

                          return (
                            event.students && event.students.length > 0 ?
                              classroomStudent && classroomStudent.examSession === classroom.session ?
                                <><div className="col-md-6 col-xs-12 lesson-box b-bottom-gray text-left">
                                  <span className="tits">{T.t('gen_classroom')}</span>
                                  <span className="text">
                                    {classroom.classroomName ?
                                      (calendar_classroom_display_type == 0 ? (classroom.classroomName + ' (' + classroom.classroomCode + ')' + ' - ') :
                                        (calendar_classroom_display_type == 1 ? classroom.classroomCode + ' - ' : classroom.classroomName + ' - '))
                                      : '-' + ' - '}
                                    {classroom.classroomType ? this.props && this.props.solutionCourseSelectOptions && this.props.solutionCourseSelectOptions.classroom_types &&
                                      this.props.solutionCourseSelectOptions.classroom_types.find((item: Types.ISelectOption) => item.value == classroom.classroomType) &&
                                      this.props.solutionCourseSelectOptions.classroom_types.find((item: Types.ISelectOption) => item.value == classroom.classroomType).label : ''}<br />
                                    {T.t('gen_exam_capacity')}: {classroom.examCapacity != 0 ? classroom.examCapacity : '-'} <br />
                                    {T.t('gen_number_students_classroom')}: {classroom.classroomStudentCount} <br />
                                    {T.t('gen_session')}: {classroom.session}
                                  </span>
                                </div>
                                  <div className="col-md-6 col-xs-12 lesson-box b-bottom-gray">
                                    <span className="tits">{T.t('gen_invigilators')}</span>
                                    {classroom.invigilators.length
                                      ? classroom.invigilators.map((invigilator) => (
                                        <span className="text" key={invigilator.id}>
                                          {(invigilator.title ? invigilator.title : '-') + ' ' + (invigilator.name ? invigilator.name : '-')}
                                        </span>
                                      ))
                                      : '-'}
                                  </div></>
                                : null

                              : classroom.session == 1 ?
                                <><div className="col-md-6 col-xs-12 lesson-box b-bottom-gray text-left">
                                  <span className="tits">{T.t('gen_classroom')}</span>
                                  <span className="text">
                                    {classroom.classroomName ?
                                      (calendar_classroom_display_type == 0 ? (classroom.classroomName + ' (' + classroom.classroomCode + ')' + ' - ') :
                                        (calendar_classroom_display_type == 1 ? classroom.classroomCode + ' - ' : classroom.classroomName + ' - '))
                                      : '-' + ' - '}
                                    {classroom.classroomType ? this.props && this.props.solutionCourseSelectOptions && this.props.solutionCourseSelectOptions.classroom_types &&
                                      this.props.solutionCourseSelectOptions.classroom_types.find((item: Types.ISelectOption) => item.value == classroom.classroomType) &&
                                      this.props.solutionCourseSelectOptions.classroom_types.find((item: Types.ISelectOption) => item.value == classroom.classroomType).label : ''}<br />
                                    {T.t('gen_exam_capacity')}: {classroom.examCapacity != 0 ? classroom.examCapacity : '-'} <br />
                                    {T.t('gen_number_students_classroom')}: {classroomStudentCounts} <br />
                                    {T.t('gen_session')}: {sessions}
                                  </span>
                                </div>
                                  <div className="col-md-6 col-xs-12 lesson-box b-bottom-gray">
                                    <span className="tits">{T.t('gen_invigilators')}</span>
                                    {classroom.invigilators.length
                                      ? classroom.invigilators.map((invigilator) => (
                                        <span className="text" key={invigilator.id}>
                                          {(invigilator.title ? invigilator.title : '-') + ' ' + (invigilator.name ? invigilator.name : '-')}
                                        </span>
                                      ))
                                      : '-'}
                                  </div></>

                                : null
                          )
                        }
                      )
                      : <> <span className="tits">{T.t('gen_remote_exam')}</span><br /></>
                    }
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="b-c-orange border">
                    <span className="tits">{T.t('gen_all_invigilators')}</span>
                    {event.classrooms.map(
                      (classroom: {
                        id: number;
                        code: string;
                        classroomType: number;
                        classroomName: string;
                        classroomCode: string;
                        session: number;
                        classroomStudentCount: number;
                        examCapacity: number;
                        invigilators: Array<{ id: number; title: string; name: string }>;
                      }) => {
                        let classroomStudent = event.students.find(
                          (object: any) => object.classroomCode.replace(/\s/g, '') === classroom.classroomCode.replace(/\s/g, '')
                        );
                        return (
                          event.students && event.students.length > 0 ?
                            classroomStudent && classroomStudent.examSession === classroom.session ?
                              <>{classroom.invigilators.length
                                ? classroom.invigilators.map((invigilator) => (
                                  <span className="text" key={invigilator.id}>
                                    {(invigilator.title ? invigilator.title : '-') + ' ' + (invigilator.name ? invigilator.name : '-')}
                                  </span>
                                ))
                                : '-'}</>
                              : null
                            :
                            <>{classroom.invigilators.length
                              ? classroom.invigilators.map((invigilator) => (
                                <span className="text" key={invigilator.id}>
                                  {(invigilator.title ? invigilator.title : '-') + ' ' + (invigilator.name ? invigilator.name : '-')}
                                </span>
                              ))
                              : '-'}</>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
        <SolutionEditModal
          courseId={event.id}
          solutionId={event.solution_id}
          solutionEvent={event}
          solutionCourseSelectOptions={this.props.solutionCourseSelectOptions}
          modalIsOpen={this.state.solutionEditModalIsOpen}
          onClose={this.switchSolutionEditModalStatus}
          term_id={event.term_id}
          selected_ids={this.state.selected_ids}
        />
      </Modal>
    );
  }
}


const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICoursePageProps): Types.ICoursePageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICoursePageProps = Object.assign({}, ownProps, {
    user: store.state.user,
    term_id: store.state.term_id,
    settings: store.state.general_settings,
    solutionCourseSelectOptions: store.state.select_options && store.state.select_options.solutionCoursePage
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.course_page) {
    return (
      !!equal(
        prev.state.user,
        next.state.user
      ) &&
      !!equal(
        prev.state.term_id,
        next.state.term_id
      ) &&
      !!equal(
        prev.state.general_settings,
        next.state.general_settings
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.solutionCoursePage,
        next.state.select_options && next.state.select_options.solutionCoursePage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(DisplayEventModal);

export default container;

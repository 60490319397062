import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import {
  ChangeAllDataModalInstructorInitialValues,
  InvigilatorLevels,
  Titles,
  StaffTypes
} from '../../store/constants/instructor-const';
import Translator from '../../services/translate-factory';
import Checkbox from '../../components/checkboxes/single-checkbox';

const T = Translator.create();
const L = Log.create('ChangeAllDataModal');

function getInitialState(): Types.ChangeAllDataModalState {
  const initialValues: Types.ChangeAllDataModalState = {
    all_ids: [],
    selected_ids: []
  };
  return Object.assign({}, initialValues);
}

class ChangeAllDataModal extends Component<any, Types.ChangeAllDataModalState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getProgramsByFacultiesAtInstructors([]);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getProgramsByFacultiesAtInstructors = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.instructor.INSTRUCTOR_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'users-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: Types.IClassroomPageProps, state: Types.ChangeAllDataModalState) {
    let hasNewState: boolean = false;
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onChangeAllData(values: any, FormActions: FormikActions<Types.IInstructorFilterChangeAllDataModal>) {
    const resultCallback = (result: any, status: number) => {
      if (status === 200) {
        this.setClose(true);
      }
    };

    let model;
    switch (values.selected_type_ids) {
      case 0:
        model = {
          selected_type: 0,
          term_id: this.props.term_id,
          staff_type: values.staff_type,
          instructor_ids: this.props.selected_ids
        }
        break;
      case 1:
        model = {
          selected_type: 1,
          term_id: this.props.term_id,
          title: values.title,
          instructor_ids: this.props.selected_ids
        }
        break;
      case 2:
        model = {
          selected_type: 2,
          term_id: this.props.term_id,
          status: values.status,
          instructor_ids: this.props.selected_ids
        }
        break;
      case 3:
        model = {
          selected_type: 3,
          term_id: this.props.term_id,
          daily_max_class: values.daily_max_class,
          instructor_ids: this.props.selected_ids
        }
        break;
      case 4:
        model = {
          selected_type: 4,
          term_id: this.props.term_id,
          invigilator_level: values.invigilator_level,
          instructor_ids: this.props.selected_ids
        }
        break;
      case 5:
        model = {
          selected_type: 5,
          term_id: this.props.term_id,
          invigilation_campus_ids: values.invigilation_campuses.map((i: any) => i.value),
          instructor_ids: this.props.selected_ids
        }
        break;
      case 6:
        model = {
          selected_type: 6,
          term_id: this.props.term_id,
          invigilation_faculty_ids: values.invigilation_faculties.map((i: any) => i.value),
          invigilation_program_ids: values.invigilation_programs.map((i: any) => i.value),
          instructor_ids: this.props.selected_ids
        }
        break;
      case 7:
        model = {
          selected_type: 7,
          term_id: this.props.term_id,
          max_invigilation_duty: values.max_invigilation_duty,
          instructor_ids: this.props.selected_ids
        }
        break;
      case 8:
        model = {
          selected_type: 8,
          term_id: this.props.term_id,
          faculty_of_duty_id: values.faculty_of_duty_id,
          program_of_duty_id: values.program_of_duty_id,
          instructor_ids: this.props.selected_ids
        }
        break;
      case 9:
        model = {
          selected_type: 9,
          term_id: this.props.term_id,
          max_time_bw_consecutive_invigilations: values.max_time_bw_consecutive_invigilations,
          instructor_ids: this.props.selected_ids
        }
        break;
      case 10:
        model = {
          selected_type: 10,
          term_id: this.props.term_id,
          daily_max_duty: values.daily_max_duty,
          instructor_ids: this.props.selected_ids
        }
        break;
      case 11:
        model = {
          selected_type: 11,
          term_id: this.props.term_id,
          weekly_max_day: values.weekly_max_day,
          instructor_ids: this.props.selected_ids
        }
        break;
      case 12:
        model = {
          selected_type: 12,
          term_id: this.props.term_id,
          contractual_workload: values.contractual_workload,
          instructor_ids: this.props.selected_ids
        }
        break;

      default:
        break;
    }

    this.props.dispatch(
      Actions.ApiRequest(Constants.instructor.INSTRUCTOR_CHANGE_ALL_DATA, model, 'instructor-list-spin', resultCallback)
    );
  }

  render() {

    let listOptions = this.props.term_type === 1 ? [
      { label: T.t('gen_staff_type'), value: 0 },
      { label: T.t('gen_title'), value: 1 },
      { label: T.t('gen_status'), value: 2 },
      { label: T.t('gen_invigilation_level'), value: 4 },
      { label: T.t('gen_invigilation_campuses'), value: 5 },
      { label: T.t('gen_invigilation_faculties') + ' & ' + T.t('gen_invigilation_programs'), value: 6 },
      { label: T.t('gen_max_time_bw_consecutive_invigilations'), value: 9 },
      { label: T.t('gen_daily_max_duty'), value: 10 },
      { label: T.t('gen_max_invigilation_count'), value: 7 },
      { label: T.t('gen_faculty_of_duty') + ' & ' + T.t('gen_program_of_duty'), value: 8 },
      { label: T.t('gen_contractual_workload'), value: 12 },
    ] : [
        { label: T.t('gen_staff_type'), value: 0 },
        { label: T.t('gen_title'), value: 1 },
        { label: T.t('gen_status'), value: 2 },
        { label: T.t('gen_daily_max_course'), value: 3 },
        { label: T.t('gen_weekly_max_day'), value: 11 },
        { label: T.t('gen_contractual_workload'), value: 12 }
      ];

    let invigilationProgramSelector = !this.props.invigilation_program_ids ? true : false;

    let titles = Titles(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_titles && this.props.selectOptions.additional_titles.length > 0) {
      titles = [...Titles(T), ...this.props.selectOptions.additional_titles].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '700px', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_changing_all_data')}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={ChangeAllDataModalInstructorInitialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                  this.onChangeAllData(values, actions);
                }}
              >
                {(props: FormikProps<Types.IInstructorFilterChangeAllDataModal>) => {
                  const { values, handleChange, errors, handleBlur, handleSubmit } = props;
                  let title = null;
                  if (props.values.title) {
                    let value = props.values.title.toLowerCase().replace(/[. ]+/g, '');
                    title = titles.find((title) => title.label.toLowerCase().replace(/[. ]+/g, '') == value);
                  }
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="col-md-12 pb-3">
                            {T.t('gen_change_all_data_description')}
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_change_all_data')}</label>
                                <Select
                                  className="react-select"
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  options={listOptions}
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.selected_types ? props.values.selected_types : null}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: any = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('selected_types', list);
                                    props.setFieldValue(
                                      'selected_type_ids',
                                      list.value
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                            </div>
                          </div>
                          {
                            props.values.selected_type_ids === 0 ?
                              <div className="col-md-8">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_staff_type')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={false}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={true}
                                      options={StaffTypes(T)}
                                      placeholder={T.t('gen_select_staff_type')}
                                      value={
                                        props.values.staff_type != null
                                          ? StaffTypes(T).find(
                                            (degree: Types.ISelectOption) => degree.value === props.values.staff_type
                                          )
                                          : null
                                      }
                                      onChange={(option: any) => props.setFieldValue('staff_type', option.value)}
                                      noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                    />
                                  </div>
                                </div>
                              </div> :
                              props.values.selected_type_ids === 1 ?
                                <div className="col-md-8">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_title')}</label>
                                      <Select
                                        className="react-select"
                                        isMulti={false}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={titles}
                                        placeholder={T.t('gen_select_title')}
                                        value={title}
                                        onChange={(option: any) => props.setFieldValue('title', option.value)}
                                        noOptionsMessage={(): string => T.t('gen_select_no_title')}
                                      />
                                    </div>
                                  </div>
                                </div> :
                                props.values.selected_type_ids === 2 ?
                                  <div className="col-md-8">
                                    <div className="text-left">
                                      <h6>{T.t('gen_status')}</h6>
                                      <div className="tick-radio position-relative d-inline-block">
                                        <Checkbox name="status" />
                                      </div>
                                    </div>
                                  </div> :
                                  props.values.selected_type_ids === 3 ?
                                    <div className="col-md-8">
                                      <div className="col-md-6 form-input form-group with-icon">
                                        <input
                                          id="daily_max_class"
                                          name="daily_max_class"
                                          value={props.values.daily_max_class}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          type="number"
                                          required
                                        />
                                        <span className="highlight" />
                                        <span className="bar" />
                                        <label htmlFor="daily_max_class">{T.t('gen_daily_max_course')}</label>
                                      </div>
                                    </div> :
                                    props.values.selected_type_ids === 11 ?
                                      <div className="col-md-8">
                                        <div className="col-md-6 form-input form-group with-icon">
                                          <input
                                            id="weekly_max_day"
                                            name="weekly_max_day"
                                            value={props.values.weekly_max_day}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="number"
                                            required
                                          />
                                          <span className="highlight" />
                                          <span className="bar" />
                                          <label htmlFor="weekly_max_day">{T.t('gen_weekly_max_day')}</label>
                                        </div>
                                      </div> :
                                      props.values.selected_type_ids === 12 ?
                                        <div className="col-md-8">
                                          <div className="col-md-6 form-input form-group with-icon">
                                            <input
                                              id="contractual_workload"
                                              name="contractual_workload"
                                              value={props.values.contractual_workload}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              type="number"
                                              required
                                            />
                                            <span className="highlight" />
                                            <span className="bar" />
                                            <label htmlFor="contractual_workload">{T.t('gen_contractual_workload')}</label>
                                          </div>
                                        </div> :
                                        props.values.selected_type_ids === 4 && this.props.term_type === 1 ?
                                          <div className="col-md-8">
                                            <div className="add-custom-tag mb-3">
                                              <div className="react-select-container">
                                                <label>{T.t('gen_invigilation_level')}</label>
                                                <Select
                                                  className="react-select"
                                                  isMulti={false}
                                                  filterOption={(option: any, query: any) =>
                                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                  }
                                                  closeMenuOnSelect={true}
                                                  options={InvigilatorLevels(T)}
                                                  placeholder={T.t('gen_select_level')}
                                                  value={
                                                    props.values.invigilator_level != null
                                                      ? InvigilatorLevels(T).find(
                                                        (degree: Types.ISelectOption) =>
                                                          degree.value === props.values.invigilator_level
                                                      )
                                                      : null
                                                  }
                                                  onChange={(option: any) => props.setFieldValue('invigilator_level', option.value)}
                                                  noOptionsMessage={(): string => T.t('gen_select_no_level')}
                                                />
                                              </div>
                                            </div>
                                          </div> :
                                          props.values.selected_type_ids === 5 && this.props.term_type === 1 ?
                                            <div className="col-md-8">
                                              <div className="add-custom-tag mb-3">
                                                <div className="react-select-container">
                                                  <label>{T.t('gen_invigilation_campuses')}</label>
                                                  <Select
                                                    className="react-select"
                                                    isMulti={true}
                                                    filterOption={(option: any, query: any) =>
                                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                    }
                                                    closeMenuOnSelect={false}
                                                    options={
                                                      this.props.selectOptions && this.props.selectOptions.campuses
                                                        ? this.props.selectOptions.campuses
                                                        : []
                                                    }
                                                    placeholder={T.t('gen_select_location')}
                                                    value={props.values.invigilation_campuses}
                                                    onChange={(
                                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                    ) => {
                                                      const list: Array<Types.ISelectOption> = options
                                                        ? (options as Array<Types.ISelectOption>)
                                                        : [];
                                                      props.setFieldValue('invigilation_campuses', list);
                                                      props.setFieldValue(
                                                        'invigilation_campus_ids',
                                                        list.map((item) => item.value)
                                                      );
                                                    }}
                                                    noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                                  />
                                                </div>
                                              </div>
                                            </div> :
                                            props.values.selected_type_ids === 6 && this.props.term_type === 1 ?
                                              <div>
                                                <div className="col-md-8">
                                                  <div className="add-custom-tag mb-3">
                                                    <div className="react-select-container">
                                                      <label>{T.t('gen_invigilation_faculties_colleges_institutes')}</label>
                                                      <Select
                                                        className="react-select"
                                                        isMulti={true}
                                                        filterOption={(option: any, query: any) =>
                                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                        }
                                                        closeMenuOnSelect={false}
                                                        options={
                                                          this.props.selectOptions && this.props.selectOptions.faculties
                                                            ? this.props.selectOptions.faculties
                                                            : []
                                                        }
                                                        placeholder={T.t('gen_select_location')}
                                                        value={props.values.invigilation_faculties}
                                                        onChange={(
                                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                        ) => {
                                                          const list: Array<Types.ISelectOption> = options
                                                            ? (options as Array<Types.ISelectOption>)
                                                            : [];
                                                          props.setFieldValue('invigilation_faculties', list);
                                                          props.setFieldValue('invigilation_faculty_ids', list.map((item) => item.value));
                                                          this.getProgramsByFacultiesAtInstructors(list.map((item) => item.value));
                                                        }}
                                                        noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-md-8">
                                                  <div className="add-custom-tag mb-3">
                                                    <div className="react-select-container">
                                                      <label>{T.t('gen_invigilation_programs')}</label>
                                                      <Select
                                                        className="react-select"
                                                        isMulti={true}
                                                        filterOption={(option: any, query: any) =>
                                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                        }
                                                        closeMenuOnSelect={false}
                                                        options={[{ label: T.t('gen_select_all'), value: -1 }, ...this.props.programs_related_faculty!]}
                                                        placeholder={T.t('gen_select_location')}
                                                        value={invigilationProgramSelector ? props.values.invigilation_programs : this.props.programs_related_faculty}
                                                        onChange={(
                                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                        ) => {
                                                          const list: Array<Types.ISelectOption> = options
                                                            ? (options as Array<Types.ISelectOption>)
                                                            : [];
                                                          props.setFieldValue('invigilation_programs',
                                                            list.map(item => item.value).includes(-1) ? this.props.programs_related_faculty : list
                                                          );
                                                          props.setFieldValue(
                                                            'invigilation_program_ids',
                                                            list.map(item => item.value).includes(-1) ? this.props.programs_related_faculty.map((item: any) => item.value) : list.map(item => item.value)
                                                          );
                                                          invigilationProgramSelector = true;
                                                        }}
                                                        noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div> :
                                              props.values.selected_type_ids === 7 && this.props.term_type === 1 ?
                                                <div className="col-md-8 form-input form-group with-icon">
                                                  <input
                                                    id="max_invigilation_duty"
                                                    name="max_invigilation_duty"
                                                    value={props.values.max_invigilation_duty}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="number"
                                                  />
                                                  <span className="highlight" />
                                                  <span className="bar" />
                                                  <label htmlFor="max_invigilation_duty">{T.t('gen_max_invigilation_count')}</label>
                                                </div> :
                                                props.values.selected_type_ids === 9 && this.props.term_type === 1 ?
                                                  <div className="col-md-8 form-input form-group with-icon">
                                                    <input
                                                      id="max_time_bw_consecutive_invigilations"
                                                      name="max_time_bw_consecutive_invigilations"
                                                      value={props.values.max_time_bw_consecutive_invigilations}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      type="number"
                                                    />
                                                    <span className="highlight" />
                                                    <span className="bar" />
                                                    <label htmlFor="max_time_bw_consecutive_invigilations">{T.t('gen_max_time_bw_consecutive_invigilations')}</label>
                                                  </div> :
                                                  props.values.selected_type_ids === 10 && this.props.term_type === 1 ?
                                                    <div className="col-md-8 form-input form-group with-icon">
                                                      <input
                                                        id="daily_max_duty"
                                                        name="daily_max_duty"
                                                        value={props.values.daily_max_duty}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="number"
                                                      />
                                                      <span className="highlight" />
                                                      <span className="bar" />
                                                      <label htmlFor="daily_max_duty">{T.t('gen_daily_max_duty')}</label>
                                                    </div> :
                                                    props.values.selected_type_ids === 8 && this.props.term_type === 1 ?
                                                      <div>
                                                        <div className="col-md-8">
                                                          <div className="add-custom-tag mb-3">
                                                            <div className="react-select-container">
                                                              <label>{T.t('gen_faculty_of_duty')}</label>
                                                              <Select
                                                                className="react-select"
                                                                isMulti={false}
                                                                filterOption={(option: any, query: any) =>
                                                                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                                }
                                                                closeMenuOnSelect={true}
                                                                options={
                                                                  this.props.selectOptions && this.props.selectOptions.faculties
                                                                    ? this.props.selectOptions.faculties
                                                                    : []
                                                                }
                                                                placeholder={T.t('gen_select_faculty_college_institute')}
                                                                value={props.values.faculty_of_duty}
                                                                onChange={(option: any) => {
                                                                  props.setFieldValue('faculty_of_duty', option);
                                                                  props.setFieldValue('faculty_of_duty_id', option && option.value);
                                                                  this.getProgramsByFacultiesAtInstructors([option && option.value]);
                                                                }}
                                                                noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                          <div className="add-custom-tag mb-3">
                                                            <div className="react-select-container">
                                                              <label>{T.t('gen_program_department_of_duty')}</label>
                                                              <Select
                                                                className="react-select"
                                                                isMulti={false}
                                                                filterOption={(option: any, query: any) =>
                                                                  option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                                }
                                                                closeMenuOnSelect={true}
                                                                options={this.props.programs_related_faculty}
                                                                value={props.values.program_of_duty}
                                                                placeholder={T.t('gen_select_program_department')}
                                                                onChange={(option: any) => {
                                                                  props.setFieldValue('program_of_duty', option);
                                                                  props.setFieldValue('program_of_duty_id', option && option.value);
                                                                }}
                                                                noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div> : null
                          }
                          {
                            props.values.selected_type_ids !== undefined ?
                              <div className="col-6 pt-3 pb-3">
                                <button
                                  type="button"
                                  className="btn btn-green"
                                  onClick={() => props.handleSubmit()}
                                >
                                  {T.t('gen_change')}
                                </button>
                              </div> : null
                          }
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IInstructorPageProps): Types.IInstructorPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IInstructorPageProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.instructorPage,
    term_type: store.state.term_type,
    term_id: store.state.term_id,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ChangeAllDataModal);

export default container;
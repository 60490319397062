import { UserRole } from '../store/constants/enums';
import * as Types from '../store/types';


export const allRestirectedRoles: string[] = [
    UserRole.INSRUCTOR_RESTRICTED,
    UserRole.MANAGER_RESTRICTED,
    UserRole.PANEL_RESTRICTED
];

export const instructorAndManagerRoles: string[] = [
    UserRole.INSTRUCTOR,
    UserRole.INSRUCTOR_RESTRICTED,
    UserRole.MANAGER,
    UserRole.MANAGER_RESTRICTED
]

export const managerRoles: string[] = [
    UserRole.MANAGER,
    UserRole.MANAGER_RESTRICTED
]

export const instructorRoles: string[] = [
    UserRole.INSTRUCTOR,
    UserRole.INSRUCTOR_RESTRICTED,
]

export const isUserEquals = (user: Types.IAuthUser | undefined, roleList: string[] | string) => {
    if (!user) return false;

    if (typeof roleList === "string") {
        return roleList === user.role;
    }

    return roleList.includes(user.role);
}
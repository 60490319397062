import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Switch from 'react-switch';
import { Table } from 'reactstrap';
import XLSX from 'xlsx';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import Spinner from '../templates/spinner';
import { ExcelImportKeys } from './import-modal';
import { ImportingKeySelectOptions } from '../../store/constants/faculty-const';
import Translator, { Language } from '../../services/translate-factory';
import { formatExcel } from './format';
import { facultyExcelMapping } from './format/column/merged';
import { setExcelTemplateDir } from './util';
import Warning from '../warning';

const T = Translator.create();

const SheetJSFT = ['xlsx', 'xls', 'csv', 'ods']
  .map(function (x) {
    return '.' + x;
  })
  .join(',');

class FacultyImportForm extends React.Component<Types.IImportFormProps, Types.IImportFormState> {
  modalName = ExcelImportKeys.Faculties;
  craeteExcelBulkAction = Constants.faculty.FACULTY_CREATE_EXCEL_BULK;
  state: Types.IImportFormState = {
    options: {
      overrideData: false,
      importingKey: 'FACULTY_CODE',
      termId: this.props.term_id ? this.props.term_id : -1
    },
    acceptedEntries: [],
    rejectedEntries: []
  };

  constructor(props: any) {
    super(props)

    this.state.exampleExcelFileName = setExcelTemplateDir(Translator.create(), [
      {
        language: Language.TR,
        dir: 'Example_Faculty_Import.xlsx'
      },
      {
        language: Language.EN,
        dir: "en/Faculty_Building_Data_Upload_Template.xlsx"
      },
    ])
  }

  componentWillUnmount() {
    this.props.dispatch(
      Actions.ApiRequest(this.craeteExcelBulkAction, {
        reset: true
      })
    );
  }

  post = () => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status === 500) {
        this.props.dispatch(Actions.ShowModal({
          title: T.t('gen_error'),
          body: <h6>{T.t('notification_excel_delete_cannot_be_performed')}</h6>,
          name: this.modalName + '_error',
          icon: 'error_outline',
          iconColor: 'red',
        }));
        this.props.dispatch(Actions.ShowModal({
          name: this.modalName,
          cancel: T.t('gen_close'),
          confirm: T.t('gen_upload_file')
        }));
      } else if (status === 200 || status === 409) {
        this.props.dispatch(
          Actions.ShowModal({
            name: this.modalName,
            confirm: T.t('gen_download_result_as_excel'),
            cancel: T.t('gen_close'),
            onConfirm: this.props.apiResultTableToExcel,
            buttonPosition: 1
          })
        );
        if (this.props.onImport) {
          this.props.onImport();
        }
      } else {
        this.props.dispatch(
          Actions.ShowModal({
            name: this.modalName,
            body: <h6>{T.t('gen_unexpected_error_has_occurred_please_check_your_data')}</h6>,
            cancel: T.t('gen_close')
          })
        );
      }
      this.props.dispatch(
        Actions.ApiRequest(this.craeteExcelBulkAction, {
          reset: true
        })
      );
    };

    if (this.state.rejectedEntries.length === 0) {
      let postModel: Types.IImportPost = {
        options: this.state.options,
        items: this.state.acceptedEntries
      };
      this.props.dispatch(
        Actions.ApiRequest(this.craeteExcelBulkAction, postModel, this.modalName + '-spinner', resultCallback)
      );
      this.props.dispatch(Actions.ShowModal({ name: this.modalName, cancel: T.t('gen_close') }));
    }
  };

  excelToJSON = (file: File) => {
    let reader = new FileReader();
    reader.onload = (e: any) => {
      const data = formatExcel<Types.IExcelFacultyRow>(e, facultyExcelMapping);
      console.log("data:", data)
      this.state.acceptedEntries = data;
      this.state.rejectedEntries = [];
      if (this.state.acceptedEntries.length > 0) {
        this.post();
      }
    };
    reader.readAsBinaryString(file);
  };

  JSONToExcel = (data: any) => {
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'import_report');
    XLSX.writeFile(wb, 'report.xlsx');
  };

  onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      this.excelToJSON(files[0]);
    }
    e.currentTarget.value = '';
  };

  translateImportingResult(result: string): string {
    let message = '';
    switch (result) {
      case 'rejected':
        message = T.t('gen_error_main');
        break;
      case 'added':
        message = T.t('gen_added');
        break;
      case 'updated':
        message = T.t('gen_updated');
        break;
    }

    return message;
  }

  render() {
    let formOptions = null;
    let apiResultTable = null;
    let validationErrorsTable = null;
    let items = this.props.results;
    let options = this.props.options;

    if (this.state.rejectedEntries.length > 0) {
      let validationErrorRow = this.state.rejectedEntries.map(r => {
        return (
          <tr key={'importing-error-' + r.RowIndex}>
            <td>{'#' + (r.RowIndex + 1)}</td>
            <td>
              {r.ValidationErrors.map(v => {
                return (
                  <Fragment>
                    {(v.Field ? v.Field + ': ' : 'GENEL: ') + v.Message} <br />
                  </Fragment>
                );
              })}
            </td>
          </tr>
        );
      });

      validationErrorsTable = (
        <div className="mt-4" style={{ fontSize: '14px' }}>
          <Table responsive striped bordered size="sm">
            <thead>
              <tr>
                <th style={{ width: '15%' }}>{T.t('gen_line_number')}#</th>
                <th style={{ width: '85%' }}>{T.t('gen_error')}</th>
              </tr>
            </thead>
            <tbody>{validationErrorRow}</tbody>
          </Table>
        </div>
      );
    }

    if (items) {
      let rows = null;

      rows = items
        .sort((a, b) => (a.model.importingResult === 'rejected' || b.model.importingResult === 'updated' ? -1 : 1))
        .map((item: Types.IMultipleResponseItem<Types.IImportedFaculty>) => {
          let { model, state } = item;
          let importingKeyObject =
            options && ImportingKeySelectOptions(T).find((item: Types.ISelectOption) => item.value == (options ? options.importingKey : ''));
          return (
            <tr key={this.modalName + '-' + model.facultyCode} hidden={!(model.importingResult === 'rejected')}>
              <td>{this.translateImportingResult(model.importingResult)}</td>
              <td>{importingKeyObject ? importingKeyObject.label : ''}</td>
              <td>{model.facultyCode}</td>
              <td>{model.facultyName}</td>
              <td>{model.description}</td>
              <td>
                {state
                  ? Array.isArray(state.details)
                    ? state.details.map(error => {
                      return <label>{error.field + ': ' + error.message}</label>;
                    })
                    : state.details
                  : ''}
              </td>
            </tr>
          );
        });

      apiResultTable = (
        <div className="small mt-2">
          <h6>
            {T.t('gen_records_successfully_added_updated').replace('{0}', this.state.acceptedEntries.length).replace('{1}',
              items.filter(item => item.model.importingResult !== 'rejected').length)}
          </h6>
          <Table
            id="api-result-table"
            className="mt-3"
            responsive
            striped
            bordered
            size="sm"
            hidden={items.findIndex(item => item.model.importingResult === 'rejected') < 0}
          >
            <thead>
              <tr>
                <th>{T.t('gen_result')}</th>
                <th>{T.t('gen_key_data')}</th>
                <th>{T.t('gen_faculty_college_code')}</th>
                <th>{T.t('gen_faculty_college_name')}</th>
                <th>{T.t('gen_description')}</th>
                <th>{T.t('gen_error_message')}</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>
      );
    } else {
      formOptions = (
        <div className="row">
          <Spinner name={this.modalName + '-spinner'} />
          <div className="col-12 col-md-12 col-sm-12">
            <p>
              <a href={process.env.PUBLIC_URL + '/files/' + this.state.exampleExcelFileName}> {T.t("excel_example_excel_file")} </a>
            </p>
          </div>
          <div className="col-12 col-md-12 col-sm-12">
            <div className="react-select-container">
              <label>{T.t("excel_base_information")}</label>
              <Select
                className="react-select"
                isSearchable={false}
                options={ImportingKeySelectOptions(T)}
                value={
                  this.state.options.importingKey ? ImportingKeySelectOptions(T).find(k => k.value === this.state.options.importingKey) : null
                }
                onChange={(item: any) => {
                  this.state.options.importingKey = item.value;
                  this.setState(this.state);
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-sm-12">
            {this.props.user && this.props.user.role === 's' ? (
              <div className="react-switch-container">
                <label>{T.t('gen_override_data')}</label>
                <Switch
                  id="send_email"
                  className="react-switch"
                  onChange={(checked: boolean) => {
                    this.state.options.overrideData = checked;
                    this.setState(this.state);
                    if (checked) this.props.dispatch(Actions.Notification('notification_override_faculties', 'gen_warning', 'danger'));
                  }}
                  checked={this.state.options.overrideData}
                />
              </div>
            ) : null}
          </div>
          <div className='col-md-12'>
            <Warning
              show
              warningKey={this.state.options.importingKey}
              warnings={{
                'FACULTY_CODE': T.t('gen_excel_merge_warning', { key: T.t('gen_faculty_school_code'), items: T.t('gen_faculties') }),
                'NONE': T.t('gen_do_not_check_warning')
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {formOptions}
        {validationErrorsTable}
        {apiResultTable}
        <input id="excel_file_input" type="file" className="d-none form-control" accept={SheetJSFT} onChange={this.onFileSelected} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IImportFormProps): Types.IImportFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IImportFormProps = Object.assign({}, ownProps, {
    results: store.state.faculty_import_result && store.state.faculty_import_result.items,
    options: store.state.faculty_import_result && store.state.faculty_import_result.options,
    term_id: store.state.term_id,
    user: store.state.user
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return next.state.faculty_import_result === prev.state.faculty_import_result;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(FacultyImportForm);

export default container;

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Switch from 'react-switch';
import { Table } from 'reactstrap';
import XLSX from 'xlsx';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import Spinner from '../templates/spinner';
import { ExcelImportKeys } from './import-modal';
import { ImportingKeyActivitySelectOptions, ImportingKeySelectOptions } from '../../store/constants/course-const';
import Translator, { Language } from '../../services/translate-factory';
import { formatExcel } from './format';
import { courseCourseActivityExcelMapping } from './format/column/merged';
import { setExcelTemplateDir } from './util';
import Warning from '../warning';

const T = Translator.create();

const SheetJSFT = ['xlsx', 'xls', 'csv', 'ods']
  .map(function (x) {
    return '.' + x;
  })
  .join(',');

let isWrongProgramCode: boolean;

class CourseImportForm extends React.Component<Types.IImportFormProps, Types.IImportFormState> {
  modalName = ExcelImportKeys.Courses;
  craeteExcelBulkAction = (this.props.term_type === 1 ? Constants.course.COURSE_CREATE_EXCEL_BULK : Constants.activity.ACTIVITY_CREATE_EXCEL_BULK);

  state: Types.IImportFormState = {
    options: {
      overrideData: false,
      importingKey: 'NONE',
      importingKeyNone: 'NONE',
      termId: this.props.term_id ? this.props.term_id : -1
    },
    acceptedEntries: [],
    rejectedEntries: [],
    exampleExcelFileName: ""
  };

  constructor(props: any) {
    super(props)

    this.state.exampleExcelFileName = setExcelTemplateDir(T, [
      {
        language: Language.TR,
        dir: this.props.term_type === 1 ? 'Example_Course_Import.xlsx' : 'Example_CourseTerm_Course_Import.xlsx'
      },
      {
        language: Language.EN,
        dir: "en/CourseTerm_Course_Activity_Data_Upload_Template.xlsx"
      },
    ])
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.props.dispatch(
      Actions.ApiRequest(this.craeteExcelBulkAction, {
        reset: true
      })
    );
  }
  check500Error = (result: Types.IApiErrorResponse, status: number) => {
    if (status === 500) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_error'),
          body: (
            <h6>
              {T.t('notification_excel_delete_cannot_be_performed')}
            </h6>
          ),
          name: this.modalName + '_error',
          icon: 'error_outline',
          iconColor: 'red'
        })
      );
      this.props.dispatch(
        Actions.ShowModal({
          name: this.modalName,
          cancel: T.t('gen_close'),
          confirm: T.t('gen_upload_file')
        })
      );
    }
  }

  check200and409Error = (result: Types.IApiErrorResponse, status: number) => {
    if (status === 200 || status === 409) {
      this.props.dispatch(
        Actions.ShowModal({
          name: this.modalName,
          confirm: T.t('gen_download_result_as_excel'),
          cancel: T.t('gen_close'),
          onConfirm: this.props.apiResultTableToExcel,
          buttonPosition: 1
        })
      );
      if (this.props.onImport) {
        this.props.onImport();
      }
    }
  }

  checkUnexpectedError = (result: Types.IApiErrorResponse, status: number) => {
    if (this.isUnexpectedError(status)) {
      this.props.dispatch(
        Actions.ShowModal({
          name: this.modalName,
          body: <h6>{T.t('gen_unexpected_error_has_occurred_please_check_your_data')}</h6>,
          cancel: T.t('gen_close')
        })
      );
    }
  }

  isUnexpectedError = (status: number) => status !== 200 && status !== 409 && status !== 500;

  post = () => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      this.check500Error(result, status);
      this.check200and409Error(result, status);
      this.checkUnexpectedError(result, status);

      this.props.dispatch(
        Actions.ApiRequest(this.craeteExcelBulkAction, {
          reset: true
        })
      );
    }
    if (this.props.term_type == 1) {
      isWrongProgramCode = this.state.acceptedEntries.some((item: any) => {
        if ((item.programCode && item.programCode.split('-')[1]) && (item.programCode && item.programCode.split('-')[1]).split(' ')[0] !== (item.class !== '10' ? ('0' + item.class) : item.class)) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (isWrongProgramCode) {
      this.props.dispatch(Actions.Notification('Lütfen program kodu parametreyi doğru formata çevirin.', 'gen_warning', 'danger'))
    } else {
      if (this.state.rejectedEntries.length === 0) {
        let postModel: Types.IImportPost = {
          options: this.state.options,
          items: this.state.acceptedEntries
        };
        this.props.dispatch(
          Actions.ApiRequest(this.craeteExcelBulkAction, postModel, this.modalName + '-spinner', resultCallback)
        );
        this.props.dispatch(Actions.ShowModal({ name: this.modalName, cancel: T.t('gen_close') }));
      }
    }
  };

  excelToJSON = (file: File) => {
    let reader = new FileReader();

    reader.onload = (e: any) => {
      // term type 0 -> ders donemi, 1 -> sinav donemi
      const data = formatExcel<Types.IExcelCourseRow>(e, courseCourseActivityExcelMapping)

      this.state.acceptedEntries = data;
      this.state.rejectedEntries = [];
      if (this.state.acceptedEntries.length > 0) {
        this.post();
      }
    };
    reader.readAsBinaryString(file);
  };

  JSONToExcel = (data: any) => {
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'import_report');
    XLSX.writeFile(wb, 'report.xlsx');
  };

  onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      this.excelToJSON(files[0]);
    }
    e.currentTarget.value = '';
  };

  translateImportingResult(result: string): string {
    let message = '';
    switch (result) {
      case 'rejected':
        message = T.t('gen_error_main');
        break;
      case 'added':
        message = T.t('gen_added');
        break;
      case 'updated':
        message = T.t('gen_updated');
        break;
    }

    return message;
  }

  render() {

    let formOptions = null;
    let apiResultTable = null;
    let validationErrorsTable = null;
    let items = this.props.results;
    let options = this.props.options;

    if (this.state.rejectedEntries.length > 0) {
      let validationErrorRow = this.state.rejectedEntries.map((r) => {
        return (
          <tr key={'importing-error-' + r.RowIndex}>
            <td>{'#' + (r.RowIndex + 1)}</td>
            <td>
              {r.ValidationErrors.map((v) => {
                return (
                  <Fragment>
                    {(v.Field ? v.Field + ': ' : 'GENEL: ') + v.Message} <br />
                  </Fragment>
                );
              })}
            </td>
          </tr>
        );
      });

      validationErrorsTable = (
        <div className="mt-4" style={{ fontSize: '14px' }}>
          <Table responsive striped bordered size="sm">
            <thead>
              <tr>
                <th style={{ width: '15%' }}>{T.t('gen_line_number')}#</th>
                <th style={{ width: '85%' }}>{T.t('gen_error')}</th>
              </tr>
            </thead>
            <tbody>{validationErrorRow}</tbody>
          </Table>
        </div>
      );
    }

    if (items) {
      let rows = null;

      rows = items
        .sort((a, b) => (a.model.importingResult === 'rejected' || b.model.importingResult === 'updated' ? -1 : 1))
        .map((item: Types.IMultipleResponseItem<Types.IImportedCourse>) => {
          let { model, state } = item;
          let importingKeyObject =
            options &&
            ImportingKeySelectOptions(T).find(
              (item: Types.ISelectOption) => item.value == (options ? options.importingKey : '')
            );
          let importingKeyActivityObject =
            options &&
            ImportingKeyActivitySelectOptions(T).find(
              (item: Types.ISelectOption) => item.value == (options ? options.importingKey : '')
            );
          return (
            <tr key={this.modalName + '-' + model.courseCode} hidden={!(model.importingResult === 'rejected')}>
              <td>{this.translateImportingResult(model.importingResult)}</td>
              <td>{this.props.term_type === 0 ? importingKeyActivityObject ? importingKeyActivityObject.label : '' : importingKeyObject ? importingKeyObject.label : ''}</td>
              {this.props.term_type === 0 ? (
                <React.Fragment>
                  <td>{model.activityNo}</td>
                  <td>{model.courseCode}</td>
                  <td>{model.courseName}</td>
                  <td>{model.activityType}</td>
                  <td>{model.lessonCount}</td>
                  <td>{model.lectureLocationCode}</td>
                  <td>{model.educationType}</td>
                  <td>{model.facultyCode}</td>
                  <td>{model.programCode}</td>
                  <td>{model.class}</td>
                  <td>{model.section}</td>
                  <td>{model.lectureStaffCodes}</td>
                  <td>{model.studentCount}</td>
                  <td>{model.week}</td>
                  <td>{model.courseType}</td>
                  <td>{model.courseEnvironment}</td>
                  <td>{model.classroomTypeName}</td>
                  <td>{model.courseLanguageName}</td>
                  <td>{model.packageName}</td>
                  <td>{model.description}</td>
                  <td>{model.status}</td>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <td>{model.courseId}</td>
                  <td>{model.courseCode}</td>
                  <td>{model.conjugateCourseCode}</td>
                  <td>{model.courseName}</td>
                  <td>{model.campusCode}</td>
                  <td>{model.facultyCode}</td>
                  <td>{model.programCodes}</td>
                  <td>{model.class}</td>
                  <td>{model.section}</td>
                  <td>{model.lectureStaffCodes}</td>
                  <td>{model.studentCount}</td>
                  <td>{model.assistantStaffCodes}</td>
                  <td>{model.examDuration}</td>
                  <td>{model.examSession}</td>
                  <td>{model.invigilatorGapBeforeExam}</td>
                  <td>{model.invigilatorGapAfterExam}</td>
                  <td>{model.classroomCombineStatus}</td>
                  <td>{model.examType}</td>
                  <td>{model.examEnvironment}</td>
                  <td>{model.classroomTypeName}</td>
                  <td>{model.courseLanguageName}</td>
                  <td>{model.description}</td>
                  <td>{model.status}</td>
                  <td>{model.combinedExamCode}</td>
                </React.Fragment>
              )}
              <td>
                {state
                  ? Array.isArray(state.details)
                    ? state.details.map((error) => {
                      return <label>{error.field + ': ' + error.message}</label>;
                    })
                    : state.details
                  : ''}
              </td>
            </tr>
          );
        });

      apiResultTable = (
        <div className="small mt-2">
          <h6>
            {T.t('gen_records_successfully_added_updated').replace('{0}', this.state.acceptedEntries.length).replace('{1}',
              items.filter(item => item.model.importingResult !== 'rejected').length)}
          </h6>
          <Table
            id="api-result-table"
            className="mt-3"
            responsive
            striped
            bordered
            size="sm"
            hidden={items.findIndex((item) => item.model.importingResult === 'rejected') < 0}
          >
            <thead>
              <tr>
                <th>{T.t('gen_result')}</th>
                <th>{T.t('gen_key_data')}</th>
                {this.props.term_type === 0 ? (
                  <React.Fragment>
                    <th>{T.t('gen_activity_no')}</th>
                    <th>{T.t('gen_course_code')}</th>
                    <th>{T.t('gen_course_name')}</th>
                    <th>{T.t('gen_activity_type')}</th>
                    <th>{T.t('gen_lesson_count')}</th>
                    <th>{T.t('gen_lesson_location')}</th>
                    <th>{T.t('gen_education_type')}</th>
                    <th>{T.t('gen_faculty_college_institute')}</th>
                    <th>{T.t('gen_department_program_code')}</th>
                    <th>{T.t('gen_grade')}</th>
                    <th>{T.t('gen_section')}</th>
                    <th>{T.t('gen_instructors_code')}</th>
                    <th>{T.t('gen_student_count')}</th>
                    <th>{T.t('gen_week')}</th>
                    <th>{T.t('gen_course_type')}</th>
                    <th>{T.t('gen_course_environment')}</th>
                    <th>{T.t('gen_classroom_type')}</th>
                    <th>{T.t('gen_course_language')}</th>
                    <th>{T.t('gen_package_name')}</th>
                    <th>{T.t('gen_description')}</th>
                    <th>{T.t('gen_status')}</th>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <th>{T.t('gen_course_no')}</th>
                    <th>{T.t('gen_course_code')}</th>
                    <th>{T.t('gen_conjugate_course_code')}</th>
                    <th>{T.t('gen_course_name')}</th>
                    <th>{T.t('gen_campus_code')}</th>
                    <th>{T.t('gen_faculty_college_code')}</th>
                    <th>{T.t('gen_program_code')}</th>
                    <th>{T.t('gen_grade_level')}</th>
                    <th>{T.t('gen_section')}</th>
                    <th>{T.t('gen_course_instructor_code')}</th>
                    <th>{T.t('gen_number_students_will_take_exam')}</th>
                    <th>{T.t('gen_course_assistant_instructor_code')}</th>
                    <th>{T.t('gen_exam_duration')}</th>
                    <th>{T.t('gen_exam_session')}</th>
                    <th>{T.t('gen_invigilator_gap_before_exam')}</th>
                    <th>{T.t('gen_invigilator_gap_after_exam')}</th>
                    <th>{T.t('gen_classroom_combine_status')}</th>
                    <th>{T.t('gen_exam_type')}</th>
                    <th>{T.t('gen_exam_environment')}</th>
                    <th>{T.t('gen_classroom_type')}</th>
                    <th>{T.t('gen_course_language')}</th>
                    <th>{T.t('gen_description')}</th>
                    <th>{T.t('gen_status')}</th>
                    <th>{T.t('gen_combined_exam_code')}</th>
                  </React.Fragment>
                )}
                <th>{T.t('gen_error_message')}</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>
      );
    } else {
      formOptions = (
        <div className="row">
          <Spinner name={this.modalName + '-spinner'} />
          <div className="col-12 col-md-12 col-sm-12">
            <p>
              <a href={process.env.PUBLIC_URL + '/files/' + this.state.exampleExcelFileName}> {T.t("excel_example_excel_file")} </a>
            </p>
          </div>
          <div className="col-12 col-md-12 col-sm-12">
            <div className="react-select-container">
              <label>{T.t("excel_base_information")}</label>
              <Select
                className="react-select"
                isSearchable={false}
                options={this.props.term_type === 0 ? ImportingKeyActivitySelectOptions(T) : ImportingKeySelectOptions(T)}
                value={
                  this.props.term_type === 0 ? (
                    this.state.options.importingKey
                      ? ImportingKeyActivitySelectOptions(T).find((k) => k.value === this.state.options.importingKey)
                      : null
                  ) : (
                    this.state.options.importingKey
                      ? ImportingKeySelectOptions(T).find((k) => k.value === this.state.options.importingKey)
                      : null
                  )
                }
                onChange={(item: any) => {
                  this.state.options.importingKey = item.value;
                  this.setState(this.state);
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-sm-12">
            {this.props.user && this.props.user.role === 's' ? (
              <div className="react-switch-container">
                <label>{T.t('gen_override_data')}</label>
                <Switch
                  id="send_email"
                  className="react-switch"
                  onChange={(checked: boolean) => {
                    this.state.options.overrideData = checked;
                    this.setState(this.state);
                    if (checked) this.props.dispatch(Actions.Notification('notification_override_courses', 'gen_warning', 'danger'));
                  }}
                  checked={this.state.options.overrideData}
                />
              </div>
            ) : null}
          </div>
          <div className='col-md-12'>
            <Warning
              show
              warningKey={this.state.options.importingKey}
              warnings={{
                'ACTIVITY_NO': T.t('gen_excel_merge_warning', { key: T.t('gen_activity_number'), items: T.t('gen_activities') }),
                'COURSE_ID': T.t('gen_excel_merge_warning', { key: T.t('gen_course_number'), items: T.t('gen_courses') }),
                'NONE': T.t('gen_do_not_check_warning')
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {formOptions}
        {validationErrorsTable}
        {apiResultTable}
        <input id="excel_file_input" type="file" className="d-none form-control" accept={SheetJSFT} onChange={this.onFileSelected} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IImportFormProps): Types.IImportFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IImportFormProps = Object.assign({}, ownProps, {
    results: store.state.course_import_result && store.state.course_import_result.items,
    options: store.state.course_import_result && store.state.course_import_result.options,
    term_id: store.state.term_id,
    term_type: store.state.term_type,
    user: store.state.user
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return next.state.course_import_result === prev.state.course_import_result;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseImportForm);

export default container;

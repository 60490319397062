import { constants } from '../constants/sketch-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

const sketchReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case constants.SKETCH_CAMPUS_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          sketch_page: {
            ...state.sketch_page,
            campus_results: result.content ? result.content.data : [],
            campus_filters: result.content.filters
          }
        };
      }
      return state;
    }
    case constants.SKETCH_BUILDING_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          sketch_page: {
            ...state.sketch_page,
            building_results: result.content ? result.content.data : [],
            building_filters: result.content.filters
          }
        };
      }
      return state;
    }
    case constants.SKETCH_CAMPUS_GET_BY_ID: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          sketch_page: {
            ...state.sketch_page,
            campus_form: result.content.data
          }
        });
      }
      return state;
    }
    case constants.SKETCH_BUILDING_GET_BY_ID: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          sketch_page: {
            ...state.sketch_page,
            building_form: result.content.data
          }
        });
      }
      return state;
    }
    case constants.SKETCH_GET_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            sketchPage: result.content
          }
        });
      }
      return state;
    }
    case constants.SKETCH_PUBLIC_CAMPUS_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          sketch_page: {
            ...state.sketch_page,
            public_campus_results: result.content ? result.content.data : [],
            public_campus_filters: result.content.filters
          }
        };
      }
      return state;
    }
    case constants.SKETCH_PUBLIC_BUILDING_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          sketch_page: {
            ...state.sketch_page,
            public_building_results: result.content ? result.content.data : [],
            public_building_filters: result.content.filters
          }
        };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default sketchReducer;

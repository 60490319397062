import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { ValueType } from 'react-select/lib/types';
import { BackupSearchInitialValues, BackupTypes } from '../../store/constants/backup-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import SortedColumn from '../../components/table/sorted-column';
import Paginate from '../../components/table/paginate';
import Translator from '../../services/translate-factory';
import BackupForm from './backup-form';

const T = Translator.create();
const L = Log.create('BackupPage');

class BackupPage extends Component<Types.IBackupPageProps, Types.IBackupPageState> {
  state: Types.IBackupPageState = {
    filters: BackupSearchInitialValues,
    filterIsOpen: false,
    backupFormIsOpen: false,
    all_ids: [],
    selected_ids: [],
    backupList: [],
    backup_id: undefined
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    window.scrollTo(0, 0);
    this.getBackupSelectOptions();
    this.searchBackups();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  searchBackups() {
    this.props.dispatch(
      Actions.ApiRequest(
        Constants.backup.BACKUP_LIST_SEARCH,
        this.state.filters,
        'backup-list-spin'
      )
    );
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchBackups();
  };

  getBackupSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.backup.BACKUP_GET_SELECT_OPTIONS, 'backup-list-spin'));
  }

  onPageChange = (page: number, size?: number) => {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        page: page,
        size: size ? size : 10
      }
    }), this.searchBackups)
  };

  onSizeChange = (size: number) => {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        size: size
      }
    }), this.searchBackups)
  }
  onFormReset = () => {
    this.state.filters = Object.assign({}, BackupSearchInitialValues);
    this.setState(this.state);
    this.searchBackups();
  };

  onFilterBackup(model: Types.IFilterBackup, FormActions: FormikActions<Types.IFilterBackup>) {
    this.setState(prev => ({
      ...prev,
      filterIsOpen: true,
      filters: {
        ...model,
        page: 1
      },
      selected_ids: [],
    }))
    this.searchBackups();
    FormActions.setSubmitting(false);
  }

  onRestoreBackup = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, backup_id: number) => {
    if (e && e.currentTarget.dataset && e.currentTarget.dataset.id) {
      let id = e.currentTarget.dataset.id;
      let name = e.currentTarget.dataset.name;
      const resultCallBack = () => {
        this.searchBackups()
      };
      const onConfirmRestore = () => {
        this.props.dispatch(
          Actions.ApiRequest(Constants.backup.BACKUP_RESTORE, backup_id, undefined, resultCallBack)
        );
      };
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_backup_will_be_restored'),
          body: T.t('gen_backup_will_be_restored_question'),
          name: 'restore_backup_',
          icon: 'error_outline',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_no'),
          onConfirm: onConfirmRestore
        })
      );
    }
  };

  onBackupDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, backup_ids: Array<number>) => {
    if (e && e.currentTarget.dataset && e.currentTarget.dataset.id) {
      let id = e.currentTarget.dataset.id;
      let name = e.currentTarget.dataset.name;
      const resultCallBack = () => {
        this.searchBackups()
      };
      const onConfirmDelete = () => {
        this.props.dispatch(
          Actions.ApiRequest(Constants.backup.BACKUP_DELETE, backup_ids, undefined, resultCallBack)
        );
      };
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_backup_will_be_deleted'),
          body: T.t('gen_backup_will_be_deleted_question'),
          name: '_backup_delete',
          icon: 'error_outline',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_no'),
          onConfirm: onConfirmDelete
        })
      );
    }
  };

  static getDerivedStateFromProps(props: Types.IBackupPageProps, state: Types.IBackupPageState) {
    let hasNewState: boolean = false;

    if (props.results) {
      hasNewState = true;
      state.backupList = props.results;
    }

    if (props.location && props.location.pathname == '/backup/create') {
      hasNewState = true;
      state.backupFormIsOpen = true;
      state.backup_id = undefined;
    } else {
      hasNewState = true;
      state.backupFormIsOpen = false;
      state.backup_id = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  backupFormOpen = () => {
    L.data("backup form", this.state, this.props);
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.BACKUPS, '/create')));
  };

  backupFormOnClose = (refresh: boolean) => {
    if (this.state.backupFormIsOpen) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.BACKUPS)));
    }
    if (refresh) {
      this.searchBackups();
    }
  };

  render() {
    let backupList = this.state.backupList ? this.state.backupList : [];
    let total_size = this.props.selectOptions ? this.props.selectOptions.total_size : '-';
    let size_limit = this.props.selectOptions ? this.props.selectOptions.backup_limit : '-';
    let limit_exceeded = this.props.selectOptions ? this.props.selectOptions.limit_exceeded : true;

    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="backup-page">
          <div className="main list-backup">
            <div className="container-fluid">
              <div className="row align-items-center mb-1">
                <div className="col-md-6 col-sm-4 col-12">
                  <h4 className="mb-4 mb-sm-0">{T.t('gen_backups')}</h4>
                </div>
              </div>
              <Spinner name="backup-list-spin" />
              <div className="white-container mt-4">
                <div className="row align-items-center mb-1">
                  <div className="col-md-6 col-sm-4 col-12">
                    <React.Fragment>
                      <button id='button_add_backup' className={"category-tag-square " + (limit_exceeded ? 'tag-red' : 'tag-green')} onClick={this.backupFormOpen} disabled={limit_exceeded}>
                        <i className="material-icons mr-2">add</i> {T.t('gen_add_backup')}
                      </button>
                    </React.Fragment>
                  </div>
                  <div className="col-md-6 col-sm-8 col-12 text-right">
                    <i className={"material-icons mr-2 " + (limit_exceeded ? 'tag-red' : 'tag-green')}>add_task</i>
                    {T.t('gen_total_size') + ": " + total_size + " / " + size_limit}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col" className="d-none d-lg-table-cell d-xl-table-cell text-center">
                          <span className="text-center">{"No"} </span>
                        </th>
                        <SortedColumn
                          datacell="type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_backup_type')}
                          sortkey="type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="d-none d-lg-table-cell d-xl-table-cell text-center">
                          <span className="text-center">{T.t('gen_description')} </span>
                        </th>
                        <SortedColumn
                          datacell="created_date"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_create_date')}
                          sortkey="created_date"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="file_size"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_file_size')}
                          sortkey="size"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="d-none d-lg-table-cell d-xl-table-cell text-center">
                          <span className="text-center">{T.t('gen_actions')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {backupList && backupList.length
                        ? backupList.map((item: Types.IBackupItem) => {
                          return (
                            <tr key={'backup-' + item.backup_id} data-title={item.description}>
                              <td data-label={"No"} className="text-center" >{item.no}</td>
                              <td scope="row" data-label={T.t('gen_backup_type')} className="text-center">
                                <div className="tags ml-1 mr-4">
                                  <button
                                    id='button_backup_type'
                                    className={
                                      `small-tag text-uppercase text-center` +
                                      (item.type == 0 ? ` tag-blue` : (item.type == 1 ? ` tag-orange` : ` tag-green`))
                                    }
                                  >
                                    {BackupTypes(T).find((x) => x.value === item.type)!.label}
                                  </button>
                                </div>
                              </td>
                              <td data-label={T.t('gen_description')} className="text-center">
                                {item.description && item.description.length ?
                                  <div className="table-scrollable-td">{item.description}</div> : '-'}
                              </td>
                              <td data-label={T.t('gen_create_date')} className="text-center" >{item.created_date}</td>
                              <td data-label={T.t('gen_file_size')} className="text-center">{(item.type == 1 ? "-" : item.backup_size)}</td>
                              <td data-label={T.t('gen_actions')} className="table-buttons">
                                <div className="table-buttons-wrapper">
                                  <button
                                    id='button_access_time'
                                    data-toggle="tooltip"
                                    data-id={item.backup_id}
                                    disabled={item && item.type == 1}
                                    onClick={(e) => this.onRestoreBackup(e, item.backup_id)}
                                    title={T.t('gen_restore_backup')}
                                    className="btn btn-light btn-sm table-button"
                                  >
                                    <span className="d-block" data-toggle="modal" data-target="#addNew">
                                      <i className="material-icons">access_time</i>
                                    </span>
                                  </button>
                                  <button
                                    id='button_delete'
                                    data-toggle="tooltip"
                                    data-id={item.backup_id}
                                    disabled={item && item.type != 0}
                                    onClick={(e) => this.onBackupDelete(e, [item.backup_id])}
                                    title={T.t('gen_delete')}
                                    className="btn btn-light btn-sm table-button"
                                  >
                                    <span className="d-block" data-toggle="modal" data-target="#coursepleModal">
                                      <i className="material-icons">delete</i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                        : null}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    >
                      {this.props.results && this.props.results.length > 0 && (
                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} onSizeChange={this.onSizeChange} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
        <BackupForm
          backupId={this.state.backup_id}
          formIsOpen={this.state.backupFormIsOpen}
          onClose={this.backupFormOnClose}
        />
      </MainLayout>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IBackupPageProps
): Types.IBackupPageProps => {
  if (!store) {
    return ownProps;
  }

  const newProps: Types.IBackupPageProps = Object.assign({}, ownProps, {
    results: store.state.backup_page && store.state.backup_page.results,
    filters: store.state.backup_page && store.state.backup_page.filters,
    selectOptions: store.state.select_options && store.state.select_options.backupsPage,
    user: store.state.user,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.backup_page) {
    return (
      !!equal(
        prev.state.backup_page && prev.state.backup_page.results,
        next.state.backup_page && next.state.backup_page.results
      ) &&
      !!equal(
        prev.state.backup_page && prev.state.backup_page.filters,
        next.state.backup_page && next.state.backup_page.filters
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.backupsPage,
        next.state.select_options && next.state.select_options.backupsPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(BackupPage);

export default container;

import { constants } from '../constants/solution-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

import { Log } from 'ng2-logger';

const Logger = Log.create('solutionReducer');

const solutionReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case constants.SOLUTION_GET_BY_TERM: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;

      state = Object.assign({}, state, {
        solution_page: {
          ...state.solution_page,
          solution: result.content ? result.content : {}
        }
      });

      return state;
    }
    case constants.SOLUTION_LIST_SEARCH:
    case constants.SOLUTION_ACTIVITY_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;

      state = Object.assign({}, state, {
        solution_page: {
          ...state.solution_page,
          solution: result.content ? result.content : {}
        }
      });

      return state;
    }
    case constants.COURSE_SOLUTION_PUBLIC: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      state = Object.assign({}, state, {
        solution_page: {
          //...state.solution_page,
          publicSolution: result.content ? result.content : {}
        }
      });
      return state;
    }
    case constants.SOLUTION_ACTIVITY_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            solutionActivityPage: result.content
          }
        });
      }
      return state;
    }
    case constants.SOLUTION_ACTIVITY_CLASSROOM_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            solutionActivityClassroomSelectOption: result.content
          }
        });
      }
      return state;
    }
    case constants.SOLUTION_COURSE_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            solutionCoursePage: result.content
          }
        });
      }
      return state;
    }
    case constants.SOLUTION_GET_PROGRAMS_BY_FACULTIES: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            programs_related_faculty: result.content.programs_related_faculty
          }
        });
      }
      return state;
    }
    case constants.SOLUTION_COURSE_GET_PROGRAMS_BY_FACULTIES: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            programs_id_related_faculty: result.content.programs_id_related_faculty
          }
        });
      }
      return state;
    }
    case constants.SOLUTION_COURSE_GET_AVAILABLE_CLASSROOMS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            course_available_classrooms: result.content ? result.content : {}
          }
        });
      }
      return state;
    }
    case constants.SOLUTION_LIST_INVIGILATOR_COUNTS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      state = Object.assign({}, state, {
        solution_page: {
          ...state.solution_page,
          solutionInvigilatorCounts: result.content ? result.content : {}
        }
      });
      return state;
    }
    case constants.SOLUTION_GET_ACTIVITY_PROGRAMS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          solution_page: {
            ...state.solution_page,
            activityDetails: result.content ? result.content : {}
          }
        });
      }
      return state;
    }
    case constants.SOLUTION_GET_COURSE_PROGRAMS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          solution_page: {
            ...state.solution_page,
            courseDetails: result.content ? result.content : {}
          }
        });
      }
      return state;
    }
    case constants.SOLUTION_GET_EXAM_DATES: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      state = Object.assign({}, state, {
        solution_page: {
          ...state.solution_page,
          solutionExamDates: result.content ? result.content : {}
        }
      });
      return state;
    }
    default: {
      return state;
    }
  }
};

export default solutionReducer;

import Translator, { Language } from "../../../services/translate-factory";

interface ExcelLanguageFileName {
    dir: string,
    language: Language
}

export function setExcelTemplateDir(T: Translator, excelFiles: ExcelLanguageFileName[]): string | undefined {
    let languageExcelDir: string | undefined

    const selectedLanguage = T.getSelectedLanguage()

    for (const file of excelFiles) {
        if (file.language === selectedLanguage) {
            languageExcelDir = file.dir;
            break;
        }
    }

    if (!languageExcelDir) {
        languageExcelDir = excelFiles.filter(i => i.language = Language.TR)[0].dir
    }

    return languageExcelDir
}
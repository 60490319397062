import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

export const constants = {
  CAMPUS_LIST_SEARCH: 'CAMPUS:LIST_SEARCH',
  CAMPUS_CREATE: 'CAMPUS:CREATE',
  CAMPUS_UPDATE: 'CAMPUS:UPDATE',
  CAMPUS_DELETE: 'CAMPUS:DELETE',
  CAMPUS_GET_BY_ID: 'CAMPUS:GET_BY_ID',
  CAMPUS_GET_DISTANCES: 'CAMPUS:GET_DISTANCES',
  CAMPUS_UPDATE_DISTANCES: 'CAMPUS:POST_DISTANCES',
  CAMPUS_GET_SELECT_OPTIONS: 'CAMPUS:GET_SELECT_OPTIONS',
  CAMPUS_CREATE_EXCEL_BULK: 'CAMPUS:CREATE_EXCEL_BULK',
  CAMPUS_IMPORT_LIST_SEARCH: 'CAMPUS:IMPORT_LIST_SEARCH',
  CAMPUS_IMPORT_GET_SELECT_OPTIONS: 'CAMPUS:IMPORT_GET_SELECT_OPTIONS',
  CAMPUS_IMPORT_CREATE: 'CAMPUS:IMPORT_CREATE',
};

export const CampusSearchInitialValues: Types.IFilterCampus = {
  order_by: '',
  campus_code: '',
  name: '',
  status: [],
  buildings: [],
  building_ids: [],
  select_all: false,
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const CampusFormInitialValues: Types.ICampusItem = {
  campus_id: undefined,
  campus_code: '',
  name: '',
  address: '',
  description: '',
  status: 1,
  photos: [],
  sketches: [],
  isSketchActive: false,
};

export const ImportingKeySelectOptions = (T: Translator) => [
  { label: T.t('gen_campus_code'), value: 'CAMPUS_CODE' },
  { label: T.t('gen_do_not_check'), value: 'NONE' }
];

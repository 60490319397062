export enum PermissionType {
  None = 0,
  CamMic_Access_Self = 1,
  CamMic_Remote_Request = 2,
  CamMic_Reject_Response = 3,
  Session_Operations = 4,
  Session_Manage_Options = 5,
  Session_Manage_Surveys = 6,
  Session_Manage_Participants = 7,
  Session_Manage_Files = 8,
  Session_Manage_Chats = 9,
  Video_Player_Control = 10,
  Board_Sharing_Auth = 11,

  Panel_Calendar = 100,
  Panel_Session_Create = 200,
  Panel_Session_List = 201,
  Panel_Session_Delete = 202,
  Panel_Session_Update = 203,
  Panel_User_List = 300,
  Panel_User_Create = 301,
  Panel_User_Create_Excel = 302,
  Panel_User_Delete = 303,
  Panel_User_Edit = 304,
  Panel_Attendee_Insert = 401,
  Panel_Attendee_Delete = 402,
  Panel_Attendee_List = 403,
  Panel_User_Info = 600,
  Panel_Company_Info = 601,
  Panel_Common_File = 601,
  Panel_Report = 602,
  Panel_License = 603,
  Panel_Password_Change = 604,
  Panel_System_Check = 605,
  Panel_Help = 606,
  Panel_TEST = 706
}

export enum UserRole {
  MANAGER = 'a',
  MANAGER_RESTRICTED = 'b',
  INSTRUCTOR = 'e',
  INSRUCTOR_RESTRICTED = 'd',
  PANEL = 'p',
  PANEL_RESTRICTED = 'r',
  SYSTEM = 's',
  USER = 'u',
  EVENT_MANAGER = 'em',
  EVENT_USER = 'eu'
}

export enum ReplayStatus {
  NotAllowed = 0,
  NotPacked = 1,
  Packed = 2,
  NoContent = 3
}

export enum SectionTypes {
  Campuses = 0,
  Buildings = 1,
  Classrooms = 2,
  Faculties = 3,
  Programs = 4,
  Courses = 5,
  Instructors = 6,
  Planning_Classrooms = 7,
  Planning_Courses = 8,
  Planning_Instructors = 9,
  Exam_Period_Students = 10,
  Exam_Period_Solution = 11,
  Instructor_Course_Info = 13,
  Solution_Basic = 14,
  Students = 15,
  SyncCampuses = 16,
  SyncBuildings = 17,
  SyncClassrooms = 18,
  SyncFaculties = 19,
  SyncPrograms = 20,
  SyncInstructors = 21,
  SyncCourses = 22,
  SyncStudents = 23,
  SyncCampusesResult = 24,
  SyncBuildingsResult = 25,
  SyncClassroomsResult = 26,
  SyncFacultiesResult = 27,
  SyncProgramsResult = 28,
  SyncInstructorsResult = 29,
  SyncCoursesResult = 30,
  SyncStudentsResult = 31,
  StudentCourseOverlaps = 33,
  ExamListResult = 34,
  TermCloneResult = 35,
  Planning_NonAvailable_Classrooms = 36,
  Planning_NonAvailable_Courses = 37,
  Planning_NonAvailable_Instructors = 38,
  Examination_Schedule = 1000,
  Course_Schedule = 1001,
  Solution = 2000,
  Distribution = 3000,
}

export enum SectionTypeSolutionOptionCourseTerm {
  Data = 0,
  ClassroomSchedule = 1,
  CrossTable = 2,
  ClassroomUsageWeekly = 3,
  ClassroomUsageDaily = 4,
  InstructorDensity = 5,
  StudentDensity = 6,
  InstructorWorkloadHourly = 7,
  Ois = 8,
  ObsFormatOne = 9,
  ObsFormatTwo = 10,
  UnipaFormatOne = 11,
  ObsFormatThree = 12,
  UnipaFormatTwo = 13,
  InstructorWeeklyCourseLoadZip = 14,
}

export enum SectionTypeSolutionOptionExamTerm {
  Data = 0,
  InvigilatorListTotal = 1,
  InvigilatorListDaily = 2,
  InstructorWorkloadHourly = 3,
  CrossTable_AccPrograms = 4,
  CrossTable_AccProgramsWithoutInvigilators = 5,
  CrossTable_AccInstructors = 6,
  CrossTable_AccClassrooms = 7,
  CrossTable_AccClassroomsVertical = 8,
  CrossTable_AccInvigilators = 9,
  InstructorExams = 10,
  InstructorCounts = 11,
  StudentCounts = 12,
  Obs = 13,
  StudentExamCounts = 14,
  StudentExams = 15,
  StudentDensity = 16,
  StudentOverlappingExams = 17,
  StudentConsecutiveExams = 18,
  Invigilators = 19,
  InstructorOverlappingExams = 20,
  InstructorConsecutiveExams = 21,
  Ois = 22,
  OisStudents = 23,
  UnipaStudents = 24,
}

export enum SyncResultIds {
  None = 0,
  FetchStarted = 1,
  Fetched = 2,
  FetchFailed = 3,
  SyncStarted = 4,
  Synched = 5,
  SyncFailed = 6
}
import React from 'react';
import { Log } from 'ng2-logger';
import { IPaginateProps } from '../../store/types';
import { ConfigProvider, Pagination } from 'antd'
import Translator from '../../services/translate-factory';
import tr from 'antd/locale/tr_TR'
import en from 'antd/locale/en_US'

import * as Constants from '../../store/constants/all';

const T = Translator.create();
const L = Log.create('Paginate');
export default class Paginate extends React.Component<IPaginateProps, any> {
  state = {
    size: this.props.filters && this.props.filters.size || 10,
    page: this.props.filters && this.props.filters.page || 1,
    locale: undefined
  }
  constructor(props?: any) {
    super(props);
    this.state.size = this.props.filters && this.props.filters.size || 10;
    this.state.page = this.props.filters && this.props.filters.page || 1;
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
        this.changeLanguage(T.getSelectedLanguage())
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };
  changeLanguage = (lang: string) => {
    if (lang === 'en-US') {
      this.setState((prev: any) => ({ ...prev, locale: en }));
    } else {
      this.setState((prev: any) => ({ ...prev, locale: tr }));
    }
  }

  componentDidMount() {
    this.changeLanguage(T.getSelectedLanguage())
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  onChange = (e: React.MouseEvent<HTMLAnchorElement>) => {
    let page: number = 1;
    if (e && e.currentTarget && e.currentTarget.dataset.page) {
      page = parseInt(e.currentTarget.dataset.page);
    }

    if (this.props.onPageChange) {
      this.props.onPageChange(page);
    }
  };
  handlePageChange = (page: number, size?: number) => {
    if (this.props.onPageChange) {
      this.props.onPageChange(page, size);
    }
  };
  handleSizeChange = (current: number, size: number) => {
    this.setState({ size });
    if (this.props.onSizeChange) {
      this.props.onSizeChange(size);
    }
  };

  render() {
    const F = this.props.filters;
    const size = this.props.filters && this.props.filters.size || 10;
    const currentPage = this.props.filters && this.props.filters.page || 1;

    const total = F && F.total || 0
    const start = (currentPage - 1) * size + 1;
    const end = Math.min(currentPage * size, total);

    return (
      <ConfigProvider locale={this.state.locale}>
        <div className="pagination-container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
          <Pagination
            key={this.state.locale}
            total={total}
            pageSize={size}
            current={currentPage}
            onChange={this.handlePageChange}
            onShowSizeChange={this.handleSizeChange}
            showSizeChanger={this.props.onSizeChange ? true : false}
            pageSizeOptions={[10, 20, 50, 100]}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: T.t('gen_pagination_text', {
                start: start,
                end: end,
                total: total,
              })
            }}
          />
        </div>
      </ConfigProvider>
    )
  }
}

import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
// import * as actions from "../actions";
import * as Actions from '../actions/general';
import { constants } from '../constants/sketch-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';

function* getSketchCampuses(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SketchCampusSearch(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSketchBuildings(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SketchBuildingSearch(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSketchCampusData(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SketchCampusGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSketchBuildingData(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SketchBuildingGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateBuildingMarker(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SketchBuildingMarkerUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_building_updated', 'gen_success'));
  } else if (response && response.status == 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateClassroomMarker(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SketchClassroomMarkerUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_classroom_updated', 'gen_success'));
  } else if (response && response.status == 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSketchSelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SketchGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* bulkUpdateSketch(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SketchBulkUpdate(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    }
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSketchPublicCampuses(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;

  if (action.body && action.body.reset) {
    response = new Response(null);
    content = null;
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SketchPublicCampusSearch(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSketchPublicBuildings(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SketchPublicBuildingSearch(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}


export function* watchSketchCampusSearch() {
  yield takeEvery((action: any) => action.target === constants.SKETCH_CAMPUS_LIST_SEARCH, getSketchCampuses);
}

export function* watchSketchBuildingSearch() {
  yield takeEvery((action: any) => action.target === constants.SKETCH_BUILDING_LIST_SEARCH, getSketchBuildings);
}

export function* watchSketchGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.SKETCH_GET_SELECT_OPTIONS, getSketchSelectOptions);
}

export function* watchSketchCampusGetById() {
  yield takeEvery((action: any) => action.target === constants.SKETCH_CAMPUS_GET_BY_ID, getSketchCampusData);
}

export function* watchSketchBuildingGetById() {
  yield takeEvery((action: any) => action.target === constants.SKETCH_BUILDING_GET_BY_ID, getSketchBuildingData);
}

export function* watchBuildingMarkerUpdate() {
  yield takeEvery((action: any) => action.target === constants.SKETCH_BUILDING_MARKER_UPDATE, updateBuildingMarker);
}

export function* watchClassroomMarkerUpdate() {
  yield takeEvery((action: any) => action.target === constants.SKETCH_CLASSROOM_MARKER_UPDATE, updateClassroomMarker);
}

export function* watchSketchBulkUpdate() {
  yield takeEvery((action: any) => action.target === constants.SKETCH_BULK_UPDATE, bulkUpdateSketch);
}

export function* watchSketchPublicCampusSearch() {
  yield takeEvery((action: any) => action.target === constants.SKETCH_PUBLIC_CAMPUS_LIST_SEARCH, getSketchPublicCampuses);
}

export function* watchSketchPublicBuildingSearch() {
  yield takeEvery((action: any) => action.target === constants.SKETCH_PUBLIC_BUILDING_LIST_SEARCH, getSketchPublicBuildings);
}
